import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect, useMemo } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLazyCondominiumShowQuery } from "../redux/slices/condominiumApi";
import SubCondominiumReadingsTab from "./SubCondominiumReadingsTab";
import { setPosDirection } from "../redux/slices/condominiumSlice";
import GatewayIndex from "../components/gateway/GatewayIndex";
import ModalDialog from "../components/meters/ModalDialog";
import { store } from "../redux/store";
import { setMessage } from "../redux/slices/snackbarSlice";
import { setSubComIndex } from "../redux/slices/condominiumSlice";
import SubcondominiumConsimptionsTab from "./SubcondominiumConsimptionsTab";
import {
  setTreeItem,
  setBtnDetector,
  setSubCondominiumIdentifier,
  setSubConName,
  setTreeExpanded,
  setSubCondominiumGatewayModal,
  setActualAppPos,
} from "../redux/slices/condominiumSlice";
import { condominiumApi } from "../redux/slices/condominiumApi";

import { useSelector, useDispatch } from "react-redux";
import { loadingOn, loadingOff } from "../redux/slices/loadingSlice";
import { setMaxAppPos } from "../redux/slices/condominiumSlice";
import { TreeItem, TreeView } from "@mui/lab";
import {
  setActualSubConPos,
  setSubCondominiumBotAllowed,
  setSubCondominiumTopAllowed,
} from "../redux/slices/condominiumSlice";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { CondominiumTree } from "../components/condominium/CondominiumTree";
import CondominiumTabs from "../components/condominium/CondominiumTabs";
import LongNameWrapper from "../utils/LongNameWrapper";
import { Button } from "react-bootstrap";

const sortChildrenByPosition = (children) => {
  return [...children]?.sort(
    (a, b) => a.attributes?.position - b.attributes?.position
  );
};

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
/**
 * A custom component for displaying and managing sub-condominium details.
 *
 * @component TextFieldNumber
 

 

* @returns {JSX.Element} .
 
 *
 */
export const SubCondominiumShow = () => {
  const [
    updateCondominium,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = store.useUpdateCondominiumIfnoMutation();
  const [getSubcondominiumId] = store.useLazyGetSubCondominiumIdQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subCondominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const posDirection = useSelector(
    (state) => state?.condominiumSlice?.posDirection
  );
  const actualSubConPos = useSelector(
    (state) => state?.condominiumSlice?.actualSubConPos
  );
  const subTopElementMeter = useSelector(
    (state) => state?.condominiumSlice?.subTopElementMeter
  );
  const subBotElementMeter = useSelector(
    (state) => state?.condominiumSlice?.subBotElementMeter
  );
  const subcondominiumMeter = useSelector(
    (state) => state?.condominiumSlice?.subcondominiumMeter
  );
  const subCondominumIndex = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIndex
  );
  const [getCondominiumShow] = store.useLazyCondominiumShowQuery();
  const gatewayModal = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumGatewayModal
  );
  const [subcomIndentifierId, setSubcomIndentifierId] = useState(null);
  const [formData, setFormData] = useState({});
  const [topElement, setTopElement] = useState(null);
  const [botElement, setBotElement] = useState(null);
  const [selectedElement, setSelctedElement] = useState(null);
  const { id, subCondominumName } = useParams();
  useEffect(() => {
    if (subCondominiumIdentifier) {
      dispatch(setTreeItem(subCondominiumIdentifier));
    }
    dispatch(setBtnDetector(2));
  }, [subCondominiumIdentifier]);
  useEffect(() => {
    if (subCondominiumIdentifier) {
      getSubcondominiumId(subCondominiumIdentifier);
    }
  }, [subCondominiumIdentifier]);
  useEffect(() => {
    dispatch(setSubConName(subCondominumName));
  }, [subCondominumName]);
  const maxSubcondominumPosition = useSelector(
    (state) => state?.condominiumSlice?.maxSubCondominumPos
  );
  useEffect(() => {
    console.log("slctdlmnt", selectedElement);
  }, [selectedElement]);
  const nextPos = maxSubcondominumPosition + 1;
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const identifierId = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.identifier;
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );
  const condominiumTreeData = useSelector(
    (state) => state?.condominiumSlice?.condominiumTreeData
  );
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const [createSubCondominium, { isSuccess: createSuccess }] =
    store.useCreateSubCondominiumMutation();
  const [getCondominiumId] = store.useLazyGetCondominiumIdQuery();

  const subcondominium = useMemo(() => {
    return (condominiumShow?.children?.filter(
      (c) => c?.attributes?.name === subCondominumName
    ) ?? [])[0];
  }, [condominiumShow, subCondominumName]);
  const sortedSubCondominum =
    subcondominium && sortChildrenByPosition(condominiumShow.children);
  useEffect(() => {
    if (subcondominium) {
      dispatch(setMaxAppPos(subcondominium?.children?.length));
      dispatch(setActualSubConPos(+subcondominium?.attributes?.position));
    }
  }, [subcondominium]);

  useEffect(() => {
    /**
     * This useEffect block is responsible for updating the selected, top, and bottom elements
     * based on the current 'subCondominumIndex' value. The effect runs whenever the 'subCondominumIndex'
     * changes, which occurs when the user selects a different sub-condominium from the list.
     *
     * It retrieves the sub-condominium elements at the current index and its adjacent indices
     * from the 'sortedSubCondominum' array (an array containing the sorted sub-condominium elements).
     * The selected sub-condominium is stored in the 'selectedElement' state.
     * The sub-condominium above the selected one is stored in the 'topElement' state.
     * The sub-condominium below the selected one is stored in the 'botElement' state.
     *
     * If there is no sub-condominium at a particular index (e.g., at the start or end of the list),
     * the corresponding state will be set to 'null'.
     *
     * Whenever the 'subCondominumIndex' changes, this effect ensures that the 'selectedElement',
     * 'topElement', and 'botElement' states are updated accordingly, allowing other parts of the component
     * to interact with the correct sub-condominium data.
     * * @memberof module:React
     */
    setSelctedElement(sortedSubCondominum?.[subCondominumIndex]);
    setTopElement(sortedSubCondominum?.[subCondominumIndex - 1]);
    setBotElement(sortedSubCondominum?.[subCondominumIndex + 1]);
  }, [subCondominumIndex]);

  useEffect(() => {
    if (!condominiumShow) {
      getCondominiumShow(id);
    }
  }, [condominiumShow]);
  useEffect(() => {
    if (updateSuccess) {
      getCondominiumShow(id);
    }
  }, [updateSuccess]);
  useEffect(() => {
    console.log("subkaci", updateLoading);
  }, [updateLoading]);

  useEffect(() => {
    if (!topElement || updateLoading) {
      dispatch(setSubCondominiumTopAllowed(false));
    } else {
      dispatch(setSubCondominiumTopAllowed(true));
    }
  }, [topElement]);
  useEffect(() => {
    if (!botElement || updateLoading) {
      dispatch(setSubCondominiumBotAllowed(false));
    } else {
      dispatch(setSubCondominiumBotAllowed(true));
    }
  }, [botElement]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage("Meter aggiornato con successo"));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setTimeout(() => {
        getCondominiumShow(id);
        const newPath = window.location.pathname.replace("new", formData.name);
        navigate(newPath);
        dispatch(setTreeExpanded(["condominum"]));
        dispatch(loadingOff());
      }, 500);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (subcondominium) {
      console.log("subcondominium", subcondominium);
      setFormData(subcondominium?.attributes);
      dispatch(
        setSubCondominiumIdentifier(subcondominium?.attributes.identifier)
      );
    }
  }, [subcondominium]);
  /**
   * Function that updates the details of the sub-condominium in the upward direction.
   * If the 'updateLoading' flag is true, the function returns early without performing any updates.
   * Otherwise, it prepares the necessary attribute objects for the actual sub-condominium and the one above it.
   * It then calls the 'updateCondominium' function twice in sequence to update the attributes of both sub-condominiums.
   *
   * The 'updateCondominium' function takes two parameters: 'twinId' and 'instanceId'.
   * - 'twinId' represents the digital twin ID associated with the sub-condominium.
   * - 'instanceId' is the identifier of the specific instance of the sub-condominium.
   *
   * The 'actualAttribuste' object contains the updated attributes for the current sub-condominium:
   * - 'position': The current position value of the sub-condominium is decreased by 1.
   * - 'coordinates': The coordinates of the subcondominiumMeter.
   * - 'serial_number': The serial number of the subcondominiumMeter.
   * - 'class': The class of the subcondominiumMeter.
   *
   *
   * The 'topAttributes' object contains the updated attributes for the sub-condominium above the current one:
   * - 'position': The position of the sub-condominium above is increased by 1.
   * - 'coordinates': The coordinates of the topElement.
   * - 'serial_number': The serial number of the topElement.
   * - 'class': The class of the topElement.
   *
   * After preparing the attribute objects, the function performs the updates asynchronously using promises.
   * Once the updates are completed, the function returns.
   */
  const topDirectionFunc = () => {
    if (updateLoading) {
      return;
    } else {
      const actualAttribuste = {
        fields: {
          position: (+selectedElement?.attributes?.position - 1)?.toString(),
          coordinates: subcondominiumMeter?.attributes?.coordinates,
          serial_number: subcondominiumMeter?.attributes?.serial_number,

          class: subcondominiumMeter?.attributes?.class,
        },
        meta_data: {},
      };
      const topAttributes = {
        fields: {
          position: (+topElement?.attributes?.position + 1).toString(),
          coordinates: topElement?.attributes?.coordinates,
          serial_number: topElement?.attributes?.identifier,
          class: topElement?.attributes?.class,
        },
        meta_data: {},
      };
      updateCondominium({
        twinId: subcondominiumMeter?.attributes?.digital_twin_id,
        instanceId: subcondominiumMeter?.attributes?.fields?.identifier,
        attributes: actualAttribuste,
      })
        .then(() => {
          updateCondominium({
            twinId: subcondominiumMeter?.attributes?.digital_twin_id,
            instanceId: topElement?.attributes?.identifier,
            attributes: topAttributes,
          });
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  };
  /**
   * Function that updates the details of the sub-condominium in the downward direction.
   * If the 'updateLoading' flag is true, the function returns early without performing any updates.
   * Otherwise, it prepares the necessary attribute objects for the actual sub-condominium and the one below it.
   * It then calls the 'updateCondominium' function twice in sequence to update the attributes of both sub-condominiums.
   *
   * The 'updateCondominium' function takes two parameters: 'twinId' and 'instanceId'.
   * - 'twinId' represents the digital twin ID associated with the sub-condominium.
   * - 'instanceId' is the identifier of the specific instance of the sub-condominium.
   *
   * The 'actualAttribuste' object contains the updated attributes for the current sub-condominium:
   * - 'position': The current position value of the sub-condominium is increased by 1.
   * - 'coordinates': The coordinates of the sub-condominiumMeter.
   * - 'serial_number': The serial number of the sub-condominiumMeter.
   * - 'class': The class of the sub-condominiumMeter.
   *
   * The 'botAttributes' object contains the updated attributes for the sub-condominium below the current one:
   * - 'position': The position of the sub-condominium below is decreased by 1.
   * - 'coordinates': The coordinates of the botElement.
   * - 'serial_number': The serial number of the botElement.
   * - 'class': The class of the botElement.
   *
   * After preparing the attribute objects, the function performs the updates asynchronously using promises.
   * Once the updates are completed, the function returns.
   */
  const botDirectionFunc = () => {
    if (updateLoading) {
      return;
    } else {
      const actualAttribuste = {
        fields: {
          position: (+selectedElement?.attributes?.position + 1)?.toString(),
          coordinates: subcondominiumMeter?.attributes?.coordinates,
          serial_number: subcondominiumMeter?.attributes?.serial_number,

          class: subcondominiumMeter?.attributes?.class,
        },
        meta_data: {},
      };
      const botAttributes = {
        fields: {
          position: (+botElement?.attributes?.position - 1).toString(),
          coordinates: botElement?.attributes?.coordinates,
          serial_number: botElement?.attributes?.identifier,
          class: botElement?.attributes?.class,
        },
        meta_data: {},
      };

      updateCondominium({
        twinId: subcondominiumMeter?.attributes?.digital_twin_id,
        instanceId: subcondominiumMeter?.attributes?.fields?.identifier,
        attributes: actualAttribuste,
      })
        .then(() => {
          updateCondominium({
            twinId: subcondominiumMeter?.attributes?.digital_twin_id,
            instanceId: botElement?.attributes?.identifier,
            attributes: botAttributes,
          });
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    /**
     * This useEffect block is responsible for determining the current index of the selected sub-condominium
     * within the 'sortedSubCondominum' array (an array containing the sorted sub-condominium elements).
     *
     * It runs whenever the 'sortedSubCondominum' array changes, which typically occurs when the component
     * first loads or when the list of sub-condominium elements is updated.
     *
     * It looks for the index of the sub-condominium whose 'identifier' attribute matches the
     * 'identifier' attribute of the 'subcondominiumMeter' (a specific sub-condominium element).
     * This is typically used to identify the currently selected sub-condominium in the list.
     *
     * If a matching sub-condominium is found, its index is stored in the state using the 'setSubComIndex' dispatcher.
     * This allows other parts of the component to know the index of the selected sub-condominium
     * and interact with its data accordingly.
     *
     * Note: The 'subcondominiumMeter' is likely a part of the component's state and represents a specific
     * sub-condominium element that is being currently viewed or interacted with.
     */
    if (sortedSubCondominum?.length > 0) {
      const actualIndex = sortedSubCondominum?.findIndex(
        (item) =>
          item?.attributes?.identifier ===
          subcondominiumMeter?.attributes?.identifier
      );
      dispatch(setSubComIndex(actualIndex));
    }
  }, [sortedSubCondominum]);
  useEffect(() => {
    if (posDirection === "subTop") {
      topDirectionFunc();
      dispatch(setPosDirection(null));
    }
    if (posDirection === "subDown") {
      botDirectionFunc();
      dispatch(setPosDirection(null));
    }
  }, [posDirection]);
  useEffect(() => {
    if (!condominiumId && identifierId) {
      getCondominiumId(identifierId);
    }
  }, [condominiumId, identifierId]);
  useEffect(() => {
    console.log("subcomIndentifierId", subcomIndentifierId);
  }, [subcomIndentifierId]);
  const location = window.location.href;
  const schema = {
    title: "New subcondominium",
    type: "object",
    properties: {
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        readOnly: !location.includes("new") && true,
      },
    },
    required: ["name"],
  };
  /**
   * This function is called when the user clicks on the "Create" button to create a new sub-condominium.
   *
   * It begins by dispatching an action to turn on the loading state, indicating that the creation process is in progress.
   *
   * The function then prepares the data for the new sub-condominium to be created. It extracts the form data from
   * the 'formData' state and combines it with other necessary information such as the 'condominiumId' and the next position.
   * The 'nextPos' variable is likely storing the position value for the new sub-condominium in the sorted list of sub-condominiums.
   *
   * Next, it calls the 'createSubCondominium' mutation function  with the prepared data to create the new sub-condominium.
   * The 'unwrap()' function is used to extract the actual data returned from the mutation, resolving the promise.
   *
   * Once the new sub-condominium is successfully created, the function dispatches actions to update the state with the new sub-condominium's data.
   * For example, it may set the new sub-condominium's name as the tree item and set a flag 'setBtnDetector(2)' to indicate that
   * a certain action has been performed (the meaning of '2' might be specific to the application's logic).
   *
   * Note: The exact details of the dispatch actions and mutation functions are dependent on the application's state management
   * and API implementation.
   */
  const onCreateClick = () => {
    /*     dispatch(loadingOn()); */
    const data = {
      ...formData,
      security: condominiumShow.security,
      condominium_id: condominiumId,
      position: nextPos?.toString(),
    };
    createSubCondominium(data)
      .unwrap()
      .then((data) => {
        console.log("subixdata", data);
        dispatch(setTreeItem(data?.data?.attributes?.fields?.identifier));
        dispatch(setBtnDetector(2));
      });
  };

  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("save"),
      classNames: "submit-button",
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };

  const customValidate = (formData, errors) => {
    if (formData?.roles?.length < 1) {
      errors?.roles?.addError("is a required property");
    }
    return errors;
  };

  return (
    <>
      <ModalDialog
        title={"Associazione gateway"}
        open={gatewayModal}
        close={() => dispatch(setSubCondominiumGatewayModal(false))}
      >
        <GatewayIndex fromCondominium />
      </ModalDialog>
      {!subcondominium && (
        <Typography sx={{ mb: 4 }} variant="h1">
          {t("new_subcondominium")}
        </Typography>
      )}
      {subcondominium && (
        <LongNameWrapper
          text={subCondominumName}
          size={appBarIsOpen ? "75vw" : "95vw"}
          variant={"h1"}
          maxLength={100}
        />
      )}
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {condominiumTreeData && (
              <CondominiumTree
                condominium={condominiumTreeData}
                subcondominiumName={subCondominumName}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={10}>
          <CondominiumTabs
            mainTab={
              <Form
                schema={schema}
                uiSchema={uiSchema}
                //fields={fields}
                formData={formData}
                onSubmit={onCreateClick}
                onChange={(changeEvent) => setFormData(changeEvent.formData)}
                validator={validator}
                showErrorList={false}
                customValidate={customValidate}
                noHtml5Validate
              />
            }
            readings={
              subcondominium && <SubCondominiumReadingsTab condominiumId={id} />
            }
            consume={
              subcondominium && (
                <SubcondominiumConsimptionsTab condominiumId={id} />
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
