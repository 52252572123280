import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  open: false,
  confirmed: false,
};

const trashSlice = createSlice({
  name: "trashSlice",
  initialState,
  reducers: {
    opendModal(state) {
      state.open = true;
    },
    closeModal(state) {
      state.open = false;
    },
    setConfirmed(state) {
      state.confirmed = true;
    },
    unsetConfirmed(state) {
      state.confirmed = false;
    },
  },
});
export const { opendModal, closeModal, setConfirmed, unsetConfirmed } =
  trashSlice.actions;
export default trashSlice.reducer;
