import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";

export const errorTypesApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getErrorTypes: build.query({
        query: (params) => {
          return `v1/plugin/errortype?${prepareParams(params)}`;
        },
      }),
      getErrorType: build.query({
        query: (id) => {
          return `v1/plugin/errortype/${id}`;
        },
      }),
      createErrorType: build.mutation({
        query: (attributes) => ({
          url: `v1/plugin/errortype`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      updateErrorType: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/errortype/${id}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      deleteErrorType: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/plugin/errortype/${id}`,
        }),
      }),
    }),
  });
