import { authApi } from "./authApi";
import { CampaignApi } from "./CampaignApi";
import { condominiumApi } from "./condominiumApi";
import { meterApi } from "./meterApi";
import { userApi } from "./userApi";
import { gatewayApi } from "./gatewayApi";
import { dashboardApi } from "./dashboardApi";
import { aegisApi } from "./aegisApi";
import { clusterApi } from "./clusterApi";
import { deviceDistrictApi } from "./deviceDistrictApi";
import { deviceExportApi } from "./deviceExportApi";
import { deviceMessageApi } from "./deviceMessageApi";
import { dexApi } from "./dexApi";
import { digitalTwinApi } from "./digitalTwinApi";
import { dataApi } from "./GetDataRtq";
import { messageApi } from "./messageApi";
import { loraApi } from "./loraApi";
import { messageExportApi } from "./messageExportApi";
import { meterReadingGraphApi } from "./meterReadingGraphApi";
import { positionApi } from "./positionApi";
import { digitalTwinInstanceRtk } from "./QdtiSlice";
import { statisticApi } from "./statisticApi";
import { uploadFileApi } from "./uploadFileApi";
import { userOTPApi } from "./userOTPApi";
import { userPreferenceApi } from "./userPreferenceApi";
import { widgetsApi } from "./widgetsApi";
import { errorTypesApi } from "./errorTypesApi";
import { errorAssociationApi } from "./errorAssociationApi";
import { alarmsApi } from "./alarmsApi";
import { notesApi } from "./notesApi";
import { auditApi } from "./auditApi";
const modules = [
  {
    api: loraApi,
    keys: [
      "useLazyGetIndexLoraQuery",
      "useLazyGetLoraShowQuery",
      "useCreateLoraMutation",
      "useDeleteLoraMutation",
    ],
    endpointKey: "lora",
  },
  {
    api: auditApi,
    keys: ["useLazyGetAuditIndexQuery"],
    endpointKey: "audit",
  },
  {
    api: notesApi,
    keys: [
      "useLazyGetNotesQuery",
      "useLazyGetShowNoteQuery",
      "useCreateNoteMutation",
      "useUpdateNoteMutation",
      "useDeleteNoteMutation",
    ],
    endpointKey: "notes",
  },
  {
    api: alarmsApi,
    keys: ["useLazyGetAlarmsQuery"],
    endpointKey: "alarms",
  },
  {
    api: errorAssociationApi,
    keys: [
      "useLazyGetErrorAssociationsQuery",
      "useLazyGetErrorAssociationQuery",
      "useCreateErrorAssociationMutation",
      "useUpdateErrorAssociationMutation",
      "useDeleteErrorAssociationMutation",
    ],
    endpointKey: "errorAssociation",
  },
  {
    api: errorTypesApi,
    keys: [
      "useLazyGetErrorTypesQuery",
      "useLazyGetErrorTypeQuery",
      "useCreateErrorTypeMutation",
      "useUpdateErrorTypeMutation",
      "useDeleteErrorTypeMutation",
    ],
    endpointKey: "errorTypes",
  },
  {
    api: widgetsApi,
    keys: [
      "useLazyGetWidgetsQuery",
      "useLazyGetShowWidgetsQuery",
      "useCreateWidgetMutation",
      "useUpdateWidgetMutation",
      "useDeleteWidgetMutation",
    ],
    endpointKey: "widgets",
  },
  {
    api: userPreferenceApi,
    keys: ["useSetPreferenceMutation", "useLazyGetPreferenceQuery"],
    endpointKey: "userPreference",
  },
  {
    api: userOTPApi,
    keys: [
      "useDeleteOTPMutation",
      "useCreateOTPMutation",
      "useVerifyOTPMutation",
    ],
    endpointKey: "userOTP",
  },
  {
    api: uploadFileApi,
    keys: [
      "useLazyGetFileErrorsQuery",
      "useLazyGetFileStatisticQuery",
      "usePostFileMutation",
    ],
    endpointKey: "uploadFile",
  },
  {
    api: statisticApi,
    keys: [
      "useCreateStatisticMutation",
      "useLazyGetStatisticsQuery",
      "useLazyGetStatisticQuery",
      "useUpdateStatisticMutation",
      "useDeleteStatisticMutation",
    ],
    endpointKey: "statistic",
  },
  {
    api: digitalTwinInstanceRtk,
    keys: ["usePostDigitalTwinInstanceMutation"],
    endpointKey: "digitalTwinInstance",
  },
  {
    api: positionApi,
    keys: ["usePostPositionMutation"],
    endpointKey: "position",
  },
  {
    api: meterReadingGraphApi,
    keys: ["useLazyGetMeterReadingGraphQuery"],
    endpointKey: "meterReadingGraph",
  },
  {
    api: messageExportApi,
    keys: ["useLazyStartExportMessageQuery", "useDownloadMessagesMutation"],
    endpointKey: "messageExport",
  },
  {
    api: messageApi,
    keys: [
      "useLazyGetDiagnosticMessagesQuery",
      "useLazyGetReadingMessagesQuery",
    ],
    endpointKey: "message",
  },
  {
    api: dataApi,
    keys: ["useLazyGetDataQuery", "useLazyGetDataByNameQuery"],
    endpointKey: "dataRtk",
  },
  {
    api: digitalTwinApi,
    keys: [
      "useLazyGetDigitalTwinsQuery",
      "useLazyGetDigitalTwinAttributesQuery",
    ],
    endpointKey: "digitalTwin",
  },
  {
    api: dexApi,
    keys: [
      "usePostDexMutation",
      "useLazyGetDexIndexQuery",
      "useCreateDexMutation",
      "useLazyGetDexShowQuery",
      "useExecuteSavedDexMutation",
      "useUpdateDexMutation",
    ],
    endpointKey: "dex",
  },
  {
    api: deviceMessageApi,
    keys: ["useLazyGetDeviceMessagesQuery"],
    endpointKey: "deviceMessage",
  },
  {
    api: deviceExportApi,
    keys: [
      "useLazyStartExportQuery",
      "useDownloadMutation",
      "useLazyGatewayStartExportQuery",
      "useGatewayDownloadMutation",
    ],
    endpointKey: "deviceExport",
  },
  {
    api: deviceDistrictApi,
    keys: [
      "useCreateDistrictMutation",
      "useCreatePolygonMutation",
      "useLazyGetPolygonDevicesQuery",
      "useLazyGetDistrictQuery",
      "useLazyGetPolygonsQuery",
      "useLazyGetDistrictsQuery",
      "useDeleteDistrictMutation",
      "useUpdateDistrictMutation",
      "useDeletePolygonMutation",
      "useDownloadDevicesMutation",
    ],
    endpointKey: "deviceDistrict",
  },
  {
    api: clusterApi,
    keys: [
      "useLazyGetClusterQuery",
      "useLazyGetSingleClusterDevicesQuery",
      "useLazyGetDevicesQuery",
    ],
    endpointKey: "cluster",
  },
  {
    api: aegisApi,
    keys: ["useLazyGetAllrolesQuery"],
    endpointKey: "aegis",
  },
  {
    api: gatewayApi,
    keys: [
      "useLazyGetIndexGatewayQuery",
      "useLazyGetShowGatewayQuery",
      "useCreateGatewayMutation",
      "useDeleteGatewayMutation",
      "useLazyGetGatewayMessagesQuery",
      "useLazyGetMeterReadedMessagesQuery",
      "useLazyGetMeterUnreadedMessagesQuery",
      "useLazyGetHandledMeterMessagesQuery",
      "useGatewayUploadMutation",
      "useLazyGetEdgeMessagesQuery",
    ],
    endpointKey: "gateway",
  },
  {
    api: userApi,
    keys: [
      "useLazyGetUsersQuery",
      "useLazyGetUserShowQuery",
      "useDeleteUserMutation",
      "useChangeUserRolesMutation",
      "useLazyGetGrantQuery",
      "useCreateUserMutation",
    ],
    endpointKey: "user",
  },
  {
    api: meterApi,
    keys: [
      "useLazyGetConsumptionsQuery",
      "usePostCommandsMutation",
      "useLazyGetMeterAttributesQuery",
      "useUpdateMeterMutation",
      "useLazyGetDigitalTwinQuery",
      "useDeleteMeterMutation",
    ],
    endpointKey: "meter",
  },
  {
    api: authApi,
    keys: ["useSignoutMutation"],
    endpointKey: "auth",
  },
  {
    api: CampaignApi,
    keys: [
      "usePostCampaignMutation",
      "useLazyGetIndexCampaignQuery",
      "useLazyGetShowCampaignQuery",
      "useStartCampaignMutation",
      "useStopCampaignMutation",
      "useDeleteCampaignMutation",
    ],
    endpointKey: "campaign",
  },
  {
    api: dashboardApi,
    keys: [
      "useUpdateWidgetsIdMutation",
      "useCreateNewDashboardMutation",
      "useDeleteDashboardMutation",
      "useLazyGetDashboardListQuery",
      "useLazyGetDashboardShowQuery",
    ],
    endpointKey: "dashboard",
  },
  {
    api: condominiumApi,
    keys: [
      "useLazyCondominiumIndexQuery",
      "useLazyCondominiumShowQuery",
      "useCreateCondominiumMutation",
      "useCreateSubCondominiumMutation",
      "useCreateApartmentMutation",
      "useLazyGetCondominiumIdQuery",
      "useLazyGetSubCondominiumIdQuery",
      "useUpdateCondominiumMutation",
      "useUpdateSubCondominiumMutation",
      "useAssociateMeterMutation",
      "useLazyGetCondominiumReadingsQuery",
      "useLazyGetSubCondominiumReadingsQuery",
      "useLazyGetApartamentReadingsQuery",
      "useLazyGetCondominiumConsumptionsQuery",
      "useLazyGetSubcondominiumConsumtionsQuery",
      "useLazyGetApartamentConsumtionsQuery",
      "useUpdateCondominiumIfnoMutation",
      "useAssociateGatewayMutation",
      "useLazyGetApartamentIdQuery",
      "useLazyGetMetertIdQuery",
      "useCreateInstanceMutation",
      "useLazyGetSubCondominiumBotElementQuery",
      "useLazyGetSubCondominiumTopElementQuery",
      "useUpdateCondominiumUploadMutation",
      "useExportCondominiumReadingsMutation",
      "useDeleteCondominiumMutation",
      "useExportCondominiumConsumptionsMutation",
      "useStopCondominiumImportMutation",
    ],
    endpointKey: "condominium",
  },
];

export default (api) => {
  const result = {};

  modules.forEach(({ api: apiModule, keys, endpointKey }) => {
    const { endpoints, ...functions } = apiModule(api);

    keys.forEach((key) => {
      if (functions[key]) {
        result[key] = functions[key];
      }
    });

    result[`${endpointKey}Endpoints`] = endpoints;
  });

  return result;
};
