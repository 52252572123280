import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  identifierTypeValue: null,
};

const breadCrumbmeterSlice = createSlice({
  name: "breadCrumbmeterSlice",
  initialState,
  reducers: {
    setidentifierTypeValue(state, action) {
      state.identifierTypeValue = action.payload;
    },
    unsetValue(state) {
      state.identifierTypeValue = null;
    },
  },
});
export const { setidentifierTypeValue, unsetValue } =
  breadCrumbmeterSlice.actions;
export default breadCrumbmeterSlice.reducer;
