import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

/* const getDefaultValue = ({formData}) => {
    
    if(formData && Object.keys(formData).length > 0){
        return {...formData}
    }else{
        return {lat: "0", lng: "0"}
    }
}
 */
const Point = (props) => {
  const [coordinate, setCoordinate] = useState({ ...props.formData });

  useEffect(() => {
    if (coordinate && coordinate != props.formData) {
      props.onChange(coordinate);
    }
  }, [coordinate]);

  const latChange = (e) => {
    /* setCoordinate({['lat'] : e.target.value}) */
    /* setCoordinate({...coordinate, lat: e.target.value}) */
    setCoordinate((prev) => ({
      ...prev,
      lat: Number(e.target.value),
    }));
  };
  useEffect(() => {
    console.log("POINTPROPS", props);
  }, [props]);
  const inyChange = (e) => {
    /*   setCoordinate({...coordinate, lng: e.target.value}) */
    setCoordinate((prev) => ({
      ...prev,
      lng: Number(e.target.value),
    }));
  };

  return (
    <>
      <Box sx={{ display: "flex", mt: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            defaultValue={0}
            type="number"
            value={props?.formData?.lat && props?.formData?.lat}
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArchitectureIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => latChange(e)}
            label="lat"
            variant="outlined"
          />
        </Box>

        <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
          <TextField
            defaultValue={0}
            type="number"
            value={props?.formData?.lng && props?.formData?.lng}
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArchitectureIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => inyChange(e)}
            label="lng"
            variant="outlined"
          />
        </Box>
      </Box>
    </>
  );
};
export default Point;
