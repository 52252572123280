import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const CroneDecoder = ({ expression }) => {
  const { t } = useTranslation();
  const [crone, setCrone] = useState({
    minute: expression[0],
    hour: expression[1],
    mounthDay: expression[2],
    month: expression[3],
    weekDay: expression[4],
  });
  const [res, setRes] = useState("");
  useEffect(() => {
    if (
      crone.minute === "*" &&
      crone.hour === "*" &&
      crone.mounthDay === "*" &&
      crone.month === "*" &&
      crone.weekDay === "*"
    ) {
      setRes(t("everyMin"));
    }

    if (
      crone.hour === "*" &&
      crone.minute !== "*" &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(`${"everyHourAtMin"} ${crone.minute}`);
    }
    if (
      crone.hour === "*" &&
      crone.minute !== "*" &&
      crone.minute.includes("*") &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("Every")} ${t("minutesTotal", {
          count: parseInt(crone.minute.substring(2)),
        })}`
      );
    }
    if (
      crone.hour !== "*" &&
      crone.minute !== "*" &&
      crone.weekDay === "*" &&
      crone.month &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("atMin")} ${crone.minute} ${t("every")} ${t("hoursTotal", {
          count: parseInt(crone.hour.substring(2)),
        })}`
      );
    }

    if (
      crone.mounthDay !== "*" &&
      crone.minute == 0 &&
      crone.hour == 0 &&
      crone.month === "*" &&
      crone.weekDay === "*"
    ) {
      setRes(
        `${t("Every")}  ${t("days", {
          count: parseInt(crone.mounthDay.substring(2)),
        })}`
      );
    }
    if (
      crone.hour !== "*" &&
      (crone.minute == 0 || crone.minute === "*") &&
      crone.weekDay === "*" &&
      crone.month === "*" &&
      crone.mounthDay === "*"
    ) {
      setRes(
        `${t("Every")}  ${t("hoursTotal", {
          count: parseInt(crone.hour.substring(2)),
        })}`
      );
    }
  }, [
    expression,
    crone.hour,
    crone.minute,
    crone.month,
    crone.weekDay,
    crone.mounthDay,
  ]);
  useEffect(() => {
    console.log("expression", expression);
  }, [expression]);

  return res;
};
export default CroneDecoder;
