import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  alarms: null,
  alarmsCount: null,
};

const alarmsSlice = createSlice({
  name: "aegisSlice",
  initialState,
  reducers: {
    cleanAlarms(state) {
      state.alarms = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.alarmsEndpoints.getAlarms.matchFulfilled,
      (state, action) => {
        state.alarms = action.payload.data.attributes;
        /* state.alarmsCount = action.payload.data.attributes.meta.count; */
      }
    );
  },
});
export const alarmsActions = alarmsSlice.actions;
export default alarmsSlice.reducer;
