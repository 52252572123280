import { prepareParams } from "../../utils";
export const alarmsApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getAlarms: build.query({
        query: (params) => {
          return `v1/plugin/alarm?${prepareParams(params)}`;
        },
      }),
    }),
  });
