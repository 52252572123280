import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const Footer = () => {
  const theme = useTheme();
  const fullYear = new Date();
  return (
    <Box
      sx={{
        color: theme.palette.primary.contrastText,
        position: "fixed",
        bottom: 0,
        width: "100%",
        background: theme.palette.primary.main,
        height: 20,
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <Box sx={{ marginLeft: "1rem" }}>
        <Typography variant="subtitle2">
          &copy; {`${fullYear.getFullYear()}`} Inkwell Data Ltd
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
