import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";

import Chip from "@mui/material/Chip";
import { Box, Typography } from "@mui/material";
import { store } from "../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { clearWidget } from "../../redux/slices/widgetsSlice";
import { setTrashId as setWidgetTrashId } from "../../redux/slices/widgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
const Widgets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getWidgets] = store.useLazyGetWidgetsQuery();
  const [deleteWidget, { isSuccess: deleteSuccess }] =
    store.useDeleteWidgetMutation();
  const [trashModal, setTrashModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const widgets = useSelector((state) => state?.widgetsSlice?.widgets);
  const widgetsCount = widgets?.attributes?.meta?.count;
  const data = widgets?.attributes?.data;
  const widgetTrashid = useSelector((state) => state?.widgetsSlice?.trashId);
  useEffect(() => {
    getWidgets({ page: page, per_page: perPage });
  }, [page]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      width: 90,
      sortable: false,
      flex: 2,
    },
    {
      field: "groups",
      headerName: t("groups"),
      width: 90,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value.map((item) => (
            <Chip
              /* sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }} */
              label={item}
            />
          ));
        }
      },
    },
    {
      field: "insertion_date",
      headerName: t("insertion_date"),
      width: 90,
      sortable: false,
      flex: 2,
    },
    {
      field: "refresh_rate",
      headerName: t("refresh_rate"),
      width: 90,
      sortable: false,
      flex: 2,
    },

    {
      field: "type",
      headerName: t("type"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton sx={{ mt: 0.5, ml: 1 }}>
              <Link to={`/widgets/${params?.id}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>

            <IconButton onClick={() => onTrashClick(params?.id)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = data?.map((item) => ({
    id: item.id,
    name: item?.name,
    groups: item?.groups,
    insertion_date: t("dt", { val: new Date(item.inserted_at) }),
    refresh_rate: item?.refresh_rate / 1000,
    type: item.type,
    actions: item.id,
  }));
  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };
  useEffect(() => {
    if (widgetTrashid) {
      deleteWidget(widgetTrashid);
    }
  }, [widgetTrashid]);
  useEffect(() => {
    dispatch(clearWidget());
  }, []);
  useEffect(() => {
    if (deleteSuccess) {
      getWidgets({ page: page, per_page: perPage });
    }
  }, [deleteSuccess]);
  return (
    <>
      <Typography
        variant="h1"
        sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
      >
        {t("widgets")}
      </Typography>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("widget_delete_text")}
        title={t("widget_delete_title")}
        deleteFunc={() => setWidgetTrashId(trashId)}
      />
      {rows && (
        <Box sx={{ height: 711, width: "100%", mt: 2 }}>
          <StyledDataGrid
            disableColumnMenu
            rows={rows}
            columns={columns}
            pageSize={perPage}
            rowCount={widgetsCount}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            rowHeight={60}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      )}
    </>
  );
};
export default Widgets;
