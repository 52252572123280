import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";
import { deviseMessageCleanUp } from "../../redux/slices/deviceMessageSlice";
import { cleanData } from "../../redux/slices/meterReadingSlice";
import { consumptionsCleanUp } from "../../redux/slices/meterSlice";
import { setConsumRange } from "../../redux/slices/meterSlice";
import { useNavigate } from "react-router-dom";
import ManufacturerData from "./ManufacturerData";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { setMeterShow } from "../../redux/slices/meterSlice";
import { nameTransform } from "../../utils/utilsFunctions";
import { setHeatUnit, setColdUnit } from "../../redux/slices/meterSlice";
import MeterCommands from "./MeterCommands";
import ImpulsiveMeterRegistry from "./impulsiveMeter/ImpulsiveMeterRegistry";
import { setImpulseId } from "../../redux/slices/meterSlice";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import {
  setFieldsToUpdate,
  setMeterIdToUpdate,
} from "../../redux/slices/meterSlice";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
import Registri from "./Registry";
import HeatCostTab from "./HeatCostTab";
import MeterConsumptions from "../form/MeterConsumptions";
import ReadignConsumptionsTab from "../form/ReadignConsumptionsTab";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import InfoCondominum from "./InfoCondominum";
import MeterReadingsTab from "../meters/MeterReadingsTab";
import MeterAlarmsTab from "./MeterAlarmsTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const MeterShow = ({
  apartamentMeterId = null,
  enteredFromCondominium = false,
  impulsiveMeterId = false,
  enteredFromParent = false,
}) => {
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const fieldsToUpdate = useSelector(
    (state) => state?.meterSlice?.fieldsToUpdate
  );
  const impulsiveId = useSelector((state) => state?.meterSlice?.impulseMeterId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataQuery();
  const [getAttributes] = store.useLazyGetMeterAttributesQuery();
  const [value, setValue] = useState(0);
  const [impulsiveTabValue, setImpulsiveTabValue] = useState(0);
  const [meterId, setMeterId] = useState(null);
  const { t } = useTranslation();
  const a = "a";
  const b = null;
  const c = "c";
  const twinId = result?.data?.data?.attributes?.digital_twin_id;
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  const serial = result?.data?.data?.attributes?.fields?.serial;
  const serialNum = result?.data?.data?.attributes?.fields?.serial_number;
  const meterClass = result?.data?.data?.attributes?.fields?.class;
  const fields = result?.data?.data?.attributes?.fields;
  useEffect(() => {
    trigger(
      `altior/digital_twin/0/instance/${
        apartamentMeterId ? apartamentMeterId : id
      }`
    )
      ?.unwrap()
      ?.then((data) => setMeterId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  }, [id, apartamentMeterId]);

  useEffect(() => {
    if (impulsiveMeterId) {
      trigger(`altior/digital_twin/0/instance/${impulsiveMeterId}`)
        ?.unwrap()
        ?.then((data) => setMeterId(data?.data?.id))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  }, [impulsiveMeterId]);

  useEffect(() => {
    if (impulsiveMeterId && result?.data?.data) {
      dispatch(setImpulseId(result?.data?.data?.id));
    }
  }, [impulsiveMeterId, result]);

  const testFn = () => {
    console.log("testfn", a ?? b ?? c);
  };
  useEffect(() => {
    console.log("twinka))0)", twinId);
  }, [twinId]);
  useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]);
  useEffect(() => {
    if (result?.data?.data?.attributes?.fields?.heat_last_reading) {
      dispatch(
        setHeatUnit(
          result?.data?.data?.attributes?.fields?.heat_last_reading?.unit
        )
      );
    }
    if (result?.data?.data?.attributes?.fields?.cold_last_reading) {
      dispatch(
        setColdUnit(
          result?.data?.data?.attributes?.fields?.cold_last_reading?.unit
        )
      );
    }
  }, [result]);
  /*   useEffect(() =>{
    if(result?.data?.data?.attributes?.fields?.cold_last_reading){
      dispatch(setColdUnit(result?.data?.data?.attributes?.fields?.cold_last_reading?.unit))
    }
  },[result]) */
  useEffect(() => {
    console.log("rezik", fields);
  }, [fields]);
  useEffect(() => {
    console.log("valusha", value);
  }, [value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const impulsiveHandleChange = (event, newValue) => {
    setImpulsiveTabValue(newValue);
  };

  /*   useEffect(() => {
    if (twinId) {
      getAttributes(twinId);
    }
  }, [twinId]); */
  useEffect(() => {
    if (result?.data?.data) {
      console.log("napiseclassno", result.data.data);
      const data = result?.data?.data?.attributes?.fields;
      const fieldsToUpdate = {
        digital_twin_id: result?.data?.data?.attributes?.digital_twin_id,
        key: data?.key,
        address: data?.address ?? "",
        coordinates: {
          lat: data?.coordinates?.coordinates?.[1],
          lng: data?.coordinates?.coordinates?.[0],
        },
        meter_type: result?.data?.data?.attributes?.fields.meter_type,
        security: result?.data?.data?.attributes?.fields?.security?.acl,
        pod: data?.pod ?? "",
        time_zone: data?.time_zone,
        tags: data?.tags,
        k: data?.k,
        kc: data?.kc,
        kq: data?.kq,
        room: data?.room,
        radiator_c: data?.radiator_c,
        radiator_depth: data?.radiator_depth,
        radiator_height: data?.radiator_height,
        radiator_width: data?.radiator_width,
        factory_converter: data?.factory_converter,
        project10200: data?.project10200,
        radiator_type: data?.radiator_type,
        working_mode: data?.working_mode,
        type: data?.type,
      };
      dispatch(setMeterIdToUpdate(result?.data?.data?.id));
      dispatch(setFieldsToUpdate(fieldsToUpdate));
    }
  }, [result?.data?.data]);

  useEffect(() => {
    if (enteredFromCondominium && fieldsToUpdate && result?.data?.data) {
      localStorage.setItem("fieldsToUpdate", JSON.stringify(fieldsToUpdate));
    }
  }, [enteredFromCondominium, fieldsToUpdate, result]);

  useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
      dispatch(consumptionsCleanUp());
      dispatch(cleanData());
      dispatch(deviseMessageCleanUp());
    };
  }, []);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return "80vw";
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return "69vw";
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return "92vw";
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return "77vw";
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return "77vw";
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return "69vw";
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return "95vw";
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return "78vw";
    }
  };
  useEffect(() => {
    dispatch(setConsumRange(null));
  }, [impulsiveTabValue, value]);
  useEffect(() => {
    if (value == 3 || value == 4 || value == 5) {
      dispatch(setImpulseId(null));
    }
  }, [value]);

  useEffect(() => {
    console.log("resikus", result);
  }, [result]);
  return (
    <>
      {result?.data && (
        <Box sx={{ width: dinamicWidthSelector() }}>
          {(serial || serialNum) && meterClass !== "PulseCounter" && (
            <>
              {!apartamentMeterId && (
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                  variant="h3"
                >{`Meter: ${nameTransform(
                  result?.data?.data?.attributes?.fields?.class ===
                    "lorawan_device"
                    ? "LoRaWAN"
                    : result?.data?.data?.attributes?.fields?.class ?? "--"
                )} ${
                  result?.data?.data?.attributes?.fields?.serial ?? ""
                }`}</Typography>
              )}

              <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 2",
                    width: "100%",
                    width: dinamicWidthSelector(),
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ width: dinamicWidthSelector(), overflowX: "auto" }}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label={t("registry")} />
                    {!enteredFromCondominium && (
                      <Tab label={t("info_condominium")} />
                    )}
                    <Tab label={t("manufacturer_data")} />
                    <Tab label={t("readings")} />
                    <Tab label={t("consumptions")} />
                    <Tab label={t("readings/consumptions")} />

                    {/*  <Tab label={t("commands")} />
                    <Tab label={t("alarms")} /> */}
                    {"project10200" in
                      result?.data?.data?.attributes?.fields && (
                      <Tab label={t("heat_cost")} />
                    )}
                    {(meterClass === "MAD:00:0D" ||
                      meterClass === "EFE:00:0D") &&
                      fields?.compound_twins?.[0]?.digital_twin_instance_id && (
                        <Tab label={t("input_1")} />
                      )}
                    {(meterClass === "MAD:00:0D" ||
                      meterClass === "EFE:00:0D") &&
                      fields?.compound_twins?.[1]?.digital_twin_instance_id && (
                        <Tab label={t("input_2")} />
                      )}
                    {(meterClass === "MAD:00:0D" ||
                      meterClass === "EFE:00:0D") &&
                      fields?.compound_twins?.[2]?.digital_twin_instance_id && (
                        <Tab label={t("input_3")} />
                      )}
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Registri
                      result={result}
                      id={id}
                      enteredFromCondominium={enteredFromCondominium}
                      impulsiveMeterId={impulsiveMeterId}
                    />
                  </TabPanel>
                  {!enteredFromCondominium && (
                    <TabPanel value={value} index={1}>
                      <InfoCondominum
                        meter={result?.data?.data?.attributes?.fields}
                      />
                    </TabPanel>
                  )}
                  <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 1 : 2}
                  >
                    <ManufacturerData result={result} />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 2 : 3}
                  >
                    <MeterReadingsTab
                      meter={result?.data?.data}
                      apartamentMeterId={meterId}
                      enteredFromCondominium={enteredFromCondominium}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 3 : 4}
                  >
                    <MeterConsumptions
                      meterId={id}
                      apartamentMeterId={meterId}
                      enteredFromCondominium={enteredFromCondominium}
                      meter={result?.data?.data}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 4 : 5}
                  >
                    <ReadignConsumptionsTab
                      meter={result?.data?.data}
                      apartamentMeterId={meterId}
                      enteredFromCondominium={enteredFromCondominium}
                    />
                  </TabPanel>
                  {/*             <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 5 : 6}
                  >
                    <MeterCommands digitalTwinId={twinId} />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={enteredFromCondominium ? 6 : 7}
                  >
                    <MeterAlarmsTab
                      id={id}
                      enteredFromCondominium={enteredFromCondominium}
                    />
                  </TabPanel> */}
                  {"project10200" in result?.data?.data?.attributes?.fields && (
                    <TabPanel
                      value={value}
                      index={enteredFromCondominium ? 7 : 8}
                    >
                      <HeatCostTab result={result?.data?.data} />
                    </TabPanel>
                  )}
                  {(meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D") &&
                    fields?.compound_twins?.[0]?.digital_twin_instance_id && (
                      <TabPanel
                        value={value}
                        index={enteredFromCondominium ? 7 : 8}
                      >
                        <MeterShow
                          impulsiveMeterId={
                            fields?.compound_twins?.[0]
                              ?.digital_twin_instance_id
                          }
                          enteredFromCondominium={enteredFromCondominium}
                          apartamentMeterId={apartamentMeterId}
                          enteredFromParent={true}
                        />
                      </TabPanel>
                    )}
                  {(meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D") &&
                    fields?.compound_twins?.[1]?.digital_twin_instance_id && (
                      <TabPanel
                        value={value}
                        index={enteredFromCondominium ? 8 : 9}
                      >
                        <MeterShow
                          impulsiveMeterId={
                            fields?.compound_twins?.[1]
                              ?.digital_twin_instance_id
                          }
                          enteredFromCondominium={enteredFromCondominium}
                          apartamentMeterId={apartamentMeterId}
                          enteredFromParent={true}
                        />
                      </TabPanel>
                    )}
                  {(meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D") &&
                    fields?.compound_twins?.[2]?.digital_twin_instance_id && (
                      <TabPanel
                        value={value}
                        index={enteredFromCondominium ? 9 : 10}
                      >
                        <MeterShow
                          impulsiveMeterId={
                            fields?.compound_twins?.[2]
                              ?.digital_twin_instance_id
                          }
                          enteredFromCondominium={enteredFromCondominium}
                          enteredFromParent={true}
                        />
                      </TabPanel>
                    )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
      {meterClass === "PulseCounter" && (
        <>
          {result?.data?.data?.attributes?.fields?.meter_type ? (
            <Box sx={{ width: dinamicWidthSelector() }}>
              {!enteredFromParent && (
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                  variant="h3"
                >{`Meter: ${nameTransform(
                  result?.data?.data?.attributes?.fields?.class
                )} ${
                  result?.data?.data?.attributes?.fields?.serial_number
                }`}</Typography>
              )}
              <Tabs value={impulsiveTabValue} onChange={impulsiveHandleChange}>
                <Tab label={t("registry")} />
                <Tab label={t("readings")} />
                <Tab label={t("consumptions")} />
                <Tab label={t("readings/consumptions")} />
              </Tabs>
              <TabPanel value={impulsiveTabValue} index={0}>
                <ImpulsiveMeterRegistry
                  result={result?.data?.data?.attributes?.fields}
                  enteredFromParent={enteredFromParent}
                />
              </TabPanel>
              <TabPanel value={impulsiveTabValue} index={1}>
                <MeterReadingsTab
                  meter={result?.data?.data}
                  apartamentMeterId={meterId}
                  enteredFromCondominium={enteredFromCondominium}
                  enteredFromParent={enteredFromParent}
                  impulsiveMeterId={impulsiveId}
                />
              </TabPanel>
              <TabPanel value={impulsiveTabValue} index={2}>
                <MeterConsumptions
                  meterId={id}
                  /*  apartamentMeterId={meterId} */
                  meter={result?.data?.data}
                  enteredFromCondominium={enteredFromCondominium}
                  impulsiveMeterId={impulsiveId}
                  enteredFromParent={enteredFromParent}
                />
              </TabPanel>
              <TabPanel value={impulsiveTabValue} index={3}>
                <ReadignConsumptionsTab
                  meter={result?.data?.data}
                  enteredFromCondominium={enteredFromCondominium}
                  enteredFromParent={enteredFromParent}
                  impulsiveMeterId={impulsiveId}
                />
              </TabPanel>
            </Box>
          ) : (
            <Typography
              sx={{ color: theme.palette.primary.main, mt: 2 }}
              variant="h3"
              align="center"
            >
              Contatore non abilitato
            </Typography>
          )}
        </>
      )}
      {result?.error?.status === 404 && (
        <Typography
          align="center"
          sx={{ color: theme.palette.primary.main }}
          variant="h3"
        >
          {t("meter_not_exists")}
        </Typography>
      )}
    </>
  );
};

export default MeterShow;
