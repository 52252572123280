import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import theme from "../theme";
const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack alignItems="center">
      <Typography
        variant="h4"
        sx={{ color: theme.palette.primary.main, mt: 2 }}
      >
        {t("not_found")}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          mt: 2,
          background: theme.palette.primary.main,
          color: "white",
          "&:hover": { backgroundColor: theme.palette.primary.main },
        }}
        onClick={() => navigate("/")}
      >
        {t("not_found_btn")}
      </Button>
    </Stack>
  );
};
export default NotFoundPage;
