import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
/**
 * This component is designed to handle long text.
   It takes 4 parameters.
 * @param {*} text The string we want to process
 * @param {*} size The maximum width of the block into which the string will be placed.
 * @param {*} variant Variant for MUI Typography. Please refer to the official documentation.
 * @param {*} maxLength Maximum permissible length of the string.
 * 
 * If the text is too long but does not exceed the maximum line length, 
 * it will be wrapped to the next line. If the text exceeds the maximum permissible line length, 
 * it will also be moved to the next line, but after a certain character, it will be truncated and replaced with ....
   The full text can be viewed by hovering over it using the mui Tooltip component.
 * @returns {JSX.Element}
 */
const LongNameWrapper = ({ text, size, variant, maxLength = 20 }) => {
  let truncatedText = text;
  if (text?.length > maxLength) {
    truncatedText = text?.substring(0, maxLength) + "...";
  }
  return (
    <Box sx={{ mb: 3 }}>
      {text?.length <= maxLength && (
        <Box style={{ width: size, wordWrap: "break-word" }}>
          <Typography variant={variant}>{text}</Typography>
        </Box>
      )}
      {text?.length > maxLength && (
        <Tooltip title={text}>
          <Box style={{ width: size, wordWrap: "break-word" }}>
            <Typography variant={variant}>{truncatedText}</Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
export default LongNameWrapper;
