import { prepareParams } from "../../utils";

export const dataApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getData: build.query({
        query: (url) => {
          return `v1/${url}`;
        },
      }),
      getDataByName: build.query({
        query: (data) => {
          const url = data[0];
          const params = data[1];

          return `v1/${url}?${prepareParams(params)}`;
        },
      }),
    }),
  });

const parseParams = (params) => {
  return Object.keys(params)
    .map((k) => {
      return `${k}=${params[k]}`;
    })
    .join("&");
};

/* export const { useLazyGetDataQuery, useLazyGetDataByNameQuery } = dataApi;
 */
