import { actions } from "./authSlice";
import { setErrors } from "./errorSlice";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { prepareParams } from "../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: `${window.BASE_URL}/api/`,
  timeout: 15000,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests

    const bearer = localStorage.getItem("user_token");
    const signout = getState().authSlice.signout;
    if (signout) {
      headers.set("Content-Type", "application/json");
    } else {
      headers.set("Content-Type", "application/vnd.api+json");
      headers.set("Accept", "application/vnd.api+json");
    }

    if (bearer) {
      headers.set("Authorization", `Bearer ${bearer}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.originalStatus === 401 || result.error.status === 401)
  ) {
    api.dispatch(actions.logout());
  }
  if (result.error) {
    api.dispatch(setErrors(result.error));
  }
  return result;
};
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  //   reducerPath: "",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithReauth,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ["meterReadingGraphApi"],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
