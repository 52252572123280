import React, { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { actions } from "../redux/slices/authSlice";
import { useAuth } from "./AuthProvider";
import { useDispatch, useSelector } from "react-redux";
const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  let auth = useAuth();
  const logged = useSelector((state) => state?.authSlice?.logged);
  const isLogged = () => {
    dispatch(actions.isLogged());
  };
  useEffect(() => {
    isLogged();
  }, []);
  let location = useLocation();
  if (logged === false) {
    localStorage.setItem("from", JSON.stringify(location));
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <> {children} </>;
};

export default RequireAuth;
