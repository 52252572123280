import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import PeopleIcon from "@mui/icons-material/People";
import theme from "../theme";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import StorageIcon from "@mui/icons-material/Storage";
import InsightsIcon from "@mui/icons-material/Insights";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import IconButton from "@mui/material/IconButton";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { IwdTreeItem } from "../utils/IwdTreeItem";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CommitIcon from "@mui/icons-material/Commit";
import ExploreIcon from "@mui/icons-material/Explore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GatewayIndex from "./gateway/GatewayIndex";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeItem from "@mui/lab/TreeItem";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ErrorIcon from "@mui/icons-material/Error";
import { HolidayVillage } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LongNameWrapper from "../utils/LongNameWrapper";
import ReportIcon from "@mui/icons-material/Report";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "normal",
  overflowWrap: "break-word",
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  background: "#2a2a2a",
  boxSizing: "border-box",
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  ...(!open && {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  }),
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    ...(open && {
      overflowX: "hidden",
    }),
    ...(!open && {
      width: 0,
      overflowX: "hidden",
    }),
  },
}));

export function SideMenu({
  expanded,
  selected,
  handleToggle,
  handleSelect,
  open,
  handleDrawerClose,
  items,
}) {
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      open={open}
      classes={{
        paper: {
          background: "#90a4ae",
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Typography
        variant="h1"
        paddingTop={"2rem"}
        paddingBottom={"2rem"}
        marginTop={"-12px"}
        fontWeight={900}
        sx={{ textAlign: "center", mb: 2 }}
      >
        {window?.LOGO?.logo ? (
          <img
            style={{
              height: window?.LOGO?.height,
              width: window?.LOGO?.width,
              marginRight: 20,
              marginTop: 5,
            }}
            src={window?.LOGO?.logo}
          />
        ) : (
          "LOGO"
        )}
      </Typography>
      {/*       <Box display={"flex"} sx={{ flexGrow: 1 }} alignItems="center">
        <img
          style={{
            height: "30px",
            marginRight: 20,
            marginTop: 5,
          }}
          src={window.LOGO}
        />
      </Box> */}
      <TreeView
        aria-label="file system navigator"
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        sx={{
          height: 240,
          flexGrow: 1,
          maxWidth: drawerWidth,
          overflowY: "auto",
        }}
      >
        <List>
          <IwdTreeItem
            uuid={items.dashboard}
            icon={<DashboardIcon />}
            title={t("dashboard")}
            section={"dashboard"}
            /*   hrefLink={true} */
            link={"/metrics"}
          />
          <IwdTreeItem
            uuid={items.condominiums}
            icon={<HolidayVillage />}
            title={t("condominiums")}
            section={"condominums"}
            link={"/condominiums"}
          />
          <IwdTreeItem
            uuid={items.meters}
            icon={<GasMeterIcon />}
            title={t("meters")}
            section={"devices_meters"}
          >
            <IwdTreeItem
              uuid={items.metersList}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"devices_meters"}
              link={"/meters"}
            />
            <IwdTreeItem
              uuid={items.errors}
              icon={<ErrorIcon />}
              title={t("errors")}
              section={"errors"}
            >
              <IwdTreeItem
                uuid={items.errorTypes}
                icon={<NewReleasesIcon />}
                title={t("errors_types")}
                section={"errors"}
              >
                <IwdTreeItem
                  uuid={items.errorTypesList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"errors"}
                  link={"/errortypes"}
                />
                <IwdTreeItem
                  uuid={items.errorTypesCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_errors_types")}
                  section={"errors"}
                  link={"/errortypes/create"}
                />
              </IwdTreeItem>

              <IwdTreeItem
                uuid={items.errorAssociation}
                icon={<ReportIcon />}
                title={t("error_association")}
                section={"errors"}
              >
                <IwdTreeItem
                  uuid={items.errorAssociationList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"errors"}
                  link={"/errorassociation"}
                />
                <IwdTreeItem
                  uuid={items.errorAssociationCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_errors_association")}
                  section={"errors"}
                  link={"/errorassociation/create"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.operations}
            icon={<TextSnippetIcon />}
            title={t("operations")}
            section={"operations"}
          >
            <IwdTreeItem
              uuid={items.userList}
              icon={<PeopleIcon />}
              title={t("users")}
              section={"users"}
              link={"/users"}
            />
            <IwdTreeItem
              uuid={items.alarms}
              icon={<NotificationsActiveIcon />}
              title={t("alarms")}
              section={"alarms"}
              link={"/alarms"}
            />
            <IwdTreeItem
              uuid={items.audit}
              icon={<VerifiedUserIcon />}
              title={t("audit")}
              section={"audit"}
              link={"/audit"}
            />
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.network}
            icon={<RssFeedIcon />}
            title={t("network")}
            section={"network"}
          >
            <IwdTreeItem
              uuid={items.gateways}
              icon={<SettingsIcon />}
              title={t("gateways")}
              section={"list_LPWAN"}
              link={"/gateways"}
            />
            <IwdTreeItem
              uuid={items.lora}
              icon={<StorageIcon />}
              title={t("lora")}
              section={"lora"}
              link={"/lora"}
            />
            <IwdTreeItem
              uuid={items.edge}
              icon={<CommitIcon />}
              title={t("edge_controller")}
              section={"edge"}
              link={"/edge"}
            />
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.digitalTwins}
            icon={<MemoryOutlinedIcon />}
            title={t("digital_twins")}
            section={"digital_twins"}
            link={"/digitaltwins"}
          />
          <IwdTreeItem
            uuid={items.statistics}
            icon={<InsightsIcon />}
            title={t("statistics")}
            section={"statistics"}
          >
            <IwdTreeItem
              uuid={items.statisticList}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"statistics_list"}
              link={"/statistics"}
            />
            <IwdTreeItem
              uuid={items.createStatistic}
              icon={<AddCircleIcon />}
              title={t("add_statistic")}
              section={"statistics_create"}
              link={"/statistics/create"}
            />
          </IwdTreeItem>
          <IwdTreeItem
            uuid={items.widgets}
            icon={<WidgetsIcon />}
            title={t("widgets")}
            section={"widgets"}
          >
            <IwdTreeItem
              uuid={items.widgetList}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"widgets_list"}
              link={"/widgets"}
            />
            <IwdTreeItem
              uuid={items.widgetCreate}
              icon={<AddCircleIcon />}
              title={t("add_widget")}
              section={"widgets_create"}
              link={"/widgets/create"}
            />
          </IwdTreeItem>
          <IwdTreeItem
            uuid={items.dex}
            icon={<ExploreIcon />}
            title={t("dex")}
            section={"dex"}
            /*   link={"/dex"} */
          >
            <IwdTreeItem
              uuid={items.dexIndex}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"dex"}
              link={"/dex"}
            />
            <IwdTreeItem
              uuid={items.connectionCreate}
              icon={<AddCircleIcon />}
              title={t("dex_create")}
              section={"dex"}
              link={"/dex/create"}
            />
          </IwdTreeItem>
        </List>
      </TreeView>
      <Box sx={{ padding: 1, color: theme.palette.primary.contrastText }}>
        Version: {process.env.REACT_APP_VERSION}
      </Box>
    </Drawer>
  );
}
