import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const Banner = () => {
  const theme = useTheme();

  const [timeChek, setTimeChek] = useState(null);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [banner, setBanner] = useState(null);
  const storageBanner = localStorage.getItem("banner");

  const handleBannerClose = () => {
    localStorage.setItem("banner", "inactive");
    setBanner(false);
  };
  const fromDate = new Date(
    window?.BANNER?.fromDate.year,
    window?.BANNER?.fromDate.month,
    window?.BANNER?.fromDate.day,
    window?.BANNER?.fromDate.houe,
    window?.BANNER?.fromDate.minute
  );
  const toDate = new Date(
    window?.BANNER?.toDate.year,
    window?.BANNER?.toDate.month,
    window?.BANNER?.toDate.day,
    window?.BANNER?.toDate.houe,
    window?.BANNER?.toDate.minute
  );
  const now = new Date();

  useEffect(() => {
    if (now >= fromDate && now <= toDate) {
      setTimeChek(true);
    } else {
      setTimeChek(false);
    }
  }, [now]);

  return (
    <>
      {window.BANNER.state && storageBanner === "active" && timeChek && (
        <Box
          sx={{
            color: theme.palette.primary.contrastText,
            position: "fixed",
            top: 0,
            width: appBarIsOpen ? "88%" : "100%",
            background: theme.palette.primary.main,
            ml: appBarIsOpen && 30,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 1rem",
            zIndex: 10000,
          }}
        >
          <Typography variant="subtitle1">{`${window.BANNER.text}`}</Typography>
          <IconButton
            sx={{ marginLeft: "auto", mr: appBarIsOpen ? 2.7 : 1.7 }}
            onClick={handleBannerClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default Banner;
