import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "./slices/api";
import logoutSlice from "./slices/logoutSlice";
import campaignSlice from "./slices/CampaignSlice";
import dashboardSlice from "./slices/dashboardSlice";
import iwdWebSocketSlice from "./slices/iwdWebSocketSlice";
import digitalTwinSlice from "./slices/digitalTwinSlice";
import widgetsSlice from "./slices/widgetsSlice";
import clusterSlice from "./slices/clusterSlice";
import loraSlice from "./slices/loraSlice";
import { digitalTwinInstanceRtk } from "./slices/QdtiSlice";
import { dataApi } from "./slices/GetDataRtq";
import getImageSlice from "./slices/getImageSlice";
import socketMiddleware from "./middleware/socketMiddleware";
import meterReadingSlice from "./slices/meterReadingSlice";
import deviceMessageSlice from "./slices/deviceMessageSlice";
import userPreferenceSlice from "./slices/userPreferenceSlice";
import trashSlice from "./slices/trashSlice";
import auditSlice from "./slices/auditSlice";
import statisticsSlice from "./slices/statisticsSlice";
import breadCrumbMeterSlice from "./slices/breadCrumbMeterSlice";
import aegisSlice from "./slices/aegisSlice";
import messageSlice from "./slices/messageSlice";
import notesSlice from "./slices/notesSlice";
import loadingSlice from "./slices/loadingSlice";
import utilsSlice from "./slices/utilsSlice";
import userSlice from "./slices/userSlice";
import dexDataSourceSlice from "./slices/dexDataSourceSlice";
import errorTypeSlice from "./slices/errorTypeSlice";
import deviceDistrictSlice from "./slices/deviceDistrictSlice";
import authSlice from "./slices/authSlice";
import errorSlice from "./slices/errorSlice";
import snackbarSlice from "./slices/snackbarSlice";
import meterSlice from "./slices/meterSlice";
import { gatewayApi } from "./slices/gatewayApi";
import gatewaySlice from "./slices/gatewaySlice";
import condominiumSlice from "./slices/condominiumSlice";
import { authApi } from "./slices/authApi";
import apiInit from "./slices/apiInit";
import alarmsSlice from "./slices/alarmsSlice";
import errorAssociationSlice from "./slices/errorAssociationSlice";
/* console.log("API : ", api); */

export const store = apiInit(api);

const rootReducer = combineReducers({
  logoutSlice,
  condominiumSlice,
  alarmsSlice,
  meterSlice,
  gatewaySlice,
  notesSlice,
  auditSlice,
  snackbarSlice,
  utilsSlice,
  clusterSlice,
  deviceDistrictSlice,
  statisticsSlice,
  messageSlice,
  errorAssociationSlice,
  authSlice,
  breadCrumbMeterSlice,
  aegisSlice,
  userSlice,
  trashSlice,
  dashboardSlice,
  campaignSlice,
  userPreferenceSlice,
  getImageSlice,
  widgetsSlice,
  loadingSlice,
  loraSlice,
  errorSlice,
  errorTypeSlice,
  digitalTwinSlice,
  iwdWebSocketSlice,
  meterReadingSlice,
  deviceMessageSlice,
  dexDataSourceSlice,
  [api.reducerPath]: api.reducer,
  [digitalTwinInstanceRtk.reducerPath]: digitalTwinInstanceRtk.reducer,
  /*   [dataApi.reducerPath]: dataApi.reducer, */
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(api.middleware)
        /* .concat(dataApi.middleware) */
        /*  .concat(digitalTwinInstanceRtk.middleware) */
        .concat([socketMiddleware]),
  });
};
/* const store = setupStore();
export default store; */
