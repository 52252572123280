import { onChange } from "@jsonforms/examples";
import TextField from "@mui/material/TextField";
import { isNumber } from "lodash";
import i18n from "i18next";

import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
/**
 * A custom TextField component for handling decimal numbers and displaying them correctly based on the application's localization.
 *
 * @component TextFieldNumber
 * @param {string} value - The current value of the TextField.
 * @param {string} label - The label for the TextField.
 * @param {boolean} error - Indicates whether there is an error with the TextField.
 * @param {function} onChange - Callback function to handle value changes.
 * @param {string} helperText - The helper text to be displayed below the TextField.
 * @param {Object} sx - Additional styles for customizing the TextField.
 * @returns {JSX.Element} A customized TextField component.
 * @example
 * // Example usage of TextFieldNumber
 * const handleValueChange = (newValue) => {
 *   console.log("New value:", newValue);
 * };
 *
 * return (
 *   <TextFieldNumber
 *     value={currentValue}
 *     label="Enter a decimal number"
 *     error={isInvalid}
 *     onChange={handleValueChange}
 *     helperText="Please enter a valid decimal number."
 *     sx={{ width: "100%", color: "primary" }}
 *   />
 * );
 */

const TextFieldNumber = ({
  value,

  label,
  error,
  onChange,
  helperText,
  sx,
  ...props
}) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("Current Language:", currentLanguage);
  const showValue = t("number", {
    val: value,
    maximumFractionDigits: 3,
  });

  const onValueChange = (e) => {
    const textFieldValue = e.target.value;

    const decimalRegex = /^-?\d*([.,]\d{0,3})?$/;

    if (decimalRegex.test(textFieldValue)) {
      const formattedValue = textFieldValue.replace(",", ".");
      onChange(formattedValue);
    }
  };

  useEffect(() => {
    console.log("hatethisshnyaga", value);
  }, [value]);

  return (
    <TextField
      value={
        currentLanguage === "it-IT" ? showValue.replaceAll(".", ",") : showValue
      }
      onChange={(e) => onValueChange(e)}
      label={label}
      error={error}
      helperText={helperText}
      sx={sx}
      {...props}
    />
  );
};

export default TextFieldNumber;
