import * as React from "react";
import Box from "@mui/material/Box";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { store } from "../../redux/store";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { setApartametnMeterModal } from "../../redux/slices/condominiumSlice";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setTreeExpanded } from "../../redux/slices/condominiumSlice";
import {
  useAssociateMeterMutation,
  useUpdateCondominiumIfnoMutation,
} from "../../redux/slices/condominiumApi";

import { setTreeItem } from "../../redux/slices/condominiumSlice";
import { loadingOff, loadingOn } from "../../redux/slices/loadingSlice";
import { Link } from "react-router-dom";
import { api } from "../../redux/slices/api";

const ApartamentMeterTable = ({
  data,
  response,
  perPage,
  page,
  setPage,
  hideAccordion,
  loading,
}) => {
  const [associateMeter, { isSuccess }] = store.useAssociateMeterMutation();
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const [updateCondominiumInfo, { isSuccess: updateSuccess }] =
    store.useUpdateCondominiumIfnoMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [getApartamentId] = store.useLazyGetApartamentIdQuery();
  const [getSubconId] = store.useLazyGetSubCondominiumIdQuery();
  const [updatedSecuruty, setUpdatedSecurity] = React.useState(null);
  const [coordinates, setCoordinates] = React.useState(null);
  const [tags, setTags] = React.useState(null);
  const [meterClass, setMeterClass] = React.useState(null);
  const [serial, setSerial] = React.useState(null);
  const [twinId, setTwinId] = React.useState(null);
  const [instanceId, setInstanceId] = React.useState(null);
  const [getCindominiumShow] = store.useLazyCondominiumShowQuery();
  const apartamentIdentifier = useSelector(
    (state) => state.condominiumSlice.apartamentIdentifier
  );
  const apartamentId = useSelector(
    (state) => state.condominiumSlice.apartamentId
  );
  const treeExpanded = useSelector(
    (state) => state?.condominiumSlice?.treeExpanded
  );

  const subCondominiumIdentifier = useSelector(
    (state) => state.condominiumSlice.subCondominiumIdentifier
  );
  const subCondominiumName = useSelector(
    (state) => state.condominiumSlice.subCondominiumName
  );
  const apartamentName = useSelector(
    (state) => state.condominiumSlice.apartamentName
  );
  const condominiumIdentifier = useSelector(
    (state) => state.condominiumSlice.condominiumIdentifier
  );
  const condominiumName = useSelector(
    (state) => state.condominiumSlice.condominiumName
  );
  const subCondominiumId = useSelector(
    (state) => state.condominiumSlice.subCondominiumId
  );
  const [meterId, setMeterId] = React.useState(null);
  const onAssociateClick = (params) => {
    console.log("parixx", params);
    dispatch(loadingOn());
    setSerial(params.serial ?? params?.serial_number);
    setUpdatedSecurity(
      mergeObjects(params?.security, condominiumShow?.security?.acl)
    );
    setCoordinates(params?.coordinates);
    setTags(params?.tags);
    setMeterClass(params?.class);
    dispatch(setTreeItem(serial));
    console.log(params);
    setTwinId(params.twinId);
    setInstanceId(params.id);
    const meterId = params.id;
    associateMeter({
      identifier: apartamentIdentifier,
      attributes: { device_id: +meterId },
    });
  };
  function mergeObjects(obj1, obj2) {
    if (obj1 !== undefined && obj2 !== undefined) {
      debugger;
      const result = Object.values(obj1);

      obj2.forEach((item2) => {
        const matchingIndex = result.findIndex(
          (item1) => item1.name === item2.name
        );

        if (matchingIndex !== -1) {
          result[matchingIndex] = { ...result[matchingIndex], ...item2 };
        } else {
          result.push({ ...item2 });
        }
      });

      return result;
    } else {
      console.log(" undefined obj ");
      return [];
    }
  }
  React.useEffect(() => {
    console.log("updsc", updatedSecuruty);
  }, [updatedSecuruty]);

  const updateInfo = () => {
    const attributes = {
      fields: {
        coordinates: coordinates,
        class: meterClass,
        /*  tag: "AltiorEdge", */
        serial_number: serial,
        tags: tags,
        apartment_name: apartamentName,
        condominium_id: id,
        condominium_name: condominiumName,
        sub_condominium_id: subCondominiumId,
        apartment_identifier: apartamentIdentifier,
        apartment_id: apartamentId,
        sub_condominium_name: subCondominiumName,
        condominium_identifier: condominiumIdentifier,
        sub_condominium_identifier: subCondominiumIdentifier,
        security: {
          acl: updatedSecuruty,

          groups: ["/network_adapter_manager_router", "/codec_manager"],
        },
      },
      meta_data: {},
    };
    updateCondominiumInfo({
      twinId: twinId,
      instanceId: instanceId,
      attributes: attributes,
    });
  };
  React.useEffect(() => {
    if (apartamentIdentifier) {
      getApartamentId(apartamentIdentifier);
    }
  }, [apartamentIdentifier]);
  React.useEffect(() => {
    if (subCondominiumIdentifier) {
      getSubconId(subCondominiumIdentifier);
    }
  }, [subCondominiumIdentifier]);
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => updateInfo(), 500);
    }
  }, [isSuccess]);
  React.useEffect(() => {
    if (updateSuccess && serial) {
      setTimeout(() => {
        dispatch(setMessage("Meter associato con successo"));

        const newPath = window.location.pathname + `/apartamentMeter/${serial}`;
        navigate(newPath);
        dispatch(setApartametnMeterModal(false));
        getCindominiumShow(id);
        dispatch(setTreeExpanded([...treeExpanded, apartamentName]));
        dispatch(loadingOff());
      }, 500);
    }
  }, [updateSuccess, serial]);
  const theme = useTheme();
  const { t } = useTranslation();
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },

    {
      field: "tag",
      headerName: t("tag"),
      flex: 3,

      sortable: false,

      renderCell: (params) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => <Chip label={t(item)} />)
          )}
        </Box>
        /*   <List>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => (
              <ListItem>
                <ListItemText primart={item} />
              </ListItem>
               <Chip label={t(item)} />
            ))
          )}
        </List> */
      ),
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,

      flex: 1,
      headerAlign: "left",
    },

    {
      field: "address",
      headerName: t("street_address"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    /*  {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
    }, */

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onAssociateClick(params.value)}
            >
              <AddIcon sx={{ ml: 2 }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  React.useEffect(() => {
    console.log("danunahuivpi", data);
  }, [data]);
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags.map((item) => item)
      : "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--",
    /* diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--", */
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: {
      id: item.id,
      serial:
        item.attributes.fields.serial ?? item.attributes.fields.serial_number,
      twinId: item?.attributes?.digital_twin_id,
      security: item?.attributes?.fields?.security?.acl,
      class: item?.attributes?.fields?.class,
      coordinates: item?.attributes?.fields?.coordinates,
      tags: item?.attributes?.fields?.tags,
    },
  }));

  return (
    <Box sx={{ height: "40vh", width: "99%" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={response?.data?.meta?.count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          !response.isFetching && setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default ApartamentMeterTable;
