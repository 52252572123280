import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import DeviceMap from "../metrics/MapLeaflet";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
const GatewayRegistri = ({ gateway, idFromCondominium, update = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fieldsToUpdate = useSelector(
    (state) => state?.gatewaySlice?.fieldsToUpdate
  );
  const [showPassword, setShowPassword] = useState(false);
  /*  const fields = result?.data?.data?.attributes?.fields; */
  const [trigger, res, lastPromiseInfo] = store.useLazyGetDataQuery();
  /*   const filteredType = res?.data?.data?.attributes?.filter(
    (item) => item.value == fields?.type
  ); */
  const key = gateway.fields?.identifier_type;
  /*   useEffect(() => {
    if (result?.data) {
      trigger("plugin/en137577");
    }
  }, [result]); */
  const offMap =
    gateway.fields.coordinates?.coordinates[0] == 0 &&
    gateway.fields.coordinates?.coordinates[1] == 0;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onUpdateClick = () => {
    if (!idFromCondominium) {
      navigate("edit");
    } else {
      window.open(`/gateways/${fieldsToUpdate.instanceId}/edit`, "_blank");
    }
  };
  useEffect(() => {
    console.log("fldstpdt", fieldsToUpdate);
  }, [fieldsToUpdate]);
  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={offMap ? 12 : 8}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`name`)} :</Typography>
            <Typography>{gateway.fields?.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`type`)} :</Typography>
            <Typography>{gateway.fields?.digital_twin_name}</Typography>
          </Grid>
          {gateway.fields?.identifier_type && (
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t(`${gateway.fields?.identifier_type}`)} :
              </Typography>
              <Typography>{gateway.fields?.[key]}</Typography>
            </Grid>
          )}

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("coordinates")} :</Typography>
            <Typography variant="subtitle1">{`Lat : ${gateway?.fields?.coordinates?.coordinates[1]}`}</Typography>
            <Typography variant="subtitle1">{`Lng : ${gateway?.fields?.coordinates?.coordinates[0]}`}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("instalation_data")} :{" "}
            </Typography>
            <Typography variant="subtitle1">
              {t("dt", {
                val: new Date(gateway?.inserted_at),
              })}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("time_zone")} :</Typography>
            <Typography variant="subtitle1">
              {gateway?.fields?.time_zone ?? "--"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_imei`)} :</Typography>
            <Typography>{gateway.fields?.imei ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_mode`)} :</Typography>
            <Typography>
              {gateway.fields?.network_technology_in_use ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_iccid`)} :</Typography>
            <Typography>{gateway.fields?.iccid ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`network_quality`)} :
            </Typography>
            <Typography>
              {gateway.fields?.network_quality ? (
                <>
                  {" "}
                  {gateway.fields?.network_quality}{" "}
                  {gateway.fields?.network_quality_type}{" "}
                </>
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_operator`)} :</Typography>
            <Typography>{gateway.fields?.modem_operator ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`temperature`)} :</Typography>
            <Typography>{gateway.fields?.temperature ?? "-"}</Typography>
          </Grid>
          {/*      <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`uptime`)} :</Typography>
            <Typography>{gateway.fields?.uptime ? timeAgo(gateway.fields?.uptime) : "-"}</Typography>
          </Grid> */}
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`battery_percentage`)} :
            </Typography>
            <Typography>
              {gateway.fields?.battery_level
                ? `${gateway.fields?.battery_level} %`
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`tag`)} :</Typography>
            <Typography>{gateway.fields?.tag ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`version_sw_modem`)} :
            </Typography>
            <Typography>{gateway.fields.firmware_version ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`identifier`)} :</Typography>
            <Typography>{gateway.fields?.identifier ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`phone_number`)} :</Typography>
            <Typography>{gateway.fields?.msisdn ?? "-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`rf_technologies`)} :
            </Typography>
            <Typography>
              {gateway.fields?.rf_technologies
                ? gateway.fields?.rf_technologies.map((rf_technologie) =>
                    Array?.isArray(rf_technologie) ? (
                      <Chip label={rf_technologie} />
                    ) : (
                      ""
                    )
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`rf_technology_in_use`)} :
            </Typography>
            <Typography>
              {gateway?.fields?.rf_technology_in_use ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`weight`)} :</Typography>
            <Typography>
              {gateway.fields?.weight ? (
                <>
                  {gateway.fields?.weight.value} {gateway.fields?.weight.unit}{" "}
                </>
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`position`)} :</Typography>
            <Typography>{gateway?.fields?.note ?? "--"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`security`)} :</Typography>

            {gateway?.fields?.security?.acl?.map((item) => (
              <Box display={"flex"} sx={{ mt: 1 }}>
                <Typography>{item.name} </Typography>
                {item?.scopes?.map((item) => (
                  <Chip label={item} sx={{ ml: 2 }} />
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
        <Grid container xs={4} sx={{ ml: 4 }}>
          {!offMap && (
            <Grid item xs={12}>
              <Box>
                <DeviceMap pos={gateway?.fields?.coordinates} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {update && (
        <Button
          variant="contained"
          sx={{ float: "right", mt: 2 }}
          onClick={onUpdateClick}
        >
          Modifica concentratore
        </Button>
      )}
    </>
  );
};

export default GatewayRegistri;
