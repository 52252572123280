import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = { data: null, from: null, to: null, consumptionsTo: null };

const meterReadingSlice = createSlice({
  name: "plugiSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    cleanData(state) {
      state.data = null;
    },
    setFrom(state, action) {
      state.from = action.payload;
    },
    setTo(state, action) {
      state.to = action.payload;
    },
    setConsumptionsTo(state, action) {
      state.consumptionsTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.meterReadingGraphEndpoints.getMeterReadingGraph.matchFulfilled,
      (state, action) => {
        state.data = action.payload.reverse();
      }
    );
  },
});
export const { setData, cleanData, setFrom, setTo, setConsumptionsTo } =
  meterReadingSlice.actions;

export default meterReadingSlice.reducer;
