import { Grid } from "@mui/material";
export const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props?.properties?.map((element) => {
        return (
          <Grid key={element?.id} alignItems="center" item xs={6}>
            {element?.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
