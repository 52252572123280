import { array } from "@jsonforms/examples";
import { createSlice } from "@reduxjs/toolkit";
import { messageApi } from "./messageApi";
import { store } from "../store";
const initialState = {
  readingMessages: null,
  diagnosticMessages: null,
  readingCount: null,
  diagnosticCount: null,
  liveMessages: [],
};

const messageSlice = createSlice({
  name: "messageSlice",
  initialState,
  reducers: {
    setMessages(state, action) {
      state.readingMessages = action.payload.filter(
        (item) => item.values.value_type === "reading"
      );
      state.diagnosticMessages = action.payload.filter(
        (item) => item.values.value_type === "diagnostic"
      );
    },
    setLiveMessages(state, action) {
      const newMessages = action?.payload?.payload?.map((item) => item);
      if (state.liveMessages.length == 20) {
        const elemsToDelete = newMessages.length;
        const refreshedMessages = state.liveMessages.filter(
          (x, i) => i + elemsToDelete < state.liveMessages.length
        );
        state.liveMessages = [...newMessages, ...refreshedMessages];
      } else {
        state.liveMessages = [...newMessages, ...state.liveMessages];
      }
    },
    clearLiveMessages(state) {
      state.liveMessages = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.messageEndpoints.getDiagnosticMessages.matchFulfilled,
      (state, action) => {
        state.diagnosticMessages = action.payload.data.map(
          (item) => item.attributes,
          (state.diagnosticCount = action.payload.meta.count)
        );
      }
    );
    builder.addMatcher(
      store.messageEndpoints.getReadingMessages.matchFulfilled,
      (state, action) => {
        state.readingMessages = action.payload.data.map(
          (item) => item.attributes,
          (state.readingCount = action.payload.meta.count)
        );
      }
    );
  },
});
export const { setMessages, setLiveMessages, clearLiveMessages } =
  messageSlice.actions;
export default messageSlice.reducer;
