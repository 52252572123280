import * as React from "react";
import Popover from "@mui/material/Popover";

import ErrorIcon from "@mui/icons-material/Error";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";

const PopoverDashboardCardError = ({ error }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {}, [error]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ pl: 1 }}>
      <ErrorIcon
        onClick={handleClick}
        sx={{ fill: theme.palette.error.main }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
          <Typography>{`Error message : ${error.data}`}</Typography>
          <Typography>{`Error date: ${error?.date}`}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default PopoverDashboardCardError;
