import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import { store } from "../redux/store";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { setCondomiumShow } from "../redux/slices/condominiumSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { condominiumApi } from "../redux/slices/condominiumApi";
import SecurityWidget from "../components/form/formComponents/customWidgets/SecurityWidget";
import { TreeItem, TreeView } from "@mui/lab";
import LongNameWrapper from "../utils/LongNameWrapper";

import { setMessage } from "../redux/slices/snackbarSlice";
import { offFirstRender } from "../redux/slices/condominiumSlice";
import { useLazyGetCondominiumIdQuery } from "../redux/slices/condominiumApi";
import { useSelector, useDispatch } from "react-redux";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { setTreeExpanded } from "../redux/slices/condominiumSlice";
import { CondominiumTree } from "../components/condominium/CondominiumTree";
import CondominiumReadingsTab from "./CondominiumReadingsTab";
import CondominiumConsumtionsTab from "./CondominiumConsumtionsTab";
import { cleanData } from "../redux/slices/condominiumSlice";
import CondominiumTabs from "../components/condominium/CondominiumTabs";
import { IwdFormAuthWrapper } from "../utils/IwdFormAuthWrapper";
import theme from "../theme";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
export const CondominiumShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scopeSaver, setScopeSaver] = useState(false);
  const condominumMeter = useSelector(
    (state) => state?.condominiumSlice?.condominumMeter
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const [getCondominiumId] = store.useLazyGetCondominiumIdQuery();
  const [updateCondominium, { isSuccess: updateSuccess }] =
    store.useUpdateCondominiumIfnoMutation();
  const [firstRender, setFirstRender] = useState(true);
  const [createdId, setCreatedId] = useState(null);
  const [condominiumCreate, { isSuccess: createSuccess }] =
    store.useCreateCondominiumMutation();

  const [formData, setFormData] = useState({});
  const { id } = useParams();

  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const identifierId = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.identifier;
  const condominiumTreeData = useSelector(
    (state) => state?.condominiumSlice?.condominiumTreeData
  );
  const condominiumId = useSelector(
    (state) => state.condominiumSlice.condominiumId
  );
  const security = IwdFormAuthWrapper({ section: "security" });
  useEffect(() => {
    if (createdId) {
      getCondominiumShow(createdId);
      navigate(`/condominiums/${createdId}`);
    }
  }, [createdId]);

  useEffect(() => {
    if (identifierId && !condominiumId) {
      getCondominiumId(identifierId);
    }
  }, [identifierId, condominiumId]);
  useEffect(() => {
    console.log("ID IS", id);
  }, [id]);
  useEffect(() => {
    if (id && !condominiumShow) {
      getCondominiumShow(id);
    }
  }, [id, condominiumShow]);
  useEffect(() => {
    if (condominiumShow) {
      setFormData(condominiumShow);
      setFirstRender(false);
    }
  }, [condominiumShow]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [updateSuccess]);

  useEffect(() => {
    console.log("seca", condominiumShow);
  }, [condominiumShow]);
  useEffect(() => {
    console.log("fmdtttt", formData);
  }, [formData]);

  const { t } = useTranslation();

  const schema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
              $id: 1,
              title: t("avatar_url"),
              type: "string",
            }, */
      address: {
        $id: "3",
        type: "string",
        title: t("street_address"),
      },
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        readOnly: id && true,
      },
      zip_code: {
        $id: "2",
        type: "string",
        title: t("zip_code"),
      },

      province: {
        $id: "4",
        type: "string",
        title: t("province"),
      },
    },
    required: ["address", "name", "zip_code", "province"],
  };
  const securitySchema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
              $id: 1,
              title: t("avatar_url"),
              type: "string",
            }, */
      address: {
        $id: "3",
        type: "string",
        title: t("street_address"),
      },
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        readOnly: id && true,
      },
      zip_code: {
        $id: "2",
        type: "string",
        title: t("zip_code"),
      },

      province: {
        $id: "4",
        type: "string",
        title: t("province"),
      },
      security: {
        $id: "2",
        type: "array",
        title: t("security"),
        default: condominiumShow?.security.acl,
      },
    },
    required: ["address", "name", "zip_code", "province"],
  };
  useEffect(() => {
    dispatch(offFirstRender());
  }, []);
  const onCreateClick = () => {
    console.log(formData);

    if (id) {
      /* const data = {
        name: formData.name,
        address: formData.address,
        zip_code: formData.zip_code,
        province: formData.province,
        coordinates: formData.coordinates,
      };
      updateCondominium({
        attributes: data,
        id: id,
      }); */
      const attributes = {
        fields: {
          coordinates: condominumMeter?.attributes?.coordinates,
          class: condominumMeter?.attributes.class,
          tag: condominumMeter?.attributes?.fields?.tag,
          serial_number: condominumMeter?.attributes?.serial_number,
          condominium_id: condominumMeter?.id,
          name: condominumMeter?.attributes?.fields?.name,
          identifier: condominumMeter?.attributes?.fields?.identifier,
          identifier_type: condominumMeter?.attributes?.fields?.identifier_type,
          address: formData.address,
          zip_code: formData.zip_code,
          province: formData.province,
        },
        meta_data: {},
      };
      const securityAttributes = security && {
        fields: {
          coordinates: condominumMeter?.attributes?.coordinates,
          class: condominumMeter?.attributes.class,
          tag: condominumMeter?.attributes?.fields?.tag,
          serial_number: condominumMeter?.attributes?.serial_number,
          condominium_id: condominumMeter?.id,
          name: condominumMeter?.attributes?.fields?.name,
          identifier: condominumMeter?.attributes?.fields?.identifier,
          identifier_type: condominumMeter?.attributes?.fields?.identifier_type,
          address: formData.address,
          zip_code: formData.zip_code,
          province: formData.province,
          security: {
            acl: [...formData?.security],
            groups: ["/network_adapter_manager_router", "/codec_manager"],
          },
        },
        meta_data: {},
      };
      updateCondominium({
        twinId: condominumMeter?.attributes?.digital_twin_id,
        instanceId: condominumMeter?.id,
        attributes: security ? securityAttributes : attributes,
      });
    } else {
      condominiumCreate({
        ...formData,
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [45.416667, 11.883333],
          type: "Point",
        },
      })
        .unwrap()
        .then((data) => setCreatedId(data.data.id))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
    window.sessionStorage.setItem("bcName", formData.name);
  };
  useEffect(() => {
    if (condominiumShow) {
      window.sessionStorage.setItem("bcName", condominiumShow?.name);
    }
  }, [condominiumShow?.name]);

  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("save"),
      classNames: "submit-button",
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    security: {
      "ui:field": "security",
    },
  };

  const customValidate = (formData, errors) => {
    if (formData?.roles?.length < 1) {
      errors?.roles?.addError("is a required property");
    }
    return errors;
  };

  const fields = {
    security: SecurityWidget,
  };

  return (
    <>
      {!id && (
        <Typography
          sx={{ mb: 4, color: theme.palette.primary.main }}
          variant="h1"
        >
          {t("new_condominium")}
        </Typography>
      )}
      {condominiumShow && (
        <LongNameWrapper
          text={condominiumShow?.name}
          size={appBarIsOpen ? "75vw" : "95vw"}
          variant={"h1"}
          maxLength={100}
        />
      )}
      <Grid container>
        {id && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {condominiumShow && (
                <CondominiumTree
                  condominium={condominiumTreeData}
                ></CondominiumTree>
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={condominiumShow ? 10 : 12}>
          {id && !isFetching && !firstRender && (
            <CondominiumTabs
              mainTab={
                <Form
                  schema={security ? securitySchema : schema}
                  uiSchema={uiSchema}
                  fields={fields}
                  formData={formData}
                  onSubmit={onCreateClick}
                  onChange={(changeEvent) => setFormData(changeEvent.formData)}
                  validator={validator}
                  showErrorList={false}
                  customValidate={customValidate}
                  noHtml5Validate
                />
              }
              readings={<CondominiumReadingsTab condominiumId={id} />}
              consume={<CondominiumConsumtionsTab condominiumId={id} />}
            />
          )}
          {!id && !isFetching && (
            <Form
              schema={security ? securitySchema : schema}
              uiSchema={uiSchema}
              fields={fields}
              formData={formData}
              onSubmit={onCreateClick}
              onChange={(changeEvent) => setFormData(changeEvent.formData)}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
