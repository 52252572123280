import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import ErrorTypeSelector from "./ErrorTypeSelector";
import { useDispatch, useSelector } from "react-redux";
import ParametersHandler from "./ParametersHandler";
import VisibilityHandler from "./VisibilityHandler";
import RawErrorCustomField from "./RawErrorCustomField";
import BlockConsumptions from "./BlockConsumptions";
import { Button, Grid, Typography } from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ErrorAssociationShow = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const error = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociation
  );
  const [getErrorAssociation] = store.useLazyGetErrorAssociationQuery();
  const [updateErrorAssociation, { isSuccess: updateSuccess }] =
    store.useUpdateErrorAssociationMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("erixon", error);
  }, [error]);
  useEffect(() => {
    getErrorAssociation(id);
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage("Aggiornato con successo"));
    }
  }, [updateSuccess]);
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      error_type_id: {
        type: "string",
        title: t("error_type"),
        default: error?.error_type_id,
      },
      raw_error: {
        type: "object",
        properties: {
          error: {
            type: "string",
            default: error?.raw_error,
          },
          class: {
            type: "string",
            default: error?.class,
          },
        },
      },
      block_consumption_computation: {
        type: "boolean",
        title: t("block_consumption_computation"),
        default: error?.block_consumption_computation,
      },
      visibility: {
        $id: "1",
        type: "object",
        title: t("visibility"),
        properties: {
          groups: {
            type: "object",
            default: { ...error?.visibility?.groups },
          },
          users: {
            type: "object",
            default: { ...error?.visibility?.users },
          },
          comdominium_admin: {
            type: "boolean",
            default: error?.visibility?.comdominium_admin,
          },
        },
      },
      notify: {
        $id: "1",
        type: "object",
        title: t("notify"),
        properties: {
          groups: {
            type: "object",
            default: { ...error?.notify?.groups },
          },
          users: {
            type: "object",
            default: { ...error?.notify?.users },
          },
          comdominium_admin: {
            type: "boolean",
            default: error?.notify?.comdominium_admin,
          },
        },
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    visibility: {
      "ui:field": "parameters",
    },
    notify: {
      "ui:field": "parameters",
    },
    error_type_id: {
      "ui:field": "errortype",
    },
    block_consumption_computation: {
      "ui:field": "BlockConsumptions",
    },
    raw_error: { "ui:field": "rawerror" },
    "ui:order": [
      "raw_error",
      "block_consumption_computation",
      ,
      "error_type_id",
      "visibility",
      "notify",
    ],
  };
  const fields = {
    parameters: VisibilityHandler,
    errortype: ErrorTypeSelector,
    rawerror: RawErrorCustomField,
    BlockConsumptions: BlockConsumptions,
  };
  const onSubmitClick = (obj) => {
    const attributes = {
      class: obj.raw_error?.class,
      raw_error: obj.raw_error.error,
      visibility: obj.visibility,
      notify: obj.notify,
      error_type_id: obj.error_type_id,
      block_consumption_computation: obj.block_consumption_computation,
    };
    updateErrorAssociation({ id: id, attributes: attributes });
  };
  useEffect(() => {
    console.log("frmdtd", formData);
  }, [formData]);
  return (
    <>
      {error && (
        <>
          <Typography
            variant="h3"
            sx={{ mb: 3, color: theme.palette.primary.main }}
          >{`${t("error_association")}: ${error?.raw_error}`}</Typography>
          <Form
            schema={schema}
            fields={fields}
            formData={formData}
            /*  customValidate={customValidate} */
            showErrorList={false}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            validator={validator}
            noHtml5Validate
            uiSchema={uiSchema}
          />
        </>
      )}
    </>
  );
};
export default ErrorAssociationShow;
