import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Typography } from "@mui/material";

import { store } from "../redux/store";
import QRCode from "react-qr-code";
import IwdSnackBar from "../components/IwdSnackBar";

const UserInfoItem = ({ title, value }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{title} :</Typography>

      <Typography variant="subtitle1">{value ?? "-"}</Typography>
    </Box>
  );
};

function MyAccount() {
  const { t } = useTranslation();
  const [getPreference] = store.useLazyGetPreferenceQuery();
  const { userInfo } = useSelector((state) => state.userPreferenceSlice);
  const dispatch = useDispatch();
  const [code, setCode] = useState();
  const [deleteOTP, { isSuccess: isSuccessDelete }] =
    store.useDeleteOTPMutation();
  const [verifyOTP, { isSuccess: isSuccessVerify }] =
    store.useVerifyOTPMutation();
  const [createOTP, { isSuccess: isSuccessCreate, data: otpData }] =
    store.useCreateOTPMutation();

  useEffect(() => {
    if (Object.keys(userInfo).length == 0) {
      getPreference();
    }
  }, []);

  useEffect(() => {
    if (isSuccessDelete) {
      //Without setTimeout doesn't work...
      setTimeout(() => {
        dispatch(createOTP());
      }, 500);
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccessVerify) {
      setCode(null);
    }
  }, [isSuccessVerify]);

  const handleOnClikc = () => {
    dispatch(deleteOTP());
  };

  if (!userInfo) {
    return <> Da mettere loading </>;
  }
  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h3">
        {t("userInfo")}
      </Typography>
      <Box sx={{ display: "flex", gap: 5 }}>
        <Box sx={{ display: "flex", columnGap: 5 }}>
          <Box
            sx={{
              display: "flex",
              rowGap: 6,

              flexDirection: "column",
            }}
          >
            <UserInfoItem title={t("firstName")} value={userInfo.first_name} />
            <UserInfoItem title={t("email")} value={userInfo.email} />
            <UserInfoItem
              title={t("displayName")}
              value={userInfo.display_name}
            />
          </Box>
          <Box sx={{ display: "flex", rowGap: 6, flexDirection: "column" }}>
            <UserInfoItem title={t("lastName")} value={userInfo.last_name} />

            <UserInfoItem title={t("userName")} value={userInfo.user_name} />
            <UserInfoItem title={t("nickName")} value={userInfo.nick_name} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {isSuccessCreate && !isSuccessVerify ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <QRCode value={otpData.data.attributes.url} />
              <TextField
                id="code"
                label={t("code")}
                variant="outlined"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                id="otp-secret"
                label={t("secret")}
                variant="outlined"
                value={otpData.data.attributes.secret}
                disabled
              />
              <Button
                variant="contained"
                color="error"
                onClick={() => dispatch(verifyOTP({ code: code }))}
              >
                {t("verifyOTP")}
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="error" onClick={handleOnClikc}>
              {t("resetOTP")}
            </Button>
          )}
        </Box>
      </Box>
      {isSuccessVerify ? (
        <IwdSnackBar
          severity="success"
          message={t("otpResetOk")}
          isOpen={isSuccessVerify}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}

export default MyAccount;
