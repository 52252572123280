import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import { store } from "../../redux/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";

import TransferList from "../TransferList";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { unsetUserRoles } from "../../redux/slices/userSlice";

import TrashModal from "../TrashModal";
const UserShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const userRoles = useSelector((state) => state?.userSlice?.userRoles);
  const allRoles = useSelector((state) => state?.aegisSlice?.roles);
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const [getGrant] = store.useLazyGetGrantQuery();
  const [getAllRoles] = store.useLazyGetAllrolesQuery();
  const { id } = useParams();
  const grantId = useSelector((state) => state?.userSlice?.grantId);
  const { t } = useTranslation();
  const userShow = useSelector((state) => state?.userSlice?.userShow);
  const data = userShow?.attributes;
  useEffect(() => {
    getUserShow(id);
    /*  getGrant(id); */
    getAllRoles();
  }, []);
  const userStateChek = (state) => {
    switch (state) {
      case "USER_STATE_UNSPECIFIED":
        return t("state_unspecified");
      case "USER_STATE_ACTIVE":
        return t("state_active");
      case "USER_STATE_INACTIVE":
        return t("state_inactive");
      case "USER_STATE_DELETED":
        return t("state_deleted");
      case "USER_STATE_LOCKED":
        return t("state_locked");
      case "USER_STATE_SUSPEND":
        return t("state_suspend");
      case "USER_STATE_INITIAL":
        return t("state_initial");
    }
  };
  const onTrashClick = () => {
    dispatch(opendModal());
  };
  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(id);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  useEffect(() => {
    if (deleteSuccess) {
      navigate("/users");
    }
  }, [deleteSuccess]);
  useEffect(() => {
    return () => {
      dispatch(unsetUserRoles());
    };
  }, []);

  return (
    <>
      {userSuccess && (
        <>
          <Typography sx={{ mb: 4 }} variant="h1">
            {`${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`}
          </Typography>

          <Box>
            <TrashModal
              title={t("delete_user_title")}
              text={t("delete_user_text")}
            />
            <Grid
              container
              alignItems="center"
              rowSpacing={5}
              direction="row"
              spacing={5}
              xs={12}
            >
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("id")} :</Typography>
                <Typography variant="subtitle1">
                  {userShow?.id ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("change_date")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.change_date
                    ? t("dt", { val: new Date(data?.change_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("creation_date")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.creation_date
                    ? t("dt", { val: new Date(data?.creation_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("display_name")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.display_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("email")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.email ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("first_name")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.first_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("gender")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.gender ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("is_email_verified")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.is_email_verified == false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("is_phone_verified")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.is_phone_verified == false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("last_name")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.last_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("login_names")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.login_names ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("nick_name")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.nick_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("otp")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.otp == false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("preferred_login_name")} :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.preferred_login_name ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("state")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.state ? userStateChek(data?.state) : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t("user_name")} :</Typography>
                <Typography variant="subtitle1">
                  {data?.user_name ?? "--"}
                </Typography>
              </Grid>
              {/*   <Grid item xs={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {userRoles?.length > 0 ? (
                    <Box display="flex">
                      <Typography sx={{ mt: 1 }}>
                        {t("user_roles") + ": "}
                      </Typography>
                      <Box>
                        {userRoles.map((item) => (
                          <Chip label={item} sx={{ ml: 1 }} />
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    t("no_roles")
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {userRoles && allRoles && (
                    <TransferList
                      userRoles={userRoles}
                      allRoles={allRoles.filter(
                        (item) => !userRoles.includes(item)
                      )}
                      userId={id}
                      grantId={grantId}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid> */}
            </Grid>

            <Box sx={{ mt: 2 }}>
              <IconButton onClick={onTrashClick}>
                <Tooltip title={t("delete_user_title")}>
                  <DeleteIcon sx={{ fill: "red" }} />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default UserShow;
