import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NetworkChannelWidget = (props) => {
  const [channel, setChannel] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (channel) {
      props.onChange({
        channel,
      });
    }
  }, [channel]);
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Channels</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          label={t("initial_channels")}
        />
      </AccordionDetails>
    </Accordion>
  );
};
export default NetworkChannelWidget;
