import React, { useState } from "react";
import useFetch from "use-http";
import { convertParamsToString, geoJsonPolygonSwapCoordinates } from "../utils";
import { useIwdBreadCrumb } from "./IwdBreadcrumbProvider";

const DeviceDistrictContext = React.createContext();

/**
 * This is the DeviceDistrictProvider and has the following functions:
 *
 * - onSubmit(params)
 *
 *   @param  param object the form fields
 */
const DeviceDistrictProvider = ({ children }) => {
  const { get, loading, error, del, patch, post } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const [page, setPage] = useState();
  const [perPage, setPerPage] = useState();
  const { setLast } = useIwdBreadCrumb();
  const [classes, setClasses] = useState();
  const [polygons, setPolygons] = useState([]);
  const [district, setDistrict] = useState();
  const [districts, setDistricts] = useState();
  const [updating, setUpdating] = useState(false);
  const [searchParams, setDeviceDistrictSearchParams] = useState();
  const [polygonsToRemove, setPolygonsToRemove] = useState([]);
  const [devices, setDevices] = useState();
  const [drawPolygon, setDrawPolygon] = useState(null);
  const [currentPolygon, setCurrentPolygon] = useState();

  const searchWithPage = (newPage, newPerPage) => {
    if (newPage != page || newPerPage != perPage) {
      setPage(newPage);
      setPerPage(newPerPage);
      doCall(newPage, newPerPage);
    }
  };

  const search = (params) => {
    setDeviceDistrictSearchParams(params);
    doCall(page, perPage, params);
  };

  const doCall = async (page, perPage, params) => {
    var completeParams = { page: page, per_page: perPage, ...searchParams };
    if (params) completeParams = { page: page, per_page: perPage, ...params };
    const newDistricts = await get(
      `/api/v1/device_district?${convertParamsToString(completeParams)}`
    );
    setDistricts(newDistricts);
  };
  const setPageAndPerPage = (newPage, newPerPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const onSubmit = (params, callback) => {
    if (updating) {

      updateDistrict(params, callback);
    } else {
      createDistrict(params, callback);
    }
  };

  const updateDistrict = async (params, callback) => {
    const body = prepareDistrictBody(params);
    const result = await patch(`/api/v1/device_district/${district.id}`, body);
    updatePolygons(district.id, callback);
  };

  const updatePolygons = async (id, callback) => {
    for (let i = 0, len = polygons.length; i < len; i++) {
      if (polygons[i].polygon.options) {
        const body = preparePolygonBody(polygons[i]);
        await post(`/api/v1/device_district/${id}/polygon`, body);
      }
    }
    if (polygonsToRemove) {
      for (let i = 0, len = polygonsToRemove.length; i < len; i++) {
        const {
          polygon: { id },
        } = polygonsToRemove[i];
        await del(`/api/v1/device_district/${district.id}/polygon/${id}`);
      }
    }
    callback({ action: "updated" });
  };

  const createDistrict = async (params, callback) => {
    const body = prepareDistrictBody(params);
    const result = await post(`/api/v1/device_district`, body);
    if (result.data && result.data.id) {
      createPolygon(result, callback);
    } else {
      callback({ action: "created", error: result });
    }
  };

  const createPolygon = async ({ data: { id } }, callback) => {
    const bodies = polygons.map((p) => preparePolygonBody(p));

    for (let i = 0, len = bodies.length; i < len; i++) {
      await post(`/api/v1/device_district/${id}/polygon`, bodies[i]);
    }

    if (callback) {
      callback({ action: "created", id: id });
    }
  };

  const clearDistrictAndPolygon = () => {
    setDistrict(null);
    setPolygons([]);
  };

  const preparePolygonBody = ({ positions }) => {
    return {
      data: {
        type: "device_polygon",
        attributes: {
          geom: {
            coordinates: [[...positions, positions[0]]],
            type: "Polygon",
          },
        },
      },
    };
  };

  const prepareDistrictBody = ({ name, classes, description, id }) => {
    var data = {
      data: {
        type: "device_district",
        attributes: {
          description: description,
          classes: prepareClasses(classes),
          name: name,
        },
      },
    };
    if (district && district.id) {
      data.data.id = district.id.toString();
    }
    return data;
  };

  const prepareClasses = (classes) => {
    if (classes) {
      return classes.map(({ value }) => value);
    } else if (district.classes) {
      return district.classes;
    }
    return null;
  };

  const getClasses = async () => {
    if (classes == null) {
      const devices = await get(`/api/v1/altior/device`);
      const classes = devices.data.map(({ attributes: { class: c } }) => c);
      const uniqueClasses = [...new Set(classes)];
      setClasses(uniqueClasses);
    }
  };

  const addPolygon = (newPolygon) => {
    var coords = newPolygon.getLatLngs()[0].map(({ lat, lng }) => [lat, lng]);
    setPolygons([
      ...polygons,
      {
        pathOptions: { color: createReandomColor() },
        positions: coords,
        polygon: newPolygon,
      },
    ]);
  };

  const setHighlight = (polygonHighlight) => {
    const tmp = [...polygons].map((p) => {
      console.log("pisya", p);
      if (p === polygonHighlight) {
        p.pathOptions.weight = 6;
        if (p.pathOptions.color !== "gray") {
          p.prev = p.pathOptions.color;
          p.active = true;
        }
        p.pathOptions.color = "gray";
        p.pathOptions.fillColor = p.prev;
      } else {
        p.pathOptions.weight = 3;
        p.active = false;
        if (p.prev) {
          p.pathOptions.color = p.prev;
        }
      }
      return p;
    });
    setPolygons(tmp);
  };

  const getDistrict = async (id) => {
    const result = await get(`/api/v1/device_district/${id}`);
    setLast(result.data.attributes.name);
    setDistrict(result.data.attributes);
    getPolygons(result.data.id);
  };

  const getPolygons = async (id) => {
    const { data } = await get(`/api/v1/device_district/${id}/polygon`);
    const downloadedPolygons = data.map(({ attributes }) => {
      const coords = attributes.geom.coordinates.map((c) => c);
      return {
        pathOptions: { color: createReandomColor() },
        positions: coords,
        polygon: attributes,
      };
    });
    setPolygons(downloadedPolygons);
  };

  const removePolygon = (polygonToRemove) => {
    setPolygons(polygons.filter((p) => p !== polygonToRemove));
    if (polygonToRemove.polygon.geom) {
      setPolygonsToRemove([...polygonsToRemove, polygonToRemove]);
    }
  };
  const remove = async (id) => {
    await del(`/api/v1/device_district/${id}`);
    doCall();
  };

  const getDevices = async (item) => {
    const devices = await get(
      `api/v1/altior/device?in_polygon=${JSON.stringify(
        item
      )}` /* &classes=${district.classes.join(",")} */
    );
    setDevices(devices);
  };

  const downloadDevices = async (item) => {

    const blob = await get(
      `api/v1/altior/device/export?in_polygon=${JSON.stringify(item)}`
    );

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `prova.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadDistrict = async () => {
    const geoms = polygons.map((polygon) => polygon.polygon.geom.coordinates);
    // const a = { coordinates: geoms, type: "Polygon" };
    // const params = geoms.map((coords) =>
    //   coords.map((coordinate) => [coordinate[1], coordinate[0]])
    // );

    const results = [];
    geoms.forEach((geo) => {
      let subResults = [];
      geo[0].forEach((g) => subResults.push([g[1], g[0]]));
      results.push(subResults);
    });

    const params = { coordinates: results, type: "Polygon" };

    const blob = await get(
      `api/v1/altior/device/export?in_polygon=${JSON.stringify(
        params
      )}&classes=${district.classes.join(",")}`
    );

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `prova.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const colors = ["lime", "red", "purple", "yellow", "orange"];
  const createReandomColor = () => {
    const index = Math.floor(Math.random() * 5);
    return colors[index];
  };

  let value = {
    classes,
    setClasses,
    onSubmit,
    getClasses,
    loading,
    polygons,
    addPolygon,
    removePolygon,
    setHighlight,
    getDistrict,
    district,
    clearDistrictAndPolygon,
    setUpdating,
    districts,
    setDistricts,
    searchWithPage,
    search,
    searchParams,
    remove,
    devices,
    getDevices,
    downloadDevices,
    downloadDistrict,
    drawPolygon,
    setDrawPolygon,
    currentPolygon,
    setCurrentPolygon,
  };

  return (
    <DeviceDistrictContext.Provider value={value}>
      {children}
    </DeviceDistrictContext.Provider>
  );
};

export const useDeviceDistrict = () => {
  return React.useContext(DeviceDistrictContext);
};

export const DeviceDistrictConsumer = DeviceDistrictContext.Consumer;
export default DeviceDistrictProvider;
