import { store } from "../../redux/store";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { prepareParams } from "../../utils";
import { Box, Typography } from "@mui/material";
import { alarmsActions } from "../../redux/slices/alarmsSlice";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useTransition } from "react";
import { useSearchParams } from "react-router-dom";
import { setTreeExpanded } from "../../redux/slices/condominiumSlice";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const AlarmsIndex = () => {
  const [formData, setFormData] = useState({});
  const [firstRender, setFirstRenred] = useState(true);
  const [refreshParams, setRefreshParams] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [getErrors] = store.useLazyGetErrorTypesQuery();
  const errorTypes = useSelector((state) => state?.errorTypeSlice?.errorTypes);
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();
  const alarms = useSelector((state) => state?.alarmsSlice?.alarms);
  const [getAlarms, { isLoading: alarmsLoading }] =
    store.useLazyGetAlarmsQuery();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      sortable: false,
      flex: 2,
      hide: "true",
    },
    {
      field: "device",
      headerName: t("meter"),
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => navigate(`/meters/${params.value}`)}>
            <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
          </IconButton>
        );
      },
    },
    {
      field: "error_type",
      headerName: t("error_type"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.value ? (
              <Box
                width={"70%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>
                  {
                    errorTypes?.filter((item) => item?.id === params.value)?.[0]
                      .name
                  }
                </Typography>
                {/*  <IconButton
                  onClick={() => navigate(`/errortypes/${params.value}`)}
                >
                  <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                </IconButton> */}
              </Box>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },

    {
      field: "condominium",
      headerName: t("condominium"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            width={"90%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{params?.value?.name}</Typography>
            <IconButton
              onClick={() => {
                dispatch(setTreeExpanded(params?.value?.openPath));
                navigate(params?.value?.navLink);
              }}
            >
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },

    {
      field: "status",
      headerName: t("status"),
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "inserted",
      headerName: t("inserted_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "updated",
      headerName: t("updated_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
  ];
  const rows = alarms?.map((alarm) => ({
    id: alarm?.id,
    error_type: alarm?.error_type_id,
    condominium: {
      name: alarm?.condominium_name,
      navLink: `/condominiums/${alarm?.condominium_id}/subcondominiums/${alarm?.sub_condominium_name}/apartments/${alarm?.apartment_name}`,
      openPath: [
        "condominum",
        alarm?.sub_condominium_name,
        alarm?.apartment_name,
      ],
    },
    status: alarm?.status,
    device: alarm?.device_id,
    inserted: t("dt", { val: new Date(alarm?.inserted_at) }),
    updated: t("dt", { val: new Date(alarm?.updated_at) }),
  }));
  const paramsList = {
    page: page,
    per_page: perPage,
    status: searchParams.get("status") ?? "open",
  };
  useEffect(() => {
    if (firstRender) {
      getErrors();
      getAlarms({ ...paramsList });
      setFirstRenred(false);
    }
  }, [page]);
  useEffect(() => {
    return () => {
      if (alarms) {
        dispatch(alarmsActions.cleanAlarms());
      }
    };
  }, []);
  useEffect(() => {
    if (refreshParams) {
      getAlarms({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams, page]);
  const onSubmitClick = (obj) => {
    const params = prepareParams(obj);
    navigate({
      pathname: "/alarms",
      search: params.toString(),
    });

    setPage(1);
    setRefreshParams(true);
  };
  useEffect(() => {
    console.log("alix", alarms);
  }, [alarms]);
  const statusEnum = [{ const: "open" }, { const: "closed" }];
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      status: {
        id: 5,
        type: "string",
        title: t("status"),
        oneOf: [...statusEnum],
        default: paramsList.status ?? "open",
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
  };

  return (
    <>
      <Typography
        variant="h1"
        sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
      >
        {t("alarms")}
      </Typography>
      <Form
        schema={schema}
        formData={formData}
        showErrorList={false}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        validator={validator}
        uiSchema={uiSchema}
      />
      <Box sx={{ height: 600, mt: 2 }}>
        <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns ?? []}
          /*      rowCount={errorsCount} */
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}
          paginationMode="server"
        />
      </Box>
    </>
  );
};
export default AlarmsIndex;
