import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomerEndpoint } from "./CustomerEndpointProvider";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import CustomerEndpointForm from "./CustomerEndpointFrom";
import ApiError from "../ApiError";
import IwdSnackBar from "../IwdSnackBar";
import { DataGrid } from "@mui/x-data-grid";
import { SortOrder } from "react-data-table-component";

const CustomerEndpointsComponent = () => {
  const { t } = useTranslation();
  const { loading } = useFetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1`);
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: t("name"),
      valueGetter: (params) => {
        if (params) {
          return params.row.attributes.name;
        }
        return "-";
      },
    },
    {
      field: "endpoint",
      headerName: t("endpoint"),
      valueGetter: (params) => {
        if (params) {
          return params.row.attributes.endpoint;
        }
        return "-";
      },
    },
    {
      field: "api_version",
      headerName: t("api_version"),
      valueGetter: (params) => {
        if (params) {
          return params.row.attributes.api_version;
        }
        return "-";
      },
    },
    {
      field: "description",
      headerName: t("description"),
      valueGetter: (params) => {
        if (params) {
          return params.row.attributes.description;
        }
        return "-";
      },
    },

    {
      field: "action",
      headerName: t("actions"),
      valueGetter: (params) => "-",
      renderCell: (params) => {
        return (
          <Button as={Link} to={`/custumer_endpoints/${params.id}`}>
            <VisibilityIcon />
          </Button>
        );
      },
    },
  ];
  const { search, customerEndpoints, page, perPage, searchWithPage } =
    useCustomerEndpoint();
  const [rowCountState, setRowCountState] = React.useState(0);
  useEffect(() => {
    if (!customerEndpoints) {
      search({});
    }
  }, [customerEndpoints]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      customerEndpoints !== undefined
        ? customerEndpoints.meta.count
        : prevRowCountState
    );
  }, [customerEndpoints, setRowCountState]);

  const handleSortModelChange = React.useCallback((sortModel) => {
    let obj = { sort: null, order: null };
    sortModel.forEach(({ field, sort }) => {
      obj["sort"] = field;
      obj["order"] = sort;
    });
    search(obj);
  }, []);

  if (loading || !customerEndpoints) {
    return (
      <Box>
        <Loader></Loader>
      </Box>
    );
  }
  return (
    <Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={customerEndpoints ? customerEndpoints.data : []}
          rowCount={rowCountState}
          loading={loading}
          rowsPerPageOptions={[perPage]}
          pagination
          page={page - 1}
          pageSize={perPage}
          paginationMode="server"
          onPageChange={(newPage) => {
            searchWithPage(newPage + 1, perPage);
          }}
          sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          onPageSizeChange={(newPageSize) => searchWithPage(page, newPageSize)}
          columns={columns}
          // initialState={initialState}
        />
      </div>
    </Box>
  );
};

export default CustomerEndpointsComponent;
