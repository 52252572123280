import { useDispatch, useSelector } from "react-redux";
import { hasCommonValue } from "../utils/api_params";

export const IwdAuthWrapper = ({ section, children }) => {
  const permessions = window?.COMPONENTS?.[section];

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);

  return (
    <>
      {permessions?.visibility && hasCommonValue(roles, permessions?.roles) ? (
        <>{children}</>
      ) : null}
    </>
  );
};
