import { useEffect, useState } from "react";
import { StyledDataGrid } from "./StyledDataGrid";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";
import { Button, Box, Typography } from "@mui/material";
import theme from "../theme";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { clearDexShow } from "../redux/slices/dexDataSourceSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
const DexIndex = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [getDexIndex] = store.useLazyGetDexIndexQuery();
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();
  const dex = useSelector((state) => state?.dexDataSourceSlice?.dexIndex);
  useEffect(() => {
    getDexIndex();
  }, []);
  useEffect(() => {
    dispatch(clearDexShow());
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: t("name"),
      width: 90,
      sortable: false,
      flex: 1,

      headerAlign: "left",
    },
    {
      field: "description",
      headerName: t("description"),
      width: 90,
      sortable: false,
      flex: 1,

      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              /*  sx={{ float: "right" }} */
              onClick={() => navigate(`/dex/${params.value}`)}
            >
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = dex?.map((item) => ({
    id: item?.attributes?.id,
    name: item?.attributes?.name,
    description: item?.attributes?.description,
    actions: item?.attributes?.id,
  }));

  return (
    <Box sx={{ height: "60vh", width: "99%" }}>
      <Typography
        variant="h1"
        sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
      >
        Dex
      </Typography>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns ?? []}
        /* rowCount={allMessages.length} */
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};
export default DexIndex;
