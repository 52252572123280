import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";

export const notesApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getNotes: build.query({
        query: (params) => {
          return `v1/devicenote?${prepareParams(params)}`;
        },
      }),
      getShowNote: build.query({
        query: (id) => {
          return `v1/devicenote/${id}`;
        },
      }),
      createNote: build.mutation({
        query: (attributes) => ({
          url: `v1/devicenote`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "device_note")),
        }),
      }),
      updateNote: build.mutation({
        query: ({ id, attributes }) => ({
          url: `/v1/devicenote/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, "device_note")),
        }),
      }),
      deleteNote: build.mutation({
        query: (id) => ({
          url: `/v1/devicenote/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });
