import {
  prepareBody,
  prepareParams,
  prepareBodyWithoutId,
} from "../../utils/api_params";

export const loraApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getIndexLora: build.query({
        query: (params) => {
          return `v1/altior/device?${prepareParams(params)}`;
        },
      }),
      getShowLora: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/0/instance/${id}`;
        },
      }),
      deleteLora: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/altior/digital_twin/0/instance/${id}`,
        }),
      }),
      createLora: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/altior/digital_twin/${id}/instance`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "devices")),
        }),
      }),
    }),
  });
