import { Typography } from "@mui/material";
import theme from "../theme";
const MaintenancePage = () => {
  return (
    <>
      <Typography
        sx={{ color: theme.palette.primary.main }}
        variant="h4"
        align="center"
      >
        Caro utente, Stiamo attualmente eseguendo delle operazioni di
        manutenzione per migliorare la qualità del nostro servizio. Ci scusiamo
        per l'inconveniente. Il servizio sarà nuovamente disponibile al più
        presto. Grazie per la vostra comprensione.
      </Typography>
    </>
  );
};
export default MaintenancePage;
