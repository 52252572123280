import React, { useState } from "react";
import useFetch from "use-http";
export const DeviceCommandContext = React.createContext();

const DeviceCommandProvider = ({ children }) => {

  const { post,loading, error, response} = useFetch(`${process.env.REACT_APP_SWARM_BASEURL}`);

  const sendCommand = async (param) => {
    const body = {
      data: {
        attributes: param.body,
        type: "device_commands",
      },
    };
    const result = await post(
      `/api/v1/altior/digital_twin/${param.digitalTwinId}/instance/${param.instanceId}/command/${param.commandName}`,
      body
    );

  };

  let value = {
    sendCommand,
    response,
    loading,
    error
  };

  return (
    <DeviceCommandContext.Provider value={value}>
      {children}
    </DeviceCommandContext.Provider>
  );
};

export const useDeviceCommand = () => {
  return React.useContext(DeviceCommandContext);
};

export const DeviceCommandConsumer = DeviceCommandContext.Consumer;

export default DeviceCommandProvider;
