import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid";
import validator from "@rjsf/validator-ajv6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";
import { prepareParams } from "../../utils";
import DraftsIcon from "@mui/icons-material/Drafts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DatePicker from "../form/formComponents/DatePicker";
import CheckIcon from "@mui/icons-material/Check";
import { useSearchParams } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import IconButton from "@mui/material/IconButton";
import MessagePopover from "./MessagePopover";
import { setRefreshIntervall } from "../../redux/slices/userPreferenceSlice";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import UseIntervall from "../../utils/UseInterval,";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ModalDialog from "../meters/ModalDialog";
import GetAppIcon from "@mui/icons-material/GetApp";
import Tooltip from "@mui/material/Tooltip";
import { store } from "../../redux/store";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { setMessages } from "../../redux/slices/messageSlice";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import theme from "../../theme";
import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { useInterval } from "react-use";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const Messages = () => {
  const navigate = useNavigate();
  const [readingPage, setReadingPage] = useState(1);
  const [message, setMessage] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [refreshParams, setRefreshParams] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [diagnosticPage, setDiagnosticPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const refreshIntervall = useSelector(
    (state) => state?.userPreferenceSlice?.refreshIntervall
  );
  const preferenceFirstRender = useSelector(
    (state) => state?.userPreferenceSlice?.firstRender
  );
  const [messageUpdating, setMessageUpdating] = useState();

  const [setPreference, { isSuccess }] = store.useSetPreferenceMutation();

  const [getPreference] = store.useLazyGetPreferenceQuery();
  useEffect(() => {
    if (preferenceFirstRender) {
      getPreference();
    }
  }, []);
  const [intervall, setIntervall] = useState(
    refreshIntervall ? refreshIntervall : null
  );
  const [getReadingMessages, { isFetching: readingFetch }] =
    store.useLazyGetReadingMessagesQuery();
  const [perPage, setPerPage] = useState(20);
  const [getDiagnosticMessages, { isFetching: diagnosticFetch }] =
    store.useLazyGetDiagnosticMessagesQuery();
  const preference = useSelector(
    (state) => state?.userPreferenceSlice?.preference
  );
  const preferenceUpdating = useSelector(
    (state) => state?.userPreferenceSlice?.updating
  );
  const updatingMessageOn = () => {
    setMessageUpdating(true);
  };
  const updatingMessageOff = () => {
    setMessageUpdating(false);
  };
  useEffect(() => {
    if (refreshIntervall) {
      setMessageUpdating(true);
    } else {
      setMessageUpdating(false);
    }
  }, [refreshIntervall]);
  const {
    isWSConnected,
    data: dataMessage,
    error,
    channels,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);

  const dispatch = useDispatch();
  useInterval(() => {
    if (
      readingPage == 1 &&
      diagnosticPage == 1 &&
      refreshIntervall &&
      messageUpdating
    ) {
      const params = {
        message_type: "reading",
        page: 1,
        per_page: perPage,
      };
      const searchParams = {
        message_type: "reading",
        page: 1,
        per_page: perPage,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      };
      getReadingMessages(isSearching ? searchParams : params);
    }
  }, [refreshIntervall]);
  useInterval(() => {
    if (
      diagnosticPage == 1 &&
      refreshIntervall &&
      readingPage == 1 &&
      messageUpdating
    ) {
      const params = {
        message_type: "diagnostic",
        page: 1,
        per_page: perPage,
      };
      const searchParams = {
        message_type: "diagnostic",
        page: 1,
        per_page: perPage,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      };
      getDiagnosticMessages(isSearching ? searchParams : params);
    }
  }, [refreshIntervall]);
  useEffect(() => {
    if (preferenceUpdating) {
      setPreference(preference)
        .then(() => getPreference())
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  }, [preferenceUpdating]);
  useEffect(() => {
    const params = {
      message_type: "reading",
      page: readingPage,
      per_page: perPage,
    };
    const searchParams = {
      message_type: "reading",
      page: readingPage,
      per_page: perPage,
      from: formData?.insertion_date?.from,
      to: formData?.insertion_date?.to,
    };
    getReadingMessages(isSearching ? searchParams : params);
  }, [readingPage]);
  useEffect(() => {
    const params = {
      message_type: "diagnostic",
      page: diagnosticPage,
      per_page: perPage,
    };
    const searchParams = {
      message_type: "diagnostic",
      page: diagnosticPage,
      per_page: perPage,
      from: formData?.insertion_date?.from,
      to: formData?.insertion_date?.to,
    };
    getDiagnosticMessages(isSearching ? searchParams : params);
  }, [diagnosticPage]);

  useEffect(() => {
    console.log("pageReading", readingPage);
  }, [readingPage]);
  useEffect(() => {
    console.log("pageDiagnostic", diagnosticPage);
  }, [diagnosticPage]);

  const readingMessages = useSelector(
    (state) => state?.messageSlice?.readingMessages
  );
  const diagnosticMessages = useSelector(
    (state) => state?.messageSlice?.diagnosticMessages
  );
  const readingCount = useSelector(
    (state) => state?.messageSlice?.readingCount
  );
  const diagnosticCount = useSelector(
    (state) => state?.messageSlice?.diagnosticCount
  );
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    if (newValue !== 2) {
      setValue(newValue);
    }
  };
  const readingColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "lvl",
      headerName: t("level"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "lastReadingValue",
      headerName: t("last_reading_value"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "meterStatusValue",
      headerName: t("meter_status_value"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params.value === "normal") {
          return <CheckIcon sx={{ color: "green", ml: 6 }} />;
        } else {
          return <ErrorIcon sx={{ color: theme.palette.error.light, ml: 6 }} />;
        }
      },
    },

    {
      field: "readTime",
      headerName: t("read_time"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value.map((item) => (
            <Chip
              sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton sx={{ mt: 0.5, ml: 1 }}>
              <Link to={`/meters/${params?.value.device_id}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
            {/*  <MessagePopover
              message={params?.value.values.values.value}
            ></MessagePopover> */}
            <IconButton
              onClick={() => onMailClick(params?.value.values.values.value)}
            >
              <DraftsIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const diagnosticColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "lvl",
      headerName: t("level"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "batteryLvlValue",
      headerName: t("battery_lvl_value"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "meterStatusValue",
      headerName: t("meter_status_value"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params.value === "normal") {
          return <CheckIcon sx={{ color: "green", ml: 6 }} />;
        } else {
          return <ErrorIcon sx={{ color: theme.palette.error.light, ml: 6 }} />;
        }
      },
    },

    {
      field: "readTime",
      headerName: t("read_time"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value.map((item) => (
            <Chip
              sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton sx={{ mt: 0.5, ml: 1 }}>
              <Link to={`/meters/${params?.value.device_id}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
            <IconButton
              onClick={() => onMailClick(params?.value.values.values.value)}
            >
              <DraftsIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const readingRows = readingMessages?.map((item, i) => ({
    id: i,

    lastReadingValue: `${
      item?.values?.values?.value?.last_reading?.value ?? "-"
    } ${item.values?.values.value?.last_reading?.unit ?? "-"}`,

    meterStatusValue: item?.values?.values?.value?.meter_status?.value ?? "--",

    readTime: t("dt", { val: new Date(item.values.read_time) }) ?? "--",
    alarms: item?.values?.values?.value?.alarms ?? "--",
    actions: item,
  }));
  const diagnosticRows = diagnosticMessages?.map((item, i) => ({
    id: i,

    batteryLvlValue: `${
      item?.values?.values?.value?.battery_level?.value ?? "-"
    } ${item?.values?.values?.value?.battery_level?.unit ?? "-"}`,

    meterStatusValue: item?.values?.values?.value?.meter_status?.value ?? "--",

    readTime: t("dt", { val: new Date(item.values.read_time) }),
    alarms: item?.values?.values?.value?.alarms ?? "--",
    actions: item,
  }));
  const handleRefreshInterval = (event) => {
    dispatch(setRefreshIntervall(event.target.value));
  };
  /*   const onSubmitClick = (val) => {
    dispatch(setRefreshIntervall(val));
  }; */
  const onGetClick = () => {
    setReadingPage(1);
    setDiagnosticPage(1);
    const readingParams = {
      message_type: "reading",
      page: 1,
      perPage: perPage,
    };
    const diagnosticParams = {
      message_type: "diagnostic",
      page: 1,
      perPage: perPage,
    };
    getReadingMessages(readingParams);
    getDiagnosticMessages(diagnosticParams);
    setIsSearching(false);
    setFormData((prev) => ({
      ...prev,
      insertion_date: "",
    }));
    setRefreshData(true);
  };
  const openMessageModal = () => {
    setMessageModal(true);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };
  const onMailClick = (msg) => {
    setMessage(msg);
    openMessageModal();
  };

  const schema = {
    titile: "Searc event",
    type: "object",
    properties: {
      insertion_date: {
        id: 1,
        title: t("inserted_at"),
        type: "object",
        messageDate: true,
        refreshData: refreshData,
        setRefreshData: setRefreshData,
        properties: {
          from: {
            type: "string",
            /*  default: paramsList.from ? paramsList.from : "", */
          },
          to: {
            type: "string",
            /*  default: paramsList.to ? paramsList.to : "", */
          },
        },
      },
      /*       in_error: {
        id: 7,
        type: "boolean",
        title: t("in_error"),
      }, */
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
    insertion_date: {
      "ui:field": "datepicker",
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
  };
  const fields = {
    datepicker: DatePicker,
  };

  /*   const onSubmitClick = (obj) => {
    const params = prepareParams(obj);
    getReadingMessages(prepareParams(params));
    getDiagnosticMessages(prepareParams(params));
  }; */
  const onSearchClick = () => {
    const readingParams = {
      message_type: "reading",
      page: 1,
      per_page: perPage,
      from: formData.insertion_date.from,
      to: formData.insertion_date.to,
      /*    in_error: formData.in_error, */
    };
    const diagnosticParams = {
      message_type: "diagnostic",
      page: 1,
      per_page: perPage,
      from: formData.insertion_date.from,
      to: formData.insertion_date.to,
      /*  in_error: formData.in_error, */
    };
    setReadingPage(1);
    setDiagnosticPage(1);
    getReadingMessages(readingParams);
    getDiagnosticMessages(diagnosticParams);
    setIsSearching(true);
  };
  const customValidate = (formData, errors) => {
    if (formData.insertion_date.from && formData.insertion_date.to) {
      if (
        formData.insertion_date.from !== "" &&
        formData.insertion_date.to !== "" &&
        formData.insertion_date.from >= formData.insertion_date.to
      ) {
        errors.insertion_date.to.addError(t("date_error"));
      }
    }

    return errors;
  };

  return (
    <>
      <ModalDialog
        open={messageModal}
        close={() => closeMessageModal()}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <Box>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          customValidate={customValidate}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={onSearchClick}
          validator={validator}
          uiSchema={uiSchema}
        />
      </Box>
      {
        <Box sx={{ mt: 6 }}>
          <Box display={"flex"} justifyContent="space-between">
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={
                  <Box display="flex">
                    {t("diagnostic_event")}{" "}
                    <TroubleshootIcon sx={{ pb: 1, ml: 1 }} />
                  </Box>
                }
              />
              <Tab
                label={
                  <Box display="flex">
                    {t("standart_event")} <MenuBookIcon sx={{ pb: 1, ml: 1 }} />
                  </Box>
                }
              />
            </Tabs>
            {refreshIntervall && (
              <Box>
                <Box
                  sx={{ width: 400, mr: 3 }}
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 2, height: 1 }}
                    onClick={onGetClick}
                  >
                    <Tooltip title={"Get new messages"}>
                      <SyncIcon />
                    </Tooltip>
                  </Button>
                  {messageUpdating && (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ mr: 2, height: 1 }}
                      disabled={readingPage > 1 || diagnosticPage > 1}
                      onClick={updatingMessageOff}
                    >
                      <Tooltip title={"Stop updating"}>
                        <PauseIcon />
                      </Tooltip>
                    </Button>
                  )}
                  {!messageUpdating && (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ mr: 2, height: 1 }}
                      disabled={readingPage > 1 || diagnosticPage > 1}
                      onClick={updatingMessageOn}
                    >
                      <Tooltip title={"Start updating"}>
                        <PlayArrowIcon />
                      </Tooltip>
                    </Button>
                  )}
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      shrink={!refreshIntervall ? false : true}
                      size="small"
                    >
                      Refresh interval
                    </InputLabel>
                    <Select
                      sx={{ mr: 1, height: 1 }}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={refreshIntervall}
                      label="Refresh interval"
                      onChange={handleRefreshInterval}
                    >
                      <MenuItem value={900000}>15 minutes</MenuItem>
                      <MenuItem value={1800000}>30 minutes</MenuItem>
                      <MenuItem value={3600000}>60 minutes</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            {!refreshIntervall && (
              <Box>
                <Box
                  sx={{ width: 400, mr: 3 }}
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 2, height: 1 }}
                    onClick={onGetClick}
                  >
                    <Tooltip title={"Get new messages"}>
                      <SyncIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 2, height: 1 }}
                    disabled
                    onClick={updatingMessageOn}
                  >
                    <Tooltip title={"Start updating"}>
                      <PlayArrowIcon />
                    </Tooltip>
                  </Button>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size="small">
                      Refresh interval
                    </InputLabel>
                    <Select
                      sx={{ mr: 1 }}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Refresh interval"
                      onChange={handleRefreshInterval}
                    >
                      <MenuItem value={5000}>5 sec</MenuItem>
                      <MenuItem value={10000}>10 sec</MenuItem>
                      <MenuItem value={20000}>20 sec</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>

          <TabPanel value={value} index={0}>
            {diagnosticRows && (
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  disableColumnMenu
                  rows={diagnosticRows}
                  columns={diagnosticColumns}
                  pageSize={perPage}
                  rowCount={diagnosticCount}
                  rowsPerPageOptions={[perPage]}
                  disableSelectionOnClick
                  onPageChange={(pageNumber) => {
                    !diagnosticFetch && setDiagnosticPage(pageNumber + 1);
                  }}
                  page={diagnosticPage - 1}
                  paginationMode="server"
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {readingRows && (
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  disableColumnMenu
                  rows={readingRows}
                  columns={readingColumns}
                  pageSize={perPage}
                  rowsPerPageOptions={[perPage]}
                  rowCount={readingCount}
                  disableSelectionOnClick
                  onPageChange={(pageNumber) => {
                    !readingFetch && setReadingPage(pageNumber + 1);
                  }}
                  page={readingPage - 1}
                  paginationMode="server"
                />
              </Box>
            )}
          </TabPanel>
        </Box>
      }
    </>
  );
};

export default Messages;
