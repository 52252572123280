// import { api } from "./api";
// console.log("api from slice",api)
export const authApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      signout: build.mutation({
        query: () => ({
          url: "v1/logout",
          method: "DELETE",
        }),
      }),
    }),
  });

// export const { useSignoutMutation } = authApi;
