import React, { useEffect, useState } from "react";
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from "react-awesome-query-builder";

// For MUI 4/5 widgets only:
// import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import { store } from "../redux/store";
import useFetch from "use-http";
import DexTable from "../components/dex/DexTable";
import Loader from "../components/Loader";
import BasicSelect from "../components/dex/DexDataSource";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useExecuteSavedDexMutation } from "../redux/slices/dexApi";
import { useSelector, useDispatch } from "react-redux";
import { setDexAttributes } from "../redux/slices/dexDataSourceSlice";
import { useParams } from "react-router-dom";
import theme from "../theme";
import { setMessage } from "../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { queryOff } from "../redux/slices/dexDataSourceSlice";
import { useCreateDexMutation } from "../redux/slices/dexApi";
import { TextareaAutosize } from "@mui/base";
import { useUpdateDexMutation } from "../redux/slices/dexApi";
import { usePostDexMutation } from "../redux/slices/dexApi";
import ExportDexButton from "../components/form/formComponents/ExportDexButton";
import ModalDialog from "../components/meters/ModalDialog";
import { useLazyGetDexShowQuery } from "../redux/slices/dexApi";
const InitialConfig = MuiConfig;

const operators = {
  ...InitialConfig.operators,
  // examples of  overriding

  //   between: {
  //     ...InitialConfig.operators.between,
  //     textSeparators: ["from", "to"],
  //   },
  sum: {
    label: "Sum",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `SUM(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },

  count_distinct: {
    label: "Count Distinct",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(distinct(${field}))`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  count: {
    label: "Count",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  average: {
    label: "Average",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `average(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result["AVG"] = [{ var: field.var }];
      return result;
    },
  },
  like: {
    label: "LIKE",
    reversedOp: null,
    labelForFormat: "LIKE*",
    cardinality: 1,
    // formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
    //   `${field} ${opDef.labelForFormat} ${value}`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }, value];
      return result;
    },
  },

  //   cane: {
  //     label: "sum",
  //     isNotOp: true,
  //     reversedOp: "not_equal",
  //     labelForFormat: "sum",
  //     cardinality: 1,
  //     formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
  //       `${opDef.labelForFormat}(${field})`,
  //     sqlFormatOp: (field, op, value) => `SUM(${field})`,
  //     mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
  //   },
};

const viewFields = {
  device_id: {
    label: "DeviceId",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  parent_device_id: {
    label: "Parent Device Id",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  in_error: {
    label: "In Error",
    type: "boolean",

    valueSources: ["value"],
  },
  inserted_at: {
    label: "Date system entry",
    type: "datetime",

    valueSources: ["value"],
  },
  message_date: {
    label: "Reading Date",
    type: "datetime",
    valueSources: ["value"],
  },
  unit: {
    label: "Unit",
    type: "string",
    valueSources: ["value"],
  },

  volume: {
    label: "Volume",
    type: "number",
    valueSources: ["value"],
    operators: [
      "sum",
      "count",
      "average",
      "not_equal",
      "equal",
      "less",
      "less_or_equal",
      "greater",
      "greater_or_equal",
    ],
  },
};

const config = {
  ...InitialConfig,
  operators: operators,

  showNot: false,
  fields: {},
};

const queryValue = { id: QbUtils.uuid(), type: "group" };

const JsonLogicQueryBuilder = ({ jsonLogic, renderBuilder }) => {
  const prefillTree = QbUtils.loadFromJsonLogic(jsonLogic, config);

  return (
    <Query
      {...config}
      value={prefillTree}
      onChange={(immutableTree, config) => {
        console.log("Query changed:", immutableTree, config);
      }}
      renderBuilder={renderBuilder}
    />
  );
};

const DexShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [saveDexModal, setModal] = useState(false);
  const [query, setQuery] = useState(null);
  const [updateDex, { isSuccess: updateDexSuccess }] =
    store.useUpdateDexMutation();
  const [firstRender, setFirstRender] = useState(true);
  const [reload, setReload] = useState(null);
  const [executeSavedDex] = store.useExecuteSavedDexMutation();
  const [page, setPage] = useState(1);
  const [perPage] = useState(15);
  const [name, setName] = useState();
  const [description, setDescription] = useState("");
  const [postDex] = store.usePostDexMutation();
  const [getDexShow] = store.useLazyGetDexShowQuery();
  const [jsonTree, setJsonTree] = useState(null);
  const dexShow = useSelector((state) => state?.dexDataSourceSlice?.dexShow);
  const dexAttributes = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes
  );
  const precompiledQuery = useSelector(
    (state) => state.dexDataSourceSlice.precompiledQuery
  );
  const dataSources = useSelector(
    (state) => state.dexDataSourceSlice.dataSources
  );

  /*   const page = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.page
  );
  const perPage = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.per_page
  ); */
  const postOrUpdate = useSelector(
    (state) => state?.dexDataSourceSlice?.postOrUpdate
  );
  const dataSource = useSelector(
    (state) => state.dexDataSourceSlice.dataSource
  );
  const nameHandle = (e) => {
    setName(e.target.value);
  };
  const descHandle = (e) => {
    setDescription(e.target.value);
  };
  const { t } = useTranslation();
  const { post, loading, error, del, patch } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  useEffect(() => {
    if (dataSource) {
      const newConfig = { ...config, fields: dataSource.fields };
      setState({ ...state, config: newConfig });
    }
  }, [dataSource]);
  // const [dataSource, setDataSource] = useState();
  const [state, setState] = useState(null);
  /*   const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  }); */
  const [ready, setReady] = useState(false);
  useEffect(() => {
    console.log("statseeee", dexShow);
  }, [dexShow]);
  useEffect(() => {
    setFirstRender(false);
  }, []);
  useEffect(() => {
    if (updateDexSuccess) {
      closeModal();
      dispatch(setMessage(t("update_success")));
      getDexShow(id);
    }
  }, [updateDexSuccess]);
  useEffect(() => {
    if (dexShow?.logic) {
      setTimeout(() => {
        console.log("statseseeet", dataSource?.fields);

        const newConfig = { ...config, fields: dataSource?.fields };
        const immutableTree = QbUtils.loadFromJsonLogic(
          dexShow?.logic,
          newConfig
        );
        const test1 = QbUtils.loadTree(immutableTree);
        const test2 = QbUtils.getTree(test1);
        /*  setReload(test2); */
        console.log("perdej", test2);

        setState({
          tree: immutableTree,
          config: newConfig,
        });
      }, [500]);
    }
  }, [dexShow, dataSource, reload]);
  const testFn = () => {
    const newConfig = { ...config, fields: dataSource?.fields };
    const immutableTree = QbUtils.loadFromJsonLogic(dexShow?.logic, newConfig);

    setState({
      tree: immutableTree,
      config: newConfig,
    });
  };
  useEffect(() => {
    if (state) {
      setReady(true);
    }
  }, [state]);

  useEffect(() => {
    if (state?.tree) {
      console.log("sttree", QbUtils.getTree(state?.tree));
    }
  }, [state?.tree]);

  const executeDex = async () => {
    console.log("ahussuuu", QbUtils.jsonLogicFormat(state.tree, config));

    const attributes = {
      logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
      data_source: dataSource.name,
      page: 1,
      per_page: 10,
    };

    postDex(attributes);
    /* } */
  };
  useEffect(() => {
    if (!firstRender) {
      if (!precompiledQuery) {
        const attributes = {
          logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
          data_source: dataSource.name,
          page: page,
          per_page: perPage,
        };
        postDex(attributes);
      } else {
        const data = {
          id: id,
          page: page,
          perPage: perPage,
        };
        executeSavedDex({
          id: id,
          attributes: data,
        });
      }
    }
  }, [page]);
  useEffect(() => {
    if (dexShow) {
      setName(dexShow?.name);
      setDescription(dexShow.description);
    }
  }, [dexShow]);
  /*   useEffect(() => {
    if (postOrUpdate && !precompiledQuery && dexAttributes) {
      postDex(dexAttributes);
    }
  }, [postOrUpdate, precompiledQuery, dexAttributes]); */
  useEffect(() => {
    if (id) {
      getDexShow(id);
    }
  }, [id]);

  useEffect(() => {
    console.log("izdec", state);
  }, [state]);

  const onChange = (immutableTree, config) => {
    console.log("jsonTree", immutableTree);
    dispatch(queryOff());
    setState({ tree: immutableTree, config: config });
    setJsonTree(QbUtils.getTree(immutableTree));
  };
  /*   useEffect(() => {
    if (dexShow && dexShow.logic) {
      // Передайте объект logic из dexShow в state.tree
      setState({ tree: dexShow.logic, config: config });
    }
  }, [dexShow]); */
  const renderBuilder = (props) => {
    console.log("Props in renderBuilder:", props);

    if (state) {
      console.log("shkvarka", state);
      return (
        <div className="query-builder-container" style={{ padding: "10px" }}>
          <div className="query-builder qb-lite">
            <Builder {...props} />
          </div>
        </div>
      );
    }
  };

  const onSaveClick = () => {
    const attributes = {
      logic:
        QbUtils.jsonLogicFormat(state.tree, config).logic ?? dexShow?.logic,
      data_source: dataSource?.name ?? dexShow?.table_name,
      page: page,
      per_page: perPage,
      name: name ?? dexShow?.name,
      description: description ?? dexShow?.description,
    };
    updateDex({ id: id, attributes: attributes });
  };
  return (
    <>
      {ready && (
        <Box>
          <Typography
            sx={{ color: theme?.palette?.primary?.main }}
            variant="h3"
          >{`Dex: ${dexShow?.name}`}</Typography>
          <Typography
            sx={{ color: theme?.palette?.primary?.main, mt: 2 }}
            variant="h5"
          >{`Description: ${dexShow?.description}`}</Typography>
          <Button onClick={testFn}>loool</Button>
          <ModalDialog
            open={saveDexModal}
            title={t("save_dex")}
            close={closeModal}
            cancell={false}
          >
            <Box sx={{ width: 500, mt: 2 }}>
              <TextField
                onChange={(e) => nameHandle(e)}
                value={name}
                label={t("name")}
                fullWidth
              />
              <TextField
                sx={{ mt: 2 }}
                rows={5}
                value={description}
                multiline
                onChange={(e) => descHandle(e)}
                label={t("description")}
                fullWidth
              />
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
                sx={{ mt: 2 }}
              >
                <Button onClick={closeModal} variant="contained">
                  {t("close")}
                </Button>
                <Button onClick={onSaveClick} variant="contained">
                  {t("save")}
                </Button>
              </Box>
            </Box>
          </ModalDialog>
          <h3 style={{ marginBottom: 20 }}> Dex - Data explorer</h3>
          {loading ? <Loader /> : <></>}
          <Box>
            <BasicSelect showMode={true} />
          </Box>
          <Box>
            <Query
              {...state.config}
              value={state.tree}
              onChange={onChange}
              renderBuilder={renderBuilder}
            />
            {/* <JsonLogicQueryBuilder
              jsonLogic={dexShow?.logic}
              renderBuilder={renderBuilder}
            /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Button
              onClick={executeDex}
              disabled={
                !dataSource || !jsonTree?.children1?.[0]?.properties?.field
              }
              endIcon={<SettingsSuggestIcon />}
            >
              {t("execute")}
            </Button>
            <Box display="flex">
              <Button variant="contained" sx={{ mr: 1 }} onClick={openModal}>
                {t("update_dex")}
              </Button>
              <ExportDexButton />
            </Box>
            {/*  <Box sx={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label={t("data_explorer_name")}
            variant="outlined"
            disabled={!dataSource}
          />
          <Button disabled={!dataSource}>{t("save")}</Button>
        </Box> */}
          </Box>
          <DexTable page={page} setPage={setPage} perPage={perPage} />
        </Box>
      )}
    </>
  );
};

export default DexShow;
