import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import { debug } from "util";

export default function HeatMeterSelect({
  obj,
  setKc,
  kc,
  meterName,
  update,
  setFc,
  selectedMeter,
}) {
  const { t } = useTranslation();
  const [firstRender, setFirstRender] = useState(true);
  const [firstInit, setFirstInit] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState();
  const meterClass = useSelector((state) => state?.meterSlice?.selectedMeter)
    ?.attributes?.digital_twin_fields?.manufacturer;
  const [age, setAge] = React.useState("");
  const types = Object.entries(obj).filter((item) =>
    item[0].includes(meterClass)
  );

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setKc(selectedValue);

    const selectedType = types.find((item) => item[1] === selectedValue);
    if (selectedType) {
      setSelectedLabel(selectedType[0]);
    }
  };

  React.useEffect(() => {
    if (selectedLabel == "kcEFE") {
      setFc(2.288);
    }
    if (selectedLabel == "kcEFEse") {
      setFc(1.097);
    }
  }, [selectedLabel]);

  React.useEffect(() => {
    if (selectedMeter.length > 0) {
      setFc(2.288);
    }
  }, [selectedMeter]);

  React.useEffect(() => {
    Object.keys(types)?.forEach((key) => {
      console.log("tipososla", types[key]);
    });
  }, [types]);
  React.useEffect(() => {
    console.log("examt", types);
    console.log("examm", meterClass);
    console.log("examo", obj);
    console.log("selectedMeter", selectedMeter);
  }, [types, obj, meterClass, selectedMeter]);
  React.useEffect(() => {
    console.log("sukapisyahui", kc);
  }, [kc]);
  React.useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      setFirstInit(true);
      setKc(types?.[0]?.[1]);
    }
  }, [meterName]);

  React.useEffect(() => {
    if ((types.length > 0 && firstRender && !update) || !firstInit) {
      setKc(types?.[0]?.[1]);
      setFirstRender(false);
      setFc(2.288);
    }
    if (update) {
      setFirstRender(false);
    }
  }, [types]);

  return (
    <Box sx={{ width: "95%" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Tipo installazione
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={kc}
          label="Tipo instalazione"
          onChange={handleChange}
        >
          {types?.map((item) => (
            <MenuItem key={item?.[1]} value={item?.[1]}>
              {t(item?.[0])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
