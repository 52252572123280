import { TextField, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { useState } from "react";
import theme from "../../theme";
import { Box } from "@mui/system";

const CreateLora = () => {
  const [createLora] = store.useCreateLoraMutation();
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onSubmitClick = () => {
    const attributes = {
      fields: {
        name: name,
        digital_twin_id: window.LORA.toString(),
        class: "lorawan_network_service",
        meter_type: "lora",
        serial_number: name,

        coordinates: {
          coordinates: [0, 0],
          type: "Point",
        },
      },
    };
    createLora({ attributes: attributes, id: window.LORA });
  };
  return (
    <>
      <Typography
        variant="h1"
        sx={{ color: theme?.palette?.primary.main, mb: 4 }}
      >
        {t("create_lora")}
      </Typography>
      <Box>
        <TextField
          value={name}
          onChange={(e) => onNameChange(e)}
          label={t("name")}
          fullWidth
        />
        <Button
          onClick={onSubmitClick}
          variant="contained"
          sx={{ mt: 2, float: "right" }}
        >
          {t("create")}
        </Button>
      </Box>
    </>
  );
};
export default CreateLora;
