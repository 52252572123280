import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Socket } from "phoenix";
import { useAuth } from "./AuthProvider";

const PhoenixSocketContext = createContext({ socket: null });

const PhoenixSocketProvider = ({ children }) => {
  const { currentToken } = useAuth();
  var connectionTries = 0;
  const reconnectRule = (tries) => {
    connectionTries = tries;
    if (tries < 20) {
      return (
        [10, 50, 100, 150, 200, 250, 500, 1000, 2000, 3000, 4000][tries - 1] ||
        5000
      );
    } else {
      return 600000; //10 min
    }
  };
  var phoenixSocket = new Socket(`${window.WS_BASE_URL}/socket`, {
    params: { token: currentToken },
    reconnectAfterMs: reconnectRule,
  });
  phoenixSocket.onOpen(() => {
    setConnected(true);
    setSocketError(null);
  });
  phoenixSocket.onClose(() => {
    setConnected(false);
    setSocketError(null);
  });
  phoenixSocket.onError((error) => {
    setConnected(false);
    setSocketError(error);
  });
  const [socket] = useState(phoenixSocket);
  const [connected, setConnected] = useState(false);
  const [socketError, setSocketError] = useState(false);
  useEffect(() => {
    socket.connect();
  });
  return (
    <PhoenixSocketContext.Provider value={{ socket, connected, socketError }}>
      {children}
    </PhoenixSocketContext.Provider>
  );
};

PhoenixSocketProvider.propTypes = {
  children: PropTypes.node,
};

export { PhoenixSocketContext, PhoenixSocketProvider };
