export const errorList = {
  "Maddalena Gradus - EFE:19:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],
  "Meters Tx1.1 - BMT:19:07": [
    "overflow_flux",
    "sw_error",
    "leakage",
    "low_battery",
    "hw_tamper",
  ],
  "Sontex 739 - SON:27:13": ["generic_alarm", "temp_sensor1_short_circuit"],
  "Maddalena MicroClimaEvo - MAD:00:13": [
    "low_battery",
    "reset",
    "damaged_electronics",
    "error_flow_sensor",
    "flow_sensor_error",
    "temp_sensor2_short_circuit",
    "temp_sensor2_broken_cable",
    "temp_sensor1_short_circuit",
    "temp_sensor1_broken_cable",
  ],
  "Maddalena Gradus - EFE:37:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "lifetime_more_than_ten_years",
    "not_installed",
  ],
  "Maddalena Gradus - EFE:17:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "lifetime_more_than_ten_years",
    "not_installed",
  ],
  "Maddalena Gradus - EFE:41:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "lifetime_more_than_ten_years",
    "not_installed",
  ],
  "Maddalena Idrico FAW - EFE:03:07": [
    "hw_error",
    "magnetic_tamper",
    "block_detected",
    "reset",
    "sw_error",
    "module_removed",
    "leakage",
    "reverse_flux",
  ],
  "Maddalena Arrow MAD - MAD:129:07": [
    "leakage",
    "reverse_flux",
    "hw_error",
    "magnetic_tamper",
    "hw_tamper",
  ],
  "Digital Twin : Sontex 868 - SON:22:08": [
    "hw_tamper",
    "measure_error",
    "key_too_long_closed",
    "overflow_temperature",
    "unknown_field_C",
    "unknown_field_CI",
    "sw_tamper",
  ],
  "Maddalena Radio Evo - MAD:82:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "Maddalena ElectoSJ - MAD:01:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "R.B.M HCA - RBM:85:08": [
    "hw_error",
    "sabotage",
    "low_battery",
    "hf_error",
    "reset",
  ],
  "Maddalena Radio Evo - MAD:80:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "Englemann SensoStar S3 - EFE:00:13": [
    "low_battery",
    "reset",
    "damaged_electronics",
    "flow_sensor_error",
    "temp_sensor2_short_circuit",
    "temp_sensor2_broken_cable",
    "temp_sensor1_short_circuit",
    "temp_sensor1_broken_cable",
  ],
  "Axioma Qualsonic W1 AXI - AXI:16:07": [
    "burst",
    "reverse_lux",
    "underflow_temperature",
    "leakage",
    "temperory_error",
    "permanent_error",
    "low_battery",
    "abnormal_condition",
  ],
  "Engelmann SensoStar S2 - EFE:00:13": [
    "checksum_error",
    "memory_error",
    "reset",
    "scanning_coil_fault",
    "reference_sensor_fault",
    "return_flow_sensor_fault",
    "forward_flow_sensor_fault",
  ],
};
