/* import { api } from "./api"; */

export const dashboardApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      updateWidgetsId: build.mutation({
        query: ({ body, id }) => ({
          url: `v1/plugin/dashboards/${id}`,
          method: "PUT",
          body: JSON.stringify(body),
        }),
      }),
      createNewDashboard: build.mutation({
        query: (body) => ({
          url: "v1/plugin/dashboards",
          method: "POST",
          body: JSON.stringify(body),
        }),
      }),
      deleteDashboard: build.mutation({
        query: ({ id }) => ({
          url: `v1/plugin/dashboards/${id}`,
          method: "DELETE",
        }),
      }),
      getDashboardList: build.query({
        query: () => {
          return `v1/plugin/dashboards?page=1`;
        },
      }),
      getDashboardShow: build.query({
        query: (id) => {
          return `v1/plugin/dashboards/${id}`;
        },
      }),
    }),
  });

/* export const {
  useUpdateWidgetsIdMutation,
  useCreateNewDashboardMutation,
  useDeleteDashboardMutation,
  useLazyGetDashboardListQuery,
  useLazyGetDashboardShowQuery,
} = dashboardApi; */
