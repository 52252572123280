import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  users: null,
  userShow: null,
  count: null,
  userRoles: null,
  grantId: null,
};
const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    unsetUserRoles(state) {
      state.userRoles = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.userEndpoints.getUsers.matchFulfilled,
      (state, action) => {
        state.users = action.payload.data;
        state.count = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.userEndpoints.getUserShow.matchFulfilled,
      (state, action) => {
        state.userShow = action.payload.data;
      }
    );
    builder.addMatcher(
      store.userEndpoints.getGrant.matchFulfilled,
      (state, action) => {
        state.userRoles = action.payload.data[0].attributes.role_keys;
        state.grantId = action.payload.data[0].id;
      }
    );
  },
});
export const { unsetUserRoles } = userSlice.actions;
export default userSlice.reducer;
