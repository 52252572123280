import { prepareParams } from "../../utils";
import { prepareBodyWithoutId } from "../../utils/api_params";
import { prepareBody } from "../../utils/api_params";

const type = "plugin";
export const widgetsApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getWidgets: build.query({
        query: (params) => {
          return `v1/plugin/widgets?${prepareParams(params)}`;
        },
      }),
      getShowWidgets: build.query({
        query: (obj) => {
          var id = obj;
          if (typeof obj === "object") {
            id = obj.id;
          }

          return `v1/plugin/widgets/${id}`;
        },
      }),
      createWidget: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v1/plugin/widgets",
          body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
        }),
      }),
      updateWidget: build.mutation({
        query: ({ attributes, id }) => ({
          url: `v1/plugin/widgets/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id.toString(), type)),
        }),
      }),
      deleteWidget: build.mutation({
        query: (id) => ({
          url: `v1/plugin/widgets/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });

/* export const {
  useLazyGetWidgetsQuery,
  useLazyGetShowWidgetsQuery,
  useCreateWidgetMutation,
  useUpdateWidgetMutation,
  useDeleteWidgetMutation,
} = widgetsApi; */
