import * as React from "react";
import Box from "@mui/material/Box";
import { store } from "../../../redux/store";
import Chip from "@mui/material/Chip";
import { StyledDataGrid } from "../../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IwdAuthWrapper } from "../../../utils/IwdAuthWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  setMeterTrashId,
  setUpdateAfterDelete,
} from "../../../redux/slices/meterSlice";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import { useState, useEffect } from "react";
import { nameTransform } from "../../../utils/utilsFunctions";
import { useSelector, useDispatch } from "react-redux";
import DeleteConfirmModal from "../../../utils/DeleteConfirmModal";
const Table = ({
  data,
  response,
  perPage,
  page,
  setPage,
  hideAccordion,
  loading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const meterTrashId = useSelector((state) => state?.meterSlice?.trashId);
  const [deleteMeter, { isSuccess: deleteSuccess }] =
    store.useDeleteMeterMutation();
  const { t } = useTranslation();
  const [trashId, setTrashId] = useState(null);
  const [modal, setModal] = useState(false);
  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);
  const onTrashClick = (id) => {
    setTrashId(id);
    setModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,

      flex: 2,
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: t("street_address"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    /*    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
 */
    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper
              children={
                <IconButton
                  onClick={() => onTrashClick(params?.value)}
                  sx={{ float: "right" }}
                >
                  <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
                </IconButton>
              }
              section={"delete_meter"}
            />
            <Link to={`/meters/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    /*    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags.map((item) => item)
      : "--", */
    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    metrological_serial: item?.attributes?.fields?.serial ?? "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    /*  pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--", */
    /*     diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--", */
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item.id ? item.id : "",
  }));
  useEffect(() => {
    if (meterTrashId) {
      deleteMeter(meterTrashId);
    }
  }, [meterTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      dispatch(setMeterTrashId(null));
      dispatch(setUpdateAfterDelete(true));
    }
  }, [deleteSuccess]);
  return (
    <Box sx={{ height: hideAccordion ? "39vh" : "60vh", width: "97.7%" }}>
      <DeleteConfirmModal
        trashModal={modal}
        setTrashModal={setModal}
        text={t("meter_delete_text")}
        title={t("meter_delete_title")}
        deleteFunc={() => setMeterTrashId(trashId)}
      />
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={response?.data?.meta?.count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          !response.isFetching && setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default Table;
