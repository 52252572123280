/* import { api } from "./api"; */

export const aegisApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getAllroles: build.query({
        query: () => {
          return `v1/role`;
        },
      }),
    }),
  });
/* export const { useLazyGetAllrolesQuery } = aegisApi; */
