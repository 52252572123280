import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
const PasswordComponent = (props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    console.log("props", props);
  }, [props]);
  return (
    <FormControl>
      <InputLabel
        style={{ color: props?.rawErrors?.length > 0 && "#d32f2f" }}
        htmlFor="outlined-adornment-password"
      >
        {t("password")}
      </InputLabel>
      <OutlinedInput
        style={{ width: "100%" }}
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={props.formData && props.formData}
        error={props?.rawErrors?.length > 0}
        onChange={(e) => props.onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={t("password")}
      />
    </FormControl>
  );
};
export default PasswordComponent;
