import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";

const grantType = "grant";
const userType = "user";

export const userApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getUsers: build.query({
        query: (params) => {
          return `v1/user?${prepareParams(params)}`;
        },
      }),
      getUserShow: build.query({
        query: (id) => {
          return `v1/user/${id}`;
        },
      }),
      deleteUser: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/user/${id}`,
        }),
      }),

      getGrant: build.query({
        query: (id) => {
          return `v1/user/${id}/grant`;
        },
      }),
      changeUserRoles: build.mutation({
        query: ({ userId, grantId, attributes }) => ({
          method: "PUT",
          url: `v1/user/${userId}/grant/${grantId}`,
          body: JSON.stringify(prepareBody(attributes, grantId, grantType)),
        }),
      }),
      createUser: build.mutation({
        query: (attributes) => ({
          url: "v1/user",
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, userType)),
        }),
      }),
    }),
  });

/* export const {
  useLazyGetUsersQuery,
  useLazyGetUserShowQuery,
  useDeleteUserMutation,
  useChangeUserRolesMutation,
  useLazyGetGrantQuery,
  useCreateUserMutation,
} = userApi; */
