import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import { t } from "i18next";

const ProfileAdrWidget = (props) => {
  const [adrMode, setAdrMode] = useState(null);
  const [power, setPower] = useState(null);
  const [dataRate, setDataRate] = useState(null);
  const [maxDataRate, setMaxDataRate] = useState(null);
  const [channels, setChannels] = useState(null);
  const [dutyCycle, setDutyCycle] = useState(null);
  const [rx1, setRx1] = useState(null);
  const [rx2, setRx2] = useState(null);
  const [rx2f, setRx2f] = useState(null);
  const [requestStatus, setRequestStatus] = useState(null);

  const arpmode = [
    { label: "Disabled" },
    { label: "Auto-Adjust" },
    { label: "Maintain" },
  ];
  const status = [
    { label: "true" },
    { label: "false" },
    { label: "undefined" },
  ];
  const dataRateOptions = [
    { label: "SF12 125KHz (250bit/s)" },
    { label: "SF11 125KHz (440bit/s)" },
    { label: "SF10 125KHz (980bit/s)" },
    { label: "SF9 125KHz (1760bit/s)" },
    { label: "SF8 125KHz (3125bit/s)" },
    { label: "SF7 125KHz (5470it/s)" },
    { label: "SF7 250KHz (11000bit/s)" },
    { label: "50 kbps (50000bit/s)" },
  ];
  const dutyOptions = [
    { label: "1 (100%)" },
    { label: "1/2 (50%)" },
    { label: "1/4 (25%)" },
    { label: "1/8 (12.5%)" },
    { label: "1/16 (6.25%)" },
    { label: "1/32 (3.125%)" },
    { label: "1/64 (1.563%)" },
    { label: "1/128 (0.781%)" },
    { label: "1/256 (0.391%)" },
    { label: "1/512 (0.195%)" },
    { label: "1/1024 (0.098%)" },
    { label: "1/2048 (0.049%)" },
    { label: "1/4096 (0.024%)" },
    { label: "1/8192 (0.012%)" },
    { label: "1/16384 (0.006%)" },
    { label: "1/32768 (0.003%)" },
  ];
  const powerOptions = [{ label: "14 dBm" }];
  useEffect(() => {
    if (adrMode !== null) {
      props.onChange({
        ...props.formData,
        adr_mode: adrMode,
      });
    }
  }, [adrMode]);

  useEffect(() => {
    if (power !== null) {
      props.onChange({
        ...props.formData,
        power: power,
      });
    }
  }, [power]);

  useEffect(() => {
    if (dataRate !== null) {
      props.onChange({
        ...props.formData,
        data_rate: dataRate,
      });
    }
  }, [dataRate]);

  useEffect(() => {
    if (maxDataRate !== null) {
      props.onChange({
        ...props.formData,
        max_data_rate: maxDataRate,
      });
    }
  }, [maxDataRate]);

  useEffect(() => {
    if (channels !== null) {
      props.onChange({
        ...props.formData,
        channels: channels,
      });
    }
  }, [channels]);

  useEffect(() => {
    if (dutyCycle !== null) {
      props.onChange({
        ...props.formData,
        duty_cycle: dutyCycle,
      });
    }
  }, [dutyCycle]);

  useEffect(() => {
    if (rx1 !== null) {
      props.onChange({
        ...props.formData,
        rx1: rx1,
      });
    }
  }, [rx1]);

  useEffect(() => {
    if (rx2 !== null) {
      props.onChange({
        ...props.formData,
        rx2: rx2,
      });
    }
  }, [rx2]);

  useEffect(() => {
    if (rx2f !== null) {
      props.onChange({
        ...props.formData,
        rx2f: rx2f,
      });
    }
  }, [rx2f]);

  useEffect(() => {
    if (requestStatus !== null) {
      props.onChange({
        ...props.formData,
        request_status: requestStatus,
      });
    }
  }, [requestStatus]);
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>ADR</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={adrMode}
                onChange={(event, newValue) => {
                  setAdrMode(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={arpmode}
                renderInput={(params) => (
                  <TextField {...params} label="ADR Mode " />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={power}
                onChange={(event, newValue) => {
                  setPower(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={powerOptions}
                renderInput={(params) => (
                  <TextField {...params} label={t("set_power")} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={dataRate}
                onChange={(event, newValue) => {
                  setDataRate(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={dataRateOptions}
                renderInput={(params) => (
                  <TextField {...params} label={t("set_data_rate")} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={maxDataRate}
                onChange={(event, newValue) => {
                  setMaxDataRate(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={dataRateOptions}
                renderInput={(params) => (
                  <TextField {...params} label={t("max_data_rate")} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={channels}
                onChange={(e) => setChannels(e.target.value)}
                label={t("set_channels")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={dutyCycle}
                onChange={(event, newValue) => {
                  setDutyCycle(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={dutyOptions}
                renderInput={(params) => (
                  <TextField {...params} label={t("set_duty_cycle")} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={rx1}
                fullWidth
                onChange={(e) => setRx1(e.target.value)}
                label={t("Set RX1 DR Offset")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={rx2}
                onChange={(event, newValue) => {
                  setRx2(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={dataRateOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Set RX2 DR" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={rx2f}
                fullWidth
                onChange={(e) => setRx2f(e.target.value)}
                label={t("Set RX2 Freq (MHz) ")}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                disablePortal
                value={requestStatus}
                onChange={(event, newValue) => {
                  setRequestStatus(newValue?.label ?? "");
                }}
                id="combo-box-demo"
                options={status}
                renderInput={(params) => (
                  <TextField {...params} label="Request Status? " />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProfileAdrWidget;
