import { createTheme } from "@mui/material";

const themeTrilliant = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#005daa",
    },
    secondary: {
      main: "#00a94f",
    },
    error: {
      main: "#bd1e1e",
    },
    warning: {
      main: "#f6ae2d",
    },
    background: {
      paper: "#fcfcfc",
    },
    device: {
      main: "#9ABAED",
      contrastText: "#fff",
    },
    group_and_district: {
      main: "#506FA1",
      contrastText: "#fff",
    },
    network: {
      main: "#3c4b64",
      contrastText: "#fff",
    },
  },
});

export default themeTrilliant;
