import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  loraIndex: null,
  loraShow: null,
  networkRegion: null,
};

const loraSlice = createSlice({
  name: "loraSlice",
  initialState,
  reducers: {
    setNetworkRegion(state, action) {
      state.networkRegion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.loraEndpoints.getIndexLora.matchFulfilled,
      (state, action) => {
        state.loraIndex = action.payload.data;
      }
    );
    builder.addMatcher(
      store.loraEndpoints.getShowLora.matchFulfilled,
      (state, action) => {
        state.loraShow = action.payload.data;
      }
    );
  },
});
export const { setNetworkRegion } = loraSlice.actions;
export default loraSlice.reducer;
