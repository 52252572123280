export const mappingCalculating = (h, l, p, c, kc, fc) => {
  const height = h / 1000;
  const width = l / 1000;
  const depth = p / 1000;
  const v = height * depth * width;
  const s = 2 * height * width + 2 * depth * width + 2 * depth * height;
  const kq = (314 * s + c * v) / 1000;
  const k = (kc * kq) / fc;
  return {
    k: +k.toFixed(3),
    kq: kq,
  };
};

export const mappingCalculating10200 = (kq, kc, fc) => {
  const k = (kc * kq) / fc;
  return +k.toFixed(3);
};
