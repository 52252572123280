import React, { useState } from "react";
import useFetch from "use-http";
import { convertParamsToString } from "../../utils";

const DeviceGroupSearchContext = React.createContext();
const DeviceGroupSearchProvider = ({ children }) => {
  const [searchParams, setDeviceGroupSearchParams] = useState();
  const [groups, setGroups] = useState();
  const [group, setGroup] = useState();
  const [page, setPage] = useState();
  const [perPage, setPerPage] = useState();
  const { get, loading, error, del, patch, post } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    { cachePolicy: "no-cache" }
  );
  const searchWithPage = (newPage, newPerPage) => {
    if (newPage != page || newPerPage != perPage) {
      setPage(newPage);
      setPerPage(newPerPage);
      doCall(newPage, newPerPage);
    }
  };

  const search = (params) => {
    setDeviceGroupSearchParams(params);
    doCall(page, perPage, params);
  };

  const setPageAndPerPage = (newPage, newPerPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const doCall = async (page, perPage, params) => {
    var completeParams = { page: page, per_page: perPage, ...searchParams };
    if (params) completeParams = { page: page, per_page: perPage, ...params };
    const newGroups = await get(
      `/api/v1/device_group?${convertParamsToString(completeParams)}`
    );
    setGroups(newGroups);
  };

  const remove = async (id) => {
    await del(`/api/v1/device_group/${id}`);
    doCall();
  };

  const getGroup = async (id) => {
    const newGroup = await get(`/api/v1/device_group/${id}`);
    setGroup(newGroup);
  };

  const update = async (id, updateParams) => {
    const newGroup = await patch(`/api/v1/device_group/${id}`, {
      data: { attributes: updateParams, type: "device_group", id: id },
    });
    setGroup(newGroup);
    return newGroup;
  };

  const removeDevice = async (devigeGroupId, deviceId) => {
    return await del(`api/v1/device_group/${devigeGroupId}/device/${deviceId}`);
  };

  const addDevices = async (deviceGroupId, devices) => {
    return await post(`/api/v1/device_group/${deviceGroupId}/device`, {
      data: {
        attributes: { device_ids: devices.map((d) => d.id) },
        type: "device_group_device",
      },
    });
  };

  let value = {
    searchParams,
    setDeviceGroupSearchParams,
    groups,
    search,
    loading,
    error,
    getGroup,
    group,
    setPageAndPerPage,
    searchWithPage,
    remove,
    update,
    removeDevice,
    addDevices,
  };

  return (
    <DeviceGroupSearchContext.Provider value={value}>
      {children}
    </DeviceGroupSearchContext.Provider>
  );
};

export const useDeviceGroupSearch = () => {
  return React.useContext(DeviceGroupSearchContext);
};

export const DeviceGroupSearchConsumer = DeviceGroupSearchContext.Consumer;
export default DeviceGroupSearchProvider;
