import { prepareParams } from "../../utils";
export const clusterApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getCluster: build.query({
        query: ({ ne_lat, ne_lng, sw_lng, sw_lat, zoom, params }) => {
          return `v1/altior/device/cluster?in_area[ne_lat]=${ne_lat}&in_area[ne_lng]=${ne_lng}&in_area[sw_lng]=${sw_lng}&in_area[sw_lat]=${sw_lat}&zoom_level=${zoom}&${prepareParams(
            params
          )}`;
        },
      }),
      getSingleClusterDevices: build.query({
        query: ({ ne_lat, ne_lng, sw_lng, sw_lat, zoom, id, params }) => {
          return `v1/altior/device?in_area[ne_lat]=${ne_lat}&in_area[ne_lng]=${ne_lng}&in_area[sw_lng]=${sw_lng}&in_area[sw_lat]=${sw_lat}&single_cluster[zoom_level]=${zoom}&single_cluster[cluster_id]=${id}&${prepareParams(
            params
          )}`;
        },
      }),
      getDevices: build.query({
        query: ({ ne_lat, ne_lng, sw_lng, sw_lat, zoom, params }) => {
          return `v1/altior/device?in_area[ne_lat]=${ne_lat}&in_area[ne_lng]=${ne_lng}&in_area[sw_lng]=${sw_lng}&in_area[sw_lat]=${sw_lat}&not_in_cluster[zoom_level]=${zoom}&${prepareParams(
            params
          )}`;
        },
      }),
    }),
  });
