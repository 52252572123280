import {
  PieChart,
  Tooltip as PieTooltip,
  Cell,
  LineChart,
  BarChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
} from "recharts";
import { Box } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useEffect } from "react";
import { Typography } from "@mui/material";

const ApartamentConsumtionsGrafic = ({ meterData, width = 350 }) => {
  const { t } = useTranslation();
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const data = meterData?.map((item) => ({
    name: item?.meta?.apartment_name,
    value: t("number", {
      val: item.value,
    }),
  }));

  return (
    <BarChart
      width={is1280x720 ? 450 : 750}
      height={400}
      margin={{
        left: 0,
        top: 10,
      }}
      data={data?.reverse()}
    >
      <PieTooltip />
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        angle={45}
        interval="preserveStartEnd"
        height={150}
        tickMargin={55}
      />
      <YAxis type="number" /* domain={[0, 100]} */ />

      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  );
};
export default ApartamentConsumtionsGrafic;
