import React, { useState } from "react";
import useFetch from "use-http";
import {
  convertParamsToString,
  geoJsonPolygonSwapCoordinates,
} from "../../utils";
import { useIwdBreadCrumb } from "../IwdBreadcrumbProvider";

const CustomerEndpointContext = React.createContext();

/**
 * This is the CustomerEndpointProvider and has the following functions:
 *
 * - onSubmit(params)
 *
 *   @param  param object the form fields
 */
const CustomerEndpointProvider = ({ children }) => {
  const { get, loading, error, response, del, patch, post, options } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const { setLast } = useIwdBreadCrumb();
  // const [updating, setUpdating] = useState(false);
  const [customerEndpoint, setCustomerEndpoint] = useState();
  const [customerEndpoints, setCustomerEndpoints] = useState();
  const [errors, setErrors] = useState();

  const [searchParams, setCustomerEndpointSearchParams] = useState();

  const searchWithPage = (newPage, newPerPage) => {
    if (newPage != page || newPerPage != perPage) {
      setPage(newPage);
      setPerPage(newPerPage);
      doCall(newPage, newPerPage);
    }
  };

  const search = (params) => {
    setCustomerEndpointSearchParams(params);
    doCall(page, perPage, params);
  };

  const doCall = async (page, perPage, params) => {
    var completeParams = { page: page, per_page: perPage, ...searchParams };
    if (params) completeParams = { page: page, per_page: perPage, ...params };
    const newCustomerEndpoint = await get(
      `/api/v1/customer_endpoint?${convertParamsToString(completeParams)}`
    );
    setCustomerEndpoints(newCustomerEndpoint);
  };
  const setPageAndPerPage = (newPage, newPerPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const updateCustomerEndpoint = async (params, callback) => {
    const body = prepareCustomerEndpointBody(params);

    const result = await patch(
      `/api/v1/customer_endpoint/${customerEndpoint.id}`,
      body
    );
    setCustomerEndpoint(result);
    callback({ action: "updated" });
  };

  const createCustomerEndpoint = async (params, callback) => {
    const body = prepareCustomerEndpointBody(params);
    const result = await post(`/api/v1/customer_endpoint`, body);
    if (response.ok) {
      setCustomerEndpoint(result);
      callback({ action: "created", data: result });
    } else {
      callback({ action: "error", data: result });
    }
  };

  const getCustomerEndpoint = async (id) => {
    const result = await get(`/api/v1/customer_endpoint/${id}`);
    if (response.ok) {
      setLast(result.data.attributes.name);
      setCustomerEndpoint(result.data.attributes);
    }
  };

  const prepareCustomerEndpointBody = ({
    name,
    description,
    endpoint,
    api_version,
    settings,
  }) => {
    var data = {
      data: {
        type: "customer_endpoint",
        attributes: {
          name,
          description,
          endpoint,
          api_version,
          settings,
        },
      },
    };
    if (customerEndpoint && customerEndpoint.id) {
      data.data.id = customerEndpoint.id.toString();
    }
    return data;
  };

  const remove = async (id) => {
    await del(`/api/v1/customer_endpoint/${id}`);
    doCall();
  };

  let value = {
    error,
    loading,
    getCustomerEndpoint,
    customerEndpoint,
    customerEndpoints,
    setCustomerEndpoint,
    searchWithPage,
    search,
    searchParams,
    remove,
    createCustomerEndpoint,
    updateCustomerEndpoint,
    page,
    perPage,
    setPage,
    setPerPage,
  };

  return (
    <CustomerEndpointContext.Provider value={value}>
      {children}
    </CustomerEndpointContext.Provider>
  );
};

export const useCustomerEndpoint = () => {
  return React.useContext(CustomerEndpointContext);
};

export const CustomerEndpointConsumer = CustomerEndpointContext.Consumer;
export default CustomerEndpointProvider;
