import { prepareBodyWithoutId } from "../../utils/api_params";

export const uploadFileApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getFileStatistic: build.query({
        query: (id) => {
          return `v1/import_operation/${id}/statistic`;
        },
      }),
      getFileErrors: build.query({
        query: (id) => {
          return `v1/import_operation?operation_id=${id}&in_error=true`;
        },
      }),
      postFile: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: `v1/altior/device/import`,
          body: JSON.stringify(prepareBodyWithoutId(attributes, "import")),
        }),
      }),
    }),
  });

/* export const {
  useLazyGetFileErrorsQuery,
  useLazyGetFileStatisticQuery,
  usePostFileMutation,
} = uploadFileApi; */
