import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";

export const gatewayApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getIndexGateway: build.query({
        query: (params) => {
          return `v1/altior/device?${prepareParams(params)}`;
        },
      }),
      getShowGateway: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/0/instance/${id}`;
        },
      }),
      deleteGateway: build.mutation({
        query: (id) => ({
          method: "DELETE",
          url: `v1/altior/digital_twin/0/instance/${id}`,
        }),
      }),
      createGateway: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/altior/digital_twin/${id}/instance`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "devices")),
        }),
      }),
      getGatewayMessages: build.query({
        query: ({ twinId, instanceId, params }) => ({
          method: "GET",
          url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}/message?value_types=["data","diagnostic"]&${prepareParams(
            params
          )}`,
        }),
      }),
      getEdgeMessages: build.query({
        query: ({ twinId, instanceId, params }) => ({
          method: "GET",
          url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}/message?${prepareParams(
            params
          )}`,
        }),
      }),
      getMeterReadedMessages: build.query({
        query: (id) => {
          return `v1/message?latst_device_seen_from_gateway=${id}`;
        },
      }),
      getMeterUnreadedMessages: build.query({
        query: ({ id, page, per_page }) => {
          return `v1/message?latst_device_not_handle_from_gateway=${id}&page=${page}&per_page=${per_page}`;
        },
      }),
      getHandledMeterMessages: build.query({
        query: (id) => {
          return `v1/altior/device?condominium_id=${id}&tags=Water Meter&without_reading=true`;
        },
      }),
      gatewayUpload: build.mutation({
        query: ({ id, instanceId, attributes }) => ({
          url: `v1/altior/digital_twin/${id}/instance/${instanceId}/command/set_white_list`,
          method: "POST",
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "device_commands")
          ),
        }),
      }),
    }),
  });
/* export const {
  useLazyGetIndexGatewayQuery,
  useLazyGetShowGatewayQuery,
  useCreateGatewayMutation,
  useLazyGetGatewayMessagesQuery,
  useLazyGetMeterReadedMessagesQuery,
  useLazyGetHandledMeterMessagesQuery,
  useGatewayUploadMutation,
} = gatewayApi; */
