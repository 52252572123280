/* import { build } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/cacheLifecycle' */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { api } from "./api";

export const digitalTwinInstanceRtk = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      postDigitalTwinInstance: build.mutation({
        query: (body) => {
          return {
            url: "v1/plugin/water_meters",

            method: "POST",

            body: JSON.stringify(body),
          };
        },
      }),
    }),
  });

/* export const { usePostDigitalTwinInstanceMutation } = digitalTwinInstanceRtk; */
