import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import PropTypes from "prop-types";

/**
 * Show the Delete dialog, and ask to confirm the operation
 */
const DeleteDialog = ({ show, setShow, deleteId, deleteFunction }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(show);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClose = () => {
		setOpen(false);
		setShow(false);
	};
	const handleYesClose = async () => {
		await deleteFunction(deleteId);
		handleClose();
	};

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{t("confirm_delete")}
				</DialogTitle>
				<DialogContent>
					<WarningIcon sx={{ width: "100%", fontSize: 60 }} color="warning" />
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						{t("no")}
					</Button>
					<Button onClick={handleYesClose} autoFocus>
						{t("yes")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

DeleteDialog.propTypes = {
	/** Set if show or not the dialog */
	show: PropTypes.bool.isRequired,
	/** Parent's function that manage the `show` value. */
	setShow: PropTypes.func.isRequired,
	/**
	 * The id to delete
	 */
	deleteId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
	]),
	/**
	 * Callback called when _"yes"_ button is pressed.
	 *
	 * @param deleteId number the id to delete
	 */
	deleteFunction: PropTypes.func.isRequired,
};
export default DeleteDialog;
