import { createSlice, current } from "@reduxjs/toolkit";

import { actions } from "./authSlice";
import { store } from "../store";
import { removeDuplicateObjects } from "../../utils/api_params";
const initialState = {
  condominiums: null,
  condominiumShow: null,
  metaCount: null,
  condominiumTreeData: null,
  condominiumId: null,
  subCondominiumIdentifier: null,
  subCondominiumId: null,
  selectedTreeItem: "condominum",
  btnDetector: 1,
  apartamentMeterModal: false,
  subCondominiumGatewayModal: false,
  apartamentIdentifier: null,
  condominiumReadings: [],
  condominiumReadingsCount: null,
  subcondominiumMeter: null,
  subCondominiumReadings: null,
  subCondominiumReadingsCount: null,
  apartamentReadings: null,
  apartamaentReadingsCount: null,
  condominiumConsumptions: null,
  condominiumConsumptionsCount: null,
  subCondomimiumConsumptions: null,
  subCondomimiumConsumptionsCount: null,
  apartamentConsumtions: null,
  apartamentConsumtionsCount: null,
  treeExpanded:
    sessionStorage.getItem("TreeExpanded") !== ""
      ? sessionStorage.getItem("TreeExpanded")?.split(",")
      : ["condominum"],
  subCondominiumName: null,
  apartamentName: null,
  condominiumTabValue: 0,
  firstRender: true,
  condominiumName: null,
  condominiumIdentifier: null,
  apartamentId: null,
  meterId: null,
  condominumMeter: null,
  apartamentMeter: null,
  maxSubCondominumPos: null,
  maAxppPos: null,
  actualSubConPos: null,
  actualAppPos: null,
  subCondominiumIndex: null,
  apartamentIndex: null,
  subTopElementMeter: null,
  subBotElementMeter: null,
  posDirection: null,
  subCondominiumTopAllowed: null,
  subCondominiumBotAllowed: null,
  appTopAllowed: null,
  appBotAllowed: null,
  importInfo: [],
  condominiumTrashId: null,
  apartamentShowMode: null,
};

const condominiumSlice = createSlice({
  name: "condominiumSlice",
  initialState,
  reducers: {
    setTreeItem(state, action) {
      if (action.payload !== "new") {
        state.selectedTreeItem = action.payload;
      }
    },
    setBtnDetector(state, action) {
      state.btnDetector = action.payload;
    },
    setSubCondominiumIdentifier(state, action) {
      state.subCondominiumIdentifier = action.payload;
    },
    setApartametnMeterModal(state, action) {
      state.apartamentMeterModal = action.payload;
    },
    setSubCondominiumGatewayModal(state, action) {
      state.subCondominiumGatewayModal = action.payload;
    },
    setApartamentIdentifier(state, action) {
      state.apartamentIdentifier = action.payload;
    },
    setTreeExpanded(state, action) {
      const tree = action.payload;
      state.treeExpanded = [...new Set(tree)];
    },
    setCondomiumShow(state, action) {
      state.condominiumShow = action.payload;
    },
    cleanData(state) {
      state.condominiumId = null;
      state.apartamentIdentifier = null;
      state.subCondominiumIdentifier = null;
    },
    setSubConName(state, action) {
      state.subCondominiumName = action.payload;
    },
    setApartamentName(state, action) {
      state.apartamentName = action.payload;
    },
    setCondomiumTabValue(state, action) {
      state.condominiumTabValue = action.payload;
    },
    setPosDirection(state, action) {
      state.posDirection = action.payload;
    },
    setMaxAppPos(state, action) {
      state.maAxppPos = action.payload;
    },
    setActualSubConPos(state, action) {
      state.actualSubConPos = action.payload;
    },
    setActualAppPos(state, action) {
      state.actualAppPos = action.payload;
    },
    offFirstRender(state) {
      state.firstRender = false;
    },
    setSubComIndex(state, action) {
      state.subCondominiumIndex = action.payload;
    },
    setAppIndex(state, action) {
      state.apartamentIndex = action.payload;
    },
    cleanMeterId(state) {
      state.meterId = null;
    },
    setSubCondominiumTopAllowed(state, action) {
      state.subCondominiumTopAllowed = action.payload;
    },
    setSubCondominiumBotAllowed(state, action) {
      state.subCondominiumBotAllowed = action.payload;
    },
    setAppTopAllowed(state, action) {
      state.appTopAllowed = action.payload;
    },
    setAppBotAllowed(state, action) {
      state.appBotAllowed = action.payload;
    },
    setCondominiumImportInfo(state, action) {
      /* state.importInfo.push(action.payload); */

      let endMessage = action.payload.filter(
        (item) => item.status == "end"
      )?.[0];
      const data = [...current(state.importInfo), ...action.payload];
      const uniqData = removeDuplicateObjects(data, (item) => item?.name);
      state.importInfo = [...uniqData /*  endMessage && endMessage */];
    },
    setcondominiumTrashId(state, action) {
      state.condominiumTrashId = action.payload;
    },
    setCondominiumReadings(state, action) {
      state.condominiumReadings = action.payload;
    },
    resetSubcondominiumReadings(state, action) {
      state.subCondominiumReadings = null;
      console.log("zamutiltemku");
    },
    resetApartmentReadings(state, action) {
      state.apartamentReadings = null;
    },
    resetCondominiumReadings(state, action) {
      state.condominiumReadings = [];
    },
    clearCondominiumImportInfo(state) {
      state.importInfo = [];
    },
    setShowMode(state, action) {
      state.apartamentShowMode = action.payload;
    },
    resetMeterId(state) {
      state.meterId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.condominiumEndpoints.condominiumIndex.matchFulfilled,
      (state, action) => {
        state.condominiums = action.payload.data;
        state.metaCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.condominiumShow.matchFulfilled,
      (state, action) => {
        state.condominiumShow = action.payload.data.attributes;
        state.condominiumTreeData = action.payload.data;
        state.condominiumIdentifier = action.payload.data.attributes.identifier;
        state.condominiumName = action.payload.data.attributes.name;
        state.maxSubCondominumPos =
          action.payload.data.attributes.children.length;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumId.matchFulfilled,
      (state, action) => {
        state.condominiumId = action.payload.data.id;
        state.condominumMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumId.matchFulfilled,
      (state, action) => {
        state.subCondominiumId = action.payload.data.id;
        state.subcondominiumMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentId.matchFulfilled,
      (state, action) => {
        state.apartamentId = action.payload.data.id;
        state.apartamentMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumReadings.matchFulfilled,
      (state, action) => {
        state.condominiumReadings = [
          ...state.condominiumReadings,
          ...action.payload.data?.map((item) => ({
            ...item,
            attributes: {
              ...item.attributes,
              values: {
                ...item.attributes.values,
                serial_number: item.attributes.values.serial_number,
              },
            },
          })),
        ]?.reduce((uniqueReadings, item) => {
          const existingReading = uniqueReadings?.find(
            (reading) =>
              reading?.attributes?.values?.serial_number ===
              item?.attributes?.values?.serial_number
          );

          if (!existingReading) {
            uniqueReadings.push(item);
          }
          console.log("vafled", uniqueReadings);
          return uniqueReadings;
        }, []);

        state.condominiumReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumReadings.matchFulfilled,
      (state, action) => {
        state.subCondominiumReadings = action.payload.data?.reduce(
          (uniqueReadings, item) => {
            const existingReading = uniqueReadings?.find(
              (reading) =>
                reading?.attributes?.values?.serial_number ===
                item?.attributes?.values?.serial_number
            );

            if (!existingReading) {
              uniqueReadings.push(item);
            }
            console.log("vafled", uniqueReadings);
            return uniqueReadings;
          },
          []
        );
        state.subCondominiumReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentReadings.matchFulfilled,
      (state, action) => {
        state.apartamentReadings = action.payload.data?.reduce(
          (uniqueReadings, item) => {
            const existingReading = uniqueReadings?.find(
              (reading) =>
                reading?.attributes?.values?.serial_number ===
                item?.attributes?.values?.serial_number
            );

            if (!existingReading) {
              uniqueReadings.push(item);
            }
            console.log("vafled", uniqueReadings);
            return uniqueReadings;
          },
          []
        );
        state.apartamaentReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumConsumptions.matchFulfilled,
      (state, action) => {
        debugger;
        state.condominiumConsumptions = action.payload.data.attributes.data;
        state.condominiumConsumptionsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubcondominiumConsumtions.matchFulfilled,
      (state, action) => {
        state.subCondomimiumConsumptions = action.payload.data.attributes.data;
        state.subCondomimiumConsumptionsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentConsumtions.matchFulfilled,
      (state, action) => {
        state.apartamentConsumtions = action.payload.data.attributes.data;
        state.apartamaentReadingsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.createApartment.matchFulfilled,
      (state, action) => {
        state.subCondominiumId = null;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getMetertId.matchFulfilled,
      (state, action) => {
        state.meterId = action.payload.data.id;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumBotElement.matchFulfilled,
      (state, action) => {
        state.subBotElementMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumTopElement.matchFulfilled,
      (state, action) => {
        state.subTopElementMeter = action.payload.data;
      }
    );
  },
});
export const {
  setTreeItem,
  setBtnDetector,
  setSubCondominiumIdentifier,
  setApartametnMeterModal,
  setApartamentIdentifier,
  setTreeExpanded,
  setCondomiumShow,
  setSubCondominiumTopAllowed,
  setSubCondominiumBotAllowed,
  cleanData,
  setApartamentName,
  setSubConName,
  setCondomiumTabValue,
  setSubCondominiumGatewayModal,
  offFirstRender,
  cleanMeterId,
  setMaxAppPos,
  setActualAppPos,
  setActualSubConPos,
  setSubComIndex,
  setPosDirection,
  setAppIndex,
  setAppTopAllowed,
  setAppBotAllowed,
  setCondominiumImportInfo,
  clearCondominiumImportInfo,
  setcondominiumTrashId,
  setCondominiumReadings,
  resetApartmentReadings,
  resetSubcondominiumReadings,
  resetCondominiumReadings,
  setShowMode,
} = condominiumSlice.actions;
export default condominiumSlice.reducer;
