import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { autoloadingOff, autoloadingOn } from "../redux/slices/loadingSlice";
import { useEffect } from "react";

const GlobalLoading = () => {
  const dispatch = useDispatch();
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );
  const isSomeQueryMutation = useSelector((state) =>
    Object.values(state.api.mutations).some(
      (mutation) => mutation.status === "pending"
    )
  );
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);

  useEffect(() => {
    if (isSomeQueryPending || isSomeQueryMutation) {
      console.log(
        "someQueryIsPending",
        isSomeQueryPending,
        "someQueryIsPendingMut",
        isSomeQueryMutation
      );
      dispatch(autoloadingOn());
    }
    if (!isSomeQueryMutation && !isSomeQueryPending) {
      dispatch(autoloadingOff());
    }
  }, [isSomeQueryMutation, isSomeQueryPending]);

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",

        zIndex: 11000,
      }}
    >
      {(isSomeQueryPending || isSomeQueryMutation || loading) && (
        <CircularProgress />
      )}
    </Box>
  );
};
export default GlobalLoading;
