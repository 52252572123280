import { Box } from "@mui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearImage, fetchImage } from "../../../redux/slices/getImageSlice";
const DigitalTwinImage = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.getImageSlice.image);
  const { t } = useTranslation();
  const imageId = props?.schema?.imageId;
  useEffect(() => {
    if (imageId) {
      dispatch(fetchImage(`${window.IMAGE_URL}/api/v1/image/${imageId}`));
    } else {
      dispatch(clearImage());
    }
  }, [imageId]);

  return (
    <Box
      sx={{
        height: 150,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!data ? (
        <Box
          sx={{ height: "100%" }}
          component="img"
          src="https://upload.wikimedia.org/wikipedia/commons/1/1f/Water-Meter_43101-480x360_%284999891543%29.jpg"
        ></Box>
      ) : (
        <Box
          sx={{ height: "100%" }}
          component="img"
          src={`data:image/png;base64,${data}`}
        ></Box>
      )}
    </Box>
  );
};
export default DigitalTwinImage;
