import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  campaigns: null,
  campaignShow: null,
};

const campaignSlice = createSlice({
  name: "campaignSlice",
  initialState,
  reducers: {
    unsetCampaignShow(state) {
      state.campaignShow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.campaignEndpoints.getIndexCampaign.matchFulfilled,
      (state, action) => {
        state.campaigns = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.campaignEndpoints.getShowCampaign.matchFulfilled,
      (state, action) => {
        state.campaignShow = action.payload.data.attributes;
      }
    );
  },
});
export const { unsetCampaignShow } = campaignSlice.actions;
export default campaignSlice.reducer;
