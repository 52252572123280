import * as React from "react";
import Popover from "@mui/material/Popover";

import DraftsIcon from "@mui/icons-material/Drafts";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, useTheme } from "@mui/material";

const MessagePopover = ({ message }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {}, [message]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <IconButton>
        <DraftsIcon
          onClick={handleClick}
          sx={{ fill: theme.palette.primary.main }}
        />
      </IconButton>
      <Popover
        disableScrollLock
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <IconButton sx={{ float: "right", m: 2 }}>
          <CancelIcon onClick={handleClose} />
        </IconButton>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          style={{
            overflow: "auto",
          }}
        >
          <Typography variant="subtitle2">
            {<pre>{JSON.stringify(message, null, 10)}</pre>}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default MessagePopover;
