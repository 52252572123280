import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GatewayRegistri from "./GatewayRegistry";
import { useTranslation } from "react-i18next";
import ReadedMeters from "./ReadedMeters";
import { cleanGatewayData } from "../../redux/slices/gatewaySlice";
import { useMediaQuery } from "@mui/material";
import { setTabIndex } from "../../redux/slices/gatewaySlice";
import { setFieldsToUpdate } from "../../redux/slices/gatewaySlice";
import MeterCommands from "../metrics/MeterCommands";
import { notesActions } from "../../redux/slices/notesSlice";
import { store } from "../../redux/store";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import { useDispatch, useSelector } from "react-redux";
import EdgeControllerMEssages from "./EdgeControllerMessages";
import GatewayMessages from "./GatewayMessages";
import theme from "../../theme";
import CsvParser from "./CsvParser";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import GatewayCommands from "./GatewayCommands";
import GatewayNotes from "./GatewayNotes";
import { setOpen } from "../../redux/slices/snackbarSlice";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const EdgeGatewayShow = ({ edgeId, forceEdge }) => {
  const dispatch = useDispatch();
  const [gateway, setGateway] = useState(null);
  const tabIndex = useSelector((state) => state?.gatewaySlice?.gatewayTabIndex);
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const fieldsToUpdate = useSelector(
    (state) => state?.gatewaySlice?.fieldsToUpdate
  );

  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);
  const gatewayShow = useSelector((state) => state?.gatewaySlice?.gateway);
  const edgeShow = useSelector((state) => state?.gatewaySlice?.edgeShow);
  useEffect(() => {
    if (forceEdge && edgeShow) {
      setGateway(edgeShow);
    }
    if (!forceEdge && gatewayShow) {
      setGateway(gatewayShow);
    }
  }, [forceEdge, gatewayShow, edgeShow]);
  const id = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const twinId = useSelector((state) => state?.gatewaySlice?.twinId);
  const [getShowGateway, { isError: gatewayError }] =
    store.useLazyGetShowGatewayQuery();
  const [getGatewayMessages, { isSuccess }] =
    store.useLazyGetGatewayMessagesQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  /*   const serial = result?.data?.data?.attributes?.fields?.serial; */
  useEffect(() => {
    getShowGateway(edgeId);
  }, [edgeId]);
  useEffect(() => {
    dispatch(cleanGatewayData());
  }, []);
  useEffect(() => {
    if (gateway) {
      dispatch(
        setFieldsToUpdate({
          class: gateway?.class,
          instanceId: gatewayId,
          twinId: gateway?.digital_twin_id,
          name: gateway?.fields?.name,
          serial_number: gateway.serial_number,
          note: gateway?.fields?.note,
          security: gateway?.fields?.security?.acl,
          coordinates: {
            lat: gateway?.coordinates?.coordinates?.[1],
            lng: gateway?.coordinates?.coordinates?.[0],
          },
          iccid: gateway?.fields?.iccid,
        })
      );
    }
  }, [gateway]);
  useEffect(() => {
    dispatch(notesActions.cleanNotes());
  }, []);

  /*   useEffect(() => {
    if (twinId) {
      getGatewayMessages({
        twinId: twinId,
        instanceId: idFromCondominium ? idFromCondominium : id,
        params: {
          page: page,
          per_page: perPage,
        },
      });
    }
  }, [twinId, page, id, idFromCondominium]); */

  /*   useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]); */
  const handleChange = (event, newValue) => {
    dispatch(setTabIndex(newValue));
  };

  /*   useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
    };
  }, []); */
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen) {
      return "77vw";
    }
    if (is1280x720 && appBarIsOpen) {
      return "69vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "92vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "77vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "81vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "69vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "95vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "78vw";
    }
  };
  return (
    <Box width={() => dinamicWidthSelector()}>
      {gateway && (
        <>
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h3"
          >{`${t("gateway")}: ${gateway?.serial_number}`}</Typography>
          <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                width: "100%",
              }}
            >
              <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label={t("registry")} />
                {/*  <Tab label={t("manufacturer_data")} /> */}
                <Tab label={t("messages")} /* disabled={!isSuccess} */ />

                <Tab label={t("commands")} />
                {!forceEdge && <Tab label={t("meters_read")} />}
                {/*    <Tab label={t("notes")} /> */}
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <GatewayRegistri update={false} gateway={gateway} />
              </TabPanel>
              {/*    <TabPanel value={tabIndex} index={1}>
                <Typography variant="subtitle2">{t("name") + ":"} </Typography>
                <Typography>{gateway?.fields?.manufacturer} </Typography>
              </TabPanel> */}
              <TabPanel value={tabIndex} index={1}>
                {forceEdge ? (
                  <EdgeControllerMEssages
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    id={id}
                    twinId={twinId}
                  />
                ) : (
                  <GatewayMessages
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    id={id}
                    twinId={twinId}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <MeterCommands />
              </TabPanel>
              {!forceEdge && (
                <TabPanel value={tabIndex} index={3}>
                  <ReadedMeters idFromCondominium={id} />
                </TabPanel>
              )}
              {/*  <TabPanel value={tabIndex} index={4}>
                <GatewayNotes />
              </TabPanel> */}
            </Box>
          </Box>
        </>
      )}
      {!gateway && !autoLoading && gatewayError && (
        <Typography
          sx={{ color: theme.palette.primary.main }}
          variant="h3"
          align="center"
        >
          Concentratore non trovato
        </Typography>
      )}
    </Box>
  );
};

export default EdgeGatewayShow;
