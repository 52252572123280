import React, { useState } from "react";
import useFetch from "use-http";
import {
  convertParamsToString,
  geoJsonPolygonSwapCoordinates,
} from "../../utils";
import { useIwdBreadCrumb } from "../IwdBreadcrumbProvider";

const NetworkAdapterContext = React.createContext();

/**
 * This is the NetworkAdapterProvider and has the following functions:
 *
 * - onSubmit(params)
 *
 *   @param  param object the form fields
 */
const NetworkAdapterProvider = ({ children }) => {
  const { get, loading, error, del, patch, post } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`
  );
  const [page, setPage] = useState();
  const [perPage, setPerPage] = useState();
  const { setLast } = useIwdBreadCrumb();

  const [networkadapter, setNetworkAdapter] = useState();
  const [networkadapters, setNetworkAdapters] = useState();

  const [searchParams, setNetworkAdapterSearchParams] = useState();

  const searchWithPage = (newPage, newPerPage) => {
    if (newPage != page || newPerPage != perPage) {
      setPage(newPage);
      setPerPage(newPerPage);
      doCall(newPage, newPerPage);
    }
  };

  const search = (params) => {
    setNetworkAdapterSearchParams(params);
    doCall(page, perPage, params);
  };

  const doCall = async (page, perPage, params) => {
    var completeParams = { page: page, per_page: perPage, ...searchParams };
    if (params) completeParams = { page: page, per_page: perPage, ...params };
    const newNetworkAdapters = await get(
      `/api/v1/altior/network_adapter?${convertParamsToString(completeParams)}`
    );
    setNetworkAdapters(newNetworkAdapters);
  };
  const setPageAndPerPage = (newPage, newPerPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const getNetworkAdapter = async (id) => {
    const {
      data: { attributes, id: networkadapterId },
    } = await get(`/api/v1/altior/network_adapter/${id}`);
    setLast(attributes.name);
    setNetworkAdapter(attributes);
  };

  let value = {
    loading,
    getNetworkAdapter,
    networkadapter,
    networkadapters,
    setNetworkAdapters,
    searchWithPage,
    search,
    searchParams,
  };

  return (
    <NetworkAdapterContext.Provider value={value}>
      {children}
    </NetworkAdapterContext.Provider>
  );
};

export const useNetworkAdapter = () => {
  return React.useContext(NetworkAdapterContext);
};

export const NetworkAdapterConsumer = NetworkAdapterContext.Consumer;
export default NetworkAdapterProvider;
