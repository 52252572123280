import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = { data: null, meta: null };

const deviceMessageSlice = createSlice({
  name: "deviceMessageSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    deviseMessageCleanUp(state) {
      state.data = null;
      state.meta = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.deviceMessageEndpoints.getDeviceMessages.matchFulfilled,
      (state, action) => {
        console.log("acp", action.payload);
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      }
    );
  },
});
export const { setData, deviseMessageCleanUp } = deviceMessageSlice.actions;

export default deviceMessageSlice.reducer;
