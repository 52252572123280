import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import * as React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";

const ErrorTypeSelector = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const err = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociation
  );

  const [error, setError] = useState(id ? props?.schema?.default : "");
  const [getErrorTypes] = store.useLazyGetErrorTypesQuery();
  const errorhandler = (e) => {
    setError(e.target?.value);
  };
  const errorTypes = useSelector(
    (state) => state?.errorTypeSlice?.errorTypes
  )?.map((err) => ({
    label: err?.name,
    value: err?.id,
  }));
  useEffect(() => {
    getErrorTypes();
  }, []);
  useEffect(() => {
    props.onChange(error);
  }, [error]);
  useEffect(() => {
    console.log("vartuhuek", props);
  }, [props]);
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("error_type")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={error}
          label={t("error_type")}
          onChange={errorhandler}
          error={props?.rawErrors}
        >
          {errorTypes?.map((item) => (
            <MenuItem value={item?.value}>{item?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ErrorTypeSelector;
