import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  consumptions: null,
  consumptionsCount: null,
  selectedMeter: null, // on create
  meterAttributes: null,
  MeterIdToUpdate: null,
  fieldsToUpdate: null,
  transformName: true,
  meterShow: null,
  impulseMeterId: null,
  heatUnit: null,
  coldUnit: null,
  commands: null,
  consumptionsRangeElements: null,
  readingsRangeElements: null,
  trashId: null,
  updateAfterDelete: false,
};

const meterSlice = createSlice({
  name: "meterSlice",
  initialState,
  reducers: {
    consumptionsCleanUp(state) {
      state.consumptions = null;
      state.consumptionsCount = null;
      state.selectedMeter = null;
    },
    setMeterShow(state, action) {
      state.meterShow = action.payload;
    },
    setTransofrmName(state) {
      state.transformName = !state.transformName;
    },
    setMeterIdToUpdate(state, action) {
      state.MeterIdToUpdate = action.payload;
    },
    setSelectedMeter(state, action) {
      if (action.payload) {
        state.selectedMeter = action.payload?.[0];
      }
    },
    setFieldsToUpdate(state, action) {
      state.fieldsToUpdate = action.payload;
    },
    setImpulseId(state, action) {
      state.impulseMeterId = action.payload;
    },
    setHeatUnit(state, action) {
      state.heatUnit = action.payload;
    },
    setColdUnit(state, action) {
      state.coldUnit = action.payload;
    },
    setConsumRange(state, action) {
      state.consumptionsRangeElements = action.payload;
    },
    setReadingRange(state, action) {
      state.readingsRangeElements = action.payload;
    },
    setMeterTrashId(state, action) {
      state.trashId = action.payload;
    },
    setUpdateAfterDelete(state, action) {
      state.updateAfterDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.meterEndpoints.getConsumptions.matchFulfilled,
      (state, action) => {
        debugger;
        state.consumptions = action.payload.data.attributes.data;
        state.consumptionsCount = action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.meterEndpoints.getMeterAttributes.matchFulfilled,
      (state, action) => {
        let results = {};
        action.payload.data.map(
          ({ attributes: { category, name, type, access } }) => {
            if (!results[category]) {
              results[category] = [];
            }
            results[category].push({ name, type, access });
          }
        );
        // let categorieNames = action.payload.data.map(
        //   (item) => item.attributes.category
        // );
        // let unicCategories = [...new Set(categorieNames)];
        // let attributes = unicCategories.map((item) => ({
        //   [item]: action.payload.data
        //     .filter((el) => el.attributes.category === item)
        //     .map((item) => item.attributes.name),
        // }));
        console.log("catka", results);
        state.meterAttributes = results;
      }
    );
    builder.addMatcher(
      store.meterEndpoints.getDigitalTwin.matchFulfilled,
      (state, action) => {
        let meter = action.payload.data.filter(
          (item) => item.id == state.fieldsToUpdate.digital_twin_id
        );
        state.selectedMeter = meter[0];
      }
    );
    builder.addMatcher(
      store.meterEndpoints.getMeterAttributes.matchFulfilled,
      (state, action) => {
        state.commands = action.payload.data.filter(
          (item) => item.attributes.category === "command"
        );
      }
    );
  },
});
export const {
  consumptionsCleanUp,
  setSelectedMeter,
  setFieldsToUpdate,
  setMeterIdToUpdate,
  setTransofrmName,
  setMeterShow,
  setImpulseId,
  setHeatUnit,
  setConsumRange,
  setMeterTrashId,
  setColdUnit,
  setUpdateAfterDelete,
  setReadingRange,
} = meterSlice.actions;
export default meterSlice.reducer;
