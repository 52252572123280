import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import ErrorTypeSelector from "./ErrorTypeSelector";
import ParametersHandler from "./ParametersHandler";
import VisibilityHandler from "./VisibilityHandler";
import RawErrorCustomField from "./RawErrorCustomField";
import BlockConsumptions from "./BlockConsumptions";
import { Button, Grid, Typography } from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CreateErrorAssociation = () => {
  const navigate = useNavigate();
  const [createErrorAssociation] = store.useCreateErrorAssociationMutation();
  const [createdId, setCreatedId] = useState(null);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      error_type_id: {
        type: "string",
        title: t("error_type"),
      },
      raw_error: {
        type: "object",
        properties: {
          error: {
            type: "string",
          },
          class: {
            type: "string",
          },
        },
      },
      block_consumption_computation: {
        type: "boolean",
        title: t("block_consumption_computation"),
        default: false,
      },
      visibility: {
        $id: "1",
        type: "object",
        title: "visibility",
        properties: {
          groups: {
            type: "object",
            default: {
              admin: true,
              "/admin": true,
            },
          },
          users: {
            type: "object",
          },
          comdominium_admin: {
            type: "boolean",
          },
        },
      },
      notify: {
        $id: "1",
        type: "object",
        title: "notify",
        properties: {
          groups: {
            type: "object",
            /*    default: {}, */
          },
          users: {
            type: "object",
            /*    default: {}, */
          },
          comdominium_admin: {
            type: "boolean",
            /*    default: false, */
          },
        },
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    visibility: {
      "ui:field": "parameters",
    },
    notify: {
      "ui:field": "parameters",
    },
    error_type_id: {
      "ui:field": "errortype",
    },
    block_consumption_computation: {
      "ui:field": "BlockConsumptions",
    },
    raw_error: { "ui:field": "rawerror" },
    "ui:order": [
      "raw_error",
      "block_consumption_computation",
      "error_type_id",
      "visibility",
      "notify",
    ],
  };
  const fields = {
    parameters: VisibilityHandler,
    errortype: ErrorTypeSelector,
    rawerror: RawErrorCustomField,
    BlockConsumptions: BlockConsumptions,
  };
  useEffect(() => {
    console.log("frmdtd", formData);
  }, [formData]);
  const onSubmitClick = (obj) => {
    const attributes = {
      class: obj.raw_error.class,
      raw_error: obj.raw_error.error,
      visibility: obj.visibility,
      notify: obj.notify,
      error_type_id: obj.error_type_id,
      block_consumption_computation: obj.block_consumption_computation,
    };
    createErrorAssociation(attributes)
      ?.unwrap()
      ?.then((data) => setCreatedId(data?.data?.attributes?.id))
      .catch((error) => {
        console.error("uff", error);
      });
  };
  useEffect(() => {
    if (createdId) {
      navigate(`/errorassociation/${createdId}`);
    }
  }, [createdId]);

  const customValidate = (formData, errors) => {
    if (!formData.raw_error.error) {
      errors.raw_error.error.addError(t("required_property"));
    }
    if (!formData.raw_error.class) {
      errors.raw_error.class.addError(t("required_property"));
    }
    if (!formData.error_type_id) {
      errors.error_type_id.addError(t("required_property"));
    }
    return errors;
  };
  return (
    <>
      <Typography
        variant="h3"
        sx={{ mb: 5, color: theme.palette.primary.main }}
      >
        {t("create_errors_association")}
      </Typography>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        /*  customValidate={customValidate} */
        showErrorList={false}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        customValidate={customValidate}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        noHtml5Validate
        uiSchema={uiSchema}
      />
    </>
  );
};
export default CreateErrorAssociation;
