import { useEffect } from "react";
import { useState } from "react";
import CenterSvg from "../../statics/icons/center.svg";
import AdjustIcon from "@mui/icons-material/Adjust";
import L from "leaflet";
import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
  Polygon,
  useMap,
  Circle,
  Tooltip as LeafletTooltip,
  CircleMarker,
} from "react-leaflet";

const CampaignMap = ({
  radius,
  setCurrentPosition,
  currentPosition,
  center,
}) => {
  const deviceIcon = () =>
    new L.icon({
      iconUrl: CenterSvg,

      iconSize: 20,
    });
  const map = useMap();

  useEffect(() => {
    if (map) {
      map.on("click", (e) => setCurrentPosition(e.latlng));
    }
  }, [map]);
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }, [map]);

  useEffect(() => {
    if (currentPosition.lat && currentPosition.lng) {
      map.flyTo(currentPosition);
    }
    if (!currentPosition.lat && !currentPosition.lng) {
      map.flyTo(center);
    }
  }, [currentPosition.lat, currentPosition.lng]);

  const redOptions = { color: "red" };

  return (
    <>
      <ScaleControl />

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {currentPosition.lat && currentPosition.lng && (
        <Marker icon={deviceIcon()} position={currentPosition}></Marker>
      )}
      {currentPosition.lat && currentPosition.lng && (
        <Circle
          center={currentPosition}
          radius={radius}
          pathOptions={redOptions}
        />
      )}
    </>
  );
};

export default CampaignMap;
