import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  auditIndex: null,
  auditCount: null,
};

const auditSlice = createSlice({
  name: "auditSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      store.auditEndpoints.getAuditIndex.matchFulfilled,
      (state, action) => {
        state.auditIndex = action.payload.data;
        state.auditCount = action.payload.meta.count;
      }
    );
  },
});
export default auditSlice.reducer;
