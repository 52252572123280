import { MapContainer } from "react-leaflet";

import CampaignMap from "./CampaignMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { useState } from "react";
import { height } from "@mui/system";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDebouncedCallback } from "use-debounce";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const CampaignMapContainer = (props) => {
  const dispatch = useDispatch();
  const center = useSelector((state) => state?.userPreferenceSlice?.mapCenter);

  useEffect(() => {
    console.log("MAPDATA", props);
  }, [props]);
  const [currentPosition, setCurrentPosition] = useState({
    lat: null,
    lng: null,
  });
  const [radius, setRadius] = useState(20000);
  const onResetClick = () => {
    setCurrentPosition({
      lat: "",
      lng: "",
    });
    setRadius(20000);
  };
  useEffect(() => {
    if (currentPosition.lat == 0) {
      setCurrentPosition((prev) => ({
        ...prev,
        lat: "",
      }));
      if (currentPosition.lng == 0) {
        setCurrentPosition((prev) => ({
          ...prev,
          lng: "",
        }));
      }
    }
  }, [currentPosition.lat, currentPosition.lng]);
  useEffect(() => {
    if (currentPosition.lat && currentPosition.lng && radius) {
      const data = {
        lat: currentPosition.lat,
        lng: currentPosition.lng,
        coverage: radius / 1000,
      };
      props.onChange(data);
    }
  }, [currentPosition.lat, currentPosition.lng, radius]);
  useEffect(() => {
    console.log("props", props);
  }, [props]);
  const increaseRadius = () => {
    setRadius((prev) => prev + 10000);
  };
  const decreaseRadius = () => {
    if (radius > 10000) {
      setRadius((prev) => prev - 10000);
    }
  };
  const setDebouncedRadius = useDebouncedCallback((e) => {
    setRadius(e.target.value * 1000);
  }, 5000);
  useEffect(() => {
    console.log("curpos", currentPosition);
  }, [currentPosition]);
  return (
    <Accordion expanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        exp
      >
        Map
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "70%" }}>
            <MapContainer
              id="show-device-map"
              center={center}
              zoom={13}
              minZoom={5}
              scrollWheelZoom={true}
            >
              <CampaignMap
                radius={radius}
                currentPosition={currentPosition}
                setCurrentPosition={setCurrentPosition}
                center={center}
              />
            </MapContainer>
          </Box>

          <Box sx={{ width: "30&" }}>
            <TextField
              type="number"
              value={currentPosition && currentPosition?.lat}
              error={props?.errorSchema?.lat?.__errors?.length > 0}
              helperText={props?.errorSchema?.lat?.__errors?.[0]}
              sx={{ width: "90%", ml: 2 }}
              label="Lat"
              onChange={(e) =>
                setCurrentPosition((prev) => ({
                  ...prev,
                  lat: +e.target.value,
                }))
              }
              InputLabelProps={{ shrink: currentPosition?.lat ? true : false }}
            />
            <TextField
              type="number"
              label="Lng"
              error={props?.errorSchema?.lng?.__errors?.length > 0}
              helperText={props?.errorSchema?.lng?.__errors?.[0]}
              value={currentPosition && currentPosition?.lng}
              sx={{ width: "90%", ml: 2, mt: 1 }}
              onChange={(e) =>
                setCurrentPosition((prev) => ({
                  ...prev,
                  lng: +e.target.value,
                }))
              }
              InputLabelProps={{ shrink: currentPosition?.lng ? true : false }}
            />
            <Box>
              <TextField
                label="coverage in km"
                type="number"
                value={radius && radius / 1000}
                onChange={(e) => setRadius(e.target.value * 1000)}
                sx={{ width: "90%", ml: 2, mt: 1 }}
                InputLabelProps={{
                  shrink: radius || radius == 0 ? true : false,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <AddCircleOutlineIcon
                          onClick={increaseRadius}
                          sx={{ fill: "green" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <RemoveCircleOutlineIcon
                          onClick={decreaseRadius}
                          sx={{ fill: "red" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button sx={{ m: 2 }} variant="contained" onClick={onResetClick}>
              Reset
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default CampaignMapContainer;
