import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  notes: null,
  notesCount: null,
  note: null,
  noteTrashId: null,
};

const notesSlice = createSlice({
  name: "notesSlice",
  initialState,
  reducers: {
    setNoteTrashId(state, action) {
      state.noteTrashId = action.payload;
    },
    cleanNotes(state) {
      state.notes = null;
    },
    cleanNote(state) {
      state.note = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.notesEndpoints.getNotes.matchFulfilled,
      (state, action) => {
        state.notes = action.payload.data;
        state.notesCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.notesEndpoints.getShowNote.matchFulfilled,
      (state, action) => {
        state.note = action.payload.data.attributes;
      }
    );
  },
});
export const notesActions = notesSlice.actions;
export default notesSlice.reducer;
