export const digitalTwinApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getDigitalTwins: build.query({
        query: () => {
          return `v1/altior/digital_twin`;
        },
      }),
      getDigitalTwinAttributes: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/${id}/attribute`;
        },
      }),
    }),
  });

/* export const { useLazyGetDigitalTwinsQuery } = digitalTwinApi; */
