import validator from "@rjsf/validator-ajv6";
import { useRef } from "react";
import { Form } from "@rjsf/mui";
import { ErrorListProps } from "@rjsf/utils";
import { useParams } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import TagsUpdateField from "./TagsUpdateField";
import MuiAlert from "@mui/material/Alert";
import { unSetDigitalTwin } from "../../redux/slices/digitalTwinSlice";
import Snackbar from "@mui/material/Snackbar";
import { store } from "../../redux/store";
import Point from "../form/formComponents/Point";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import DigitalTwinImage from "../form/formComponents/DigitalTwinImage";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import { Button, Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { logout } from "../../redux/slices/logoutSlice";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import HeatMeterForm from "../form/formComponents/HeatMeterForm";

import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import ListItemText from "@mui/material/ListItemText";
import ErrorsHandler from "../../utils/ErrorsHandler";

/* import { postDigitalTwinInstance } from "../../redux/slices/ditigalTwinInstance"; */

import { Box } from "@mui/system";

import { useEffect, useState } from "react";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setFieldsToUpdate } from "../../redux/slices/meterSlice";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        if (i == 14) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const excludedMeterClasses = [
  "gateway",
  "Gateway",
  "condominium",
  "Condominium",
  "sub_condominium",
  "Sub_condominium",
  "apartment",
  "Apartment",
];
const UpdateMeter = () => {
  const fromRef = useRef(null);
  const security = IwdFormAuthWrapper({ section: "security" });
  const { id } = useParams();
  const navigate = useNavigate();
  const [getDigitalTwin] = store.useLazyGetDigitalTwinQuery();
  const meterShow =
    useSelector((state) => state?.meterSlice?.meterShow) ??
    JSON.parse(localStorage.getItem("meterShow"));
  const [meterId, setMeterId] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const haveStates = useSelector((state) => state?.meterSlice?.meterShow);
  const dispatch = useDispatch();
  const fieldsToUpdate = useSelector(
    (state) => state?.meterSlice?.fieldsToUpdate
  );
  const storageFieldsToUpdate = JSON.parse(
    localStorage.getItem("fieldsToUpdate")
  );
  useEffect(() => {
    if (!fieldsToUpdate && storageFieldsToUpdate) {
      dispatch(setFieldsToUpdate(storageFieldsToUpdate));
    }
  }, [fieldsToUpdate, setFieldsToUpdate]);
  /*   const [postDigitalTwinInstance, { data, isLoading, error }] =
    store.usePostDigitalTwinInstanceMutation(); */
  /*  const errors =
    error &&
    Object?.entries(error?.data?.errors)?.map(
      (item) => `${item?.[0]} : ${item?.[1]}`
    ); */
  const [updateMeter, { isSuccess: updateSuccess }] =
    store.useUpdateMeterMutation();
  const MeterIdToUpdate = useSelector(
    (state) => state?.meterSlice?.MeterIdToUpdate
  );
  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const { t } = useTranslation();
  const selectedMeter = useSelector(
    (state) => state?.meterSlice?.selectedMeter
  );

  const [meterType, setMeterType] = useState("");
  const [heatValidation, setHeatValidation] = useState("waiting");
  const checkValidation = (e) => {
    e?.preventDefault();
    fromRef?.current?.submit();
  };
  const [formData, setFormData] = useState({});
  const diameter = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.diameter;
  const identifireType = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.identifier_type;
  const digitalTwinId = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.id;
  const imageId =
    useSelector((state) => state.digitalTwinSlice.digitalTwin)?.[0]?.attributes
      ?.digital_twin_fields?.image_id ?? null;
  const classStateSelector = ["classesSlice", "classes"];

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const meterTypeEnum = (type) => {
    switch (type) {
      case "08":
      case "8":
        return [{ const: t("distributor") }];
        break;
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        return [{ const: "AFS" }, { const: "ACS" }];
        break;
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        return [
          { const: t("cal_fri") /* "CALORIE / FRIGORIE" */ },
          { const: t("calories") },
          { const: t("frigories") },
        ];
        break;
      default:
        return [
          { const: "AFS" },
          { const: "ACS" },
          { const: t("distributor") },
          { const: t("cal_fri") /* "CALORIE / FRIGORIE" */ },
          { const: t("calories") },
          { const: t("frigories") },
          /* { const: t("AFS_ACS") }, */
        ];
        break;
    }
  };
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });
  useEffect(() => {
    getDigitalTwin();
  }, [fieldsToUpdate?.digital_twin_id]);
  /*   useEffect(() => {
    if (fieldsToUpdate && firstRender) {
      setFirstRender(false);
    }
  }, [fieldsToUpdate, firstRender]); */
  useEffect(() => {
    if (firstRender && fieldsToUpdate && formData) {
      setFormData(fieldsToUpdate);
      console.log("bilo sdelano", fieldsToUpdate);
    }
  }, [firstRender, fieldsToUpdate, formData]);
  useEffect(() => {
    if (updateSuccess && haveStates) {
      dispatch(setMessage("Meter aggiornato con successo"));
      navigate(`/meters/${id}`);
    }
    if (updateSuccess && !haveStates) {
      /*   localStorage.removeItem("meterShow");
      localStorage.removeItem("fieldsToUpdate"); */
      /*     localStorage.setItem("meterUpdatedFromCondominium", "ready"); */
      dispatch(setMessage("Meter aggiornato con successo"));
      /*   window.close(); */
    }
  }, [updateSuccess, haveStates]);

  const schm = {
    title: "New Device",
    type: "object",
    properties: {
      /*      serial: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      }, */
      key: {
        $id: "2",
        type: "string",
        title: t("key"),
      },
      meter_type: {
        type: "string",
        oneOf: meterTypeEnum(fieldsToUpdate?.type),
        title: t("meter_type"),
      },

      pod: {
        $id: "3",
        type: "string",
        title: t("POD"),
      },
      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },

      dataConfigurazione: {
        $id: "5",
        type: "string",
        format: "date-time",
        title: t("configuration_date"),
      },

      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: "Europe/Rome",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },

      /*      reverse: {
        $id: "8",
        type: "boolean",
        title: t("mounted_reverse"),
      }, */

      tags: {
        $id: "10",
        title: t("tag"),
        type: "array",
        url: "tag",
        multiple: true,

        properties: {
          tags: {
            type: "number",
          },
        },
      },

      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      /*     note: {
        $id: "13",
        type: "string",
        title: t("notes"),
      }, */
      /* diameter: {
        $id: "11",
        type: "string",
        title: t("diameter"),
        readOnly: true,
      }, */
    },
  };
  const heatSchm = {
    title: "New Device",
    type: "object",
    properties: {
      /*      serial: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      }, */
      key: {
        $id: "2",
        type: "string",
        title: t("key"),
      },
      meter_type: {
        type: "string",
        oneOf: meterTypeEnum(fieldsToUpdate?.type),
        title: t("meter_type"),
      },

      pod: {
        $id: "3",
        type: "string",
        title: t("POD"),
      },
      room: {
        type: "string",
        title: t("room"),
      },
      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },

      dataConfigurazione: {
        $id: "5",
        type: "string",
        format: "date-time",
        title: t("configuration_date"),
      },

      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: "Europe/Rome",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },

      /*      reverse: {
        $id: "8",
        type: "boolean",
        title: t("mounted_reverse"),
      }, */

      tags: {
        $id: "10",
        title: t("tag"),
        type: "array",
        url: "tag",
        multiple: true,

        properties: {
          tags: {
            type: "number",
          },
        },
      },

      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      /*     note: {
        $id: "13",
        type: "string",
        title: t("notes"),
      }, */
      /* diameter: {
        $id: "11",
        type: "string",
        title: t("diameter"),
        readOnly: true,
      }, */
    },
  };

  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "name",
      "serial",
      "meter_type",
      "key",
      "pod",
      "room",
      "address",
      "dataConfigurazione",
      "time_zone",
      "coordinates",

      "tags",

      "reverse",
      "security",
    ],

    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },
    id_type: {
      "ui:field": "search",
    },
    note: {
      "ui:options": {
        widget: "textarea",
        minRows: 12,
      },
    },
    digital_twin: {
      "ui:field": "search",
    },

    tags: {
      "ui:field": "serverSearch",
    },
    image: {
      "ui:field": "image",
    },
    security: {
      "ui:field": "security",
    },
  };
  const meterData = meterShow?.attributes;
  const [uiSchema, setUiSchema] = useState(uiSchm);
  const [schema, setSchema] = useState(null);
  useEffect(() => {
    if (fieldsToUpdate && firstRender) {
      setSchema(fieldsToUpdate?.type === "08" ? heatSchm : schm);
      setFirstRender(false);
    }
  }, [fieldsToUpdate]);
  const customValidate = (formData, errors) => {
    /*    if (formData?.tags?.length < 1) {
    
      errors?.tags?.addError("is a required property");
    } */

    return errors;
  };
  useEffect(() => {
    console.log("formdata", meterData);
  }, [meterData]);
  useEffect(() => {
    if (security && !firstRender) {
      const props = { ...schema?.properties };

      props["security"] = {
        type: "array",
        title: t("security"),
        default: fieldsToUpdate?.security,
      };
      const newSchema = { ...schema, properties: props };
      setSchema(newSchema);
    }
  }, [security, firstRender]);
  const errors = {};
  const onSubmitClick = (formData, e) => {
    /*    delete formData.meter_type; */

    customValidate(formData, formData.errors);

    const attributes = {
      fields: {
        class: meterData?.class,
        user_id: meterData?.user_id,
        serial_number: meterData?.serial_number,

        digital_twin_id: meterData?.digital_twin_id,

        ...formData,

        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [formData?.coordinates?.lng, formData?.coordinates?.lat],
          type: "Point",
        },
      },
    };

    if (fieldsToUpdate.type === "08") {
      setHeatValidation("validation");
    } else {
      console.log("varik2");
      updateMeter({
        attributes: attributes,
        instanceId: id,
        twinId: meterData?.digital_twin_id,
      });
      setOpenErr(true);
    }
  };
  useEffect(() => console.log("formocdatoch", meterShow), [meterShow]);
  useEffect(() => {
    console.log("frmdtupdt", fieldsToUpdate?.security);
  }, [fieldsToUpdate]);

  useEffect(() => {
    const attributes = {
      fields: {
        class: meterData?.class,
        user_id: meterData?.user_id,
        serial_number: meterData?.serial_number,

        digital_twin_id: meterData?.digital_twin_id,

        ...formData,
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [formData?.coordinates?.lng, formData?.coordinates?.lat],
          type: "Point",
        },
      },
    };
    if (heatValidation === "validated") {
      updateMeter({
        attributes: attributes,
        instanceId: id,
        twinId: meterData?.digital_twin_id,
      });
      setOpenErr(true);
    }
  }, [heatValidation]);
  /*   useEffect(() => {
    if (data) {
      setMeterId(data?.data?.id);
    }
  }, [data]); */
  /*   useEffect(() => {
    if (meterId && haveStates) {
      navigate(`/meters/${meterId}`);
    }
  }, [meterId]); */

  useEffect(() => {
    console.log("ITYPE", identifireType);
  }, [identifireType]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenErr(false);
  };
  useEffect(() => {
    console.log("fmdt", haveStates);
  }, [haveStates]);

  const fields = {
    point: Point,
    search: SearcheableSelect,
    serverSearch: SearchOnServer,
    image: DigitalTwinImage,
    heatmeter: HeatMeterForm,
    security: SecurityWidget,
  };

  /*  if (isLoading) {
    return <Loader />;
  } */

  return (
    <>
      {fieldsToUpdate && schema && (
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ mb: 4 }} variant="h1">
            Aggiorna Meter
          </Typography>
          <Form
            id="pisya"
            ref={fromRef}
            schema={schema}
            fields={fields}
            formData={formData}
            onChange={(changeEvent) => {
              setFormData(changeEvent.formData);
            }}
            onSubmit={(values, e) => onSubmitClick(values.formData, e)}
            validator={validator}
            uiSchema={uiSchema}
            /* widgets={widgets} */
            showErrorList={false}
            customValidate={customValidate}
            noHtml5Validate

            /*  templates={{ ErrorListTemplate }} */
          />
          {fieldsToUpdate.meter_type === "RIPARTITORE" && (
            <HeatMeterForm
              setFormData={setFormData}
              formData={formData}
              heatValidation={heatValidation}
              setHeatValidation={setHeatValidation}
              update={true}
            />
          )}

          {/*  <Button
            variant="contained"
            type="button"
            sx={{ float: "right", mt: 2 }}
            onClick={(e) => checkValidation(e)}
          >
            {t("aggiorna")}
          </Button> */}
          <ErrorsHandler />
        </Box>
      )}
    </>
  );
};

export default UpdateMeter;
