import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import { widgetsApi } from "./widgetsApi";

const initialState = {
  widgets: [],
  widgetShow: [],
  updating: false,
  trashId: null,
  previewMode: false,
};

const widgetSlice = createSlice({
  name: "widgetsSlice",
  initialState,
  reducers: {
    setUpdating(state, action) {
      state.updating = action.payload;
    },
    setTrashId(state, action) {
      state.trashId = action.payload;
    },
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    clearWidget(state) {
      state.widgetShow = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.widgetsEndpoints.getWidgets.matchFulfilled,
      (state, action) => {
        state.widgets = action.payload.data;
      }
    );
    builder.addMatcher(
      store.widgetsEndpoints.getShowWidgets.matchFulfilled,
      (state, action) => {
        state.widgetShow = action.payload.data;
      }
    );
  },
});
export const { setUpdating, setTrashId, setPreviewMode, clearWidget } =
  widgetSlice.actions;

export default widgetSlice.reducer;
