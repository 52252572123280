import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from "recharts";

import theme from "../../theme";

const MeterReadingsGraph = ({ meter, unit }) => {
  const { t } = useTranslation();
  const readingsRangeElements = useSelector(
    (state) => state?.meterSlice?.readingsRangeElements
  );
  const data = useSelector((state) => state.meterReadingSlice?.data);

  const mappedData = data?.map((item) => {
    let numericValue = item.reading;
    if (typeof item.reading === "string") {
      numericValue = parseFloat(item.reading.replace(",", "."));
      console.log("hapnulhuya", typeof numericValue);
    }

    return {
      oneDay: /* new Date(item?.oneDay).getTime() */ item?.oneDay,
      reading: isNaN(numericValue)
        ? "Nessuna lettura"
        : unit == "m3"
        ? numericValue
        : numericValue * 1000,
    };
  });
  const [range, setRange] = useState({
    start: mappedData?.length > 10 ? mappedData?.length - 10 : 0,
    end: mappedData?.length - 1,
  });

  useEffect(() => {
    console.log("vpolnomahui", data?.length - 1);
  }, [data]);
  /*   useEffect(() => {
    if (readingsRangeElements) {
      setRange({
        end: mappedData?.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.oneDay),
            }) == readingsRangeElements?.start?.messageDate
        ),
        start: mappedData?.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.oneDay),
            }) == readingsRangeElements?.end?.messageDate
        ),
      });
    }
  }, [readingsRangeElements, data]); */
  useEffect(() => {
    console.log("datagraph", mappedData, range);
  }, [mappedData]);
  const [ref, { width }] = useMeasure();

  if (!data) {
    return <Box ref={ref}>Loading</Box>;
  }

  return (
    <Box ref={ref}>
      <LineChart
        style={{ margin: 4 }}
        width={width - 10}
        height={400}
        data={mappedData}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <Tooltip
          labelFormatter={(value) => {
            return t("dt_n", { val: new Date(value) });
          }}
          formatter={(value) => t("number", { val: value })}
        />
        <Line
          type="monotone"
          dataKey="reading"
          stroke={theme.palette.network.main}
          isAnimationActive={false}
        />
        <CartesianGrid stroke={theme.palette.device.main} />
        <XAxis
          dataKey="oneDay"
          label={{ value: t("date"), position: "insideBottom", offset: 0 }}
          angle={45}
          height={90}
          tickMargin={30}
          interval="preserveStartEnd"
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          stroke={theme.palette.group_and_district.main}
        />
        <YAxis
          label={{ value: t("volume"), angle: -90, position: "insideLeft" }}
          stroke={theme.palette.group_and_district.main}
          tickFormatter={(value) => t("number", { val: value })}
        />
        {/*  <Brush
          height={30}
          dataKey="brushDate"
          travellerWidth={10}
          startIndex={range?.start}
          endIndex={range?.end}
        /> */}
        <Tooltip formatter={(value) => t("number", { val: value })} /> //
      </LineChart>
    </Box>
  );
};

export default MeterReadingsGraph;
