import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearWithValueLabel from "./LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

import { orderBy } from "lodash";
import DoneIcon from "@mui/icons-material/Done";
import TrashModal from "../TrashModal";
import theme from "../../theme";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";

import { loadingOff, loadingOn } from "../../redux/slices/loadingSlice";
const Campaigns = () => {
  const [startCampaign, { isSuccess: startSuccess, isLoading: startLoading }] =
    store.useStartCampaignMutation();
  const [stopCampaign, { isSuccess: stopSuccess, isLoading: stopLoading }] =
    store.useStopCampaignMutation();
  const [deleteCampaign, isSuccess] = store.useDeleteCampaignMutation();
  const [sortedCampaigns, setSortedCampaigns] = useState(null);
  const { t } = useTranslation();
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const campaigns = useSelector((state) => state?.campaignSlice?.campaigns);
  const [trashId, setTrashId] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (campaigns?.length > 0) {
      const sortedCampaigns = orderBy(campaigns, ["id"], ["desc"]);
      setSortedCampaigns(sortedCampaigns);
    }
  }, [campaigns]);
  useEffect(() => {
    console.log("campaigns", campaigns);
  }, [campaigns]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },

    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },

    {
      field: "running",
      headerName: t("running"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) =>
        params.value == false ? (
          <CloseIcon sx={{ fill: "red" }} />
        ) : (
          <DoneIcon sx={{ fill: "green" }} />
        ),
    },
    {
      field: "last_update",
      headerName: t("last_update"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("status"),
      flex: 1,
      editable: false,
      sortable: false,
      editable: false,
      renderCell: (params) => <LinearWithValueLabel progress={params.value} />,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {params?.value?.[1] == false && params?.value?.[2] < 100 && (
              <IconButton
                disabled={startLoading || stopLoading}
                onClick={() => onCampaignStartClick(params?.value?.[0])}
              >
                <Tooltip title={t("start_campaign")}>
                  <PlayArrowIcon sx={{ fill: theme.palette.primary.main }} />
                </Tooltip>
              </IconButton>
            )}
            {params?.value?.[1] == true && (
              <IconButton
                disabled={startLoading || stopLoading}
                onClick={() => onCampaignStopClick(params?.value?.[0])}
              >
                <Tooltip title={t("stop_campaign")}>
                  <PauseIcon sx={{ fill: "orange" }} />
                </Tooltip>
              </IconButton>
            )}
            <IconButton sx={{ mt: 0.5 }}>
              <Link to={`/campaigns/${params?.value?.[0]}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>

            <IconButton onClick={() => onTrashClick(params?.value?.[0])}>
              <Tooltip title={t("delete_campaign")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = sortedCampaigns?.map((item) => ({
    id: item?.id ?? "--",
    name: item?.name ?? "--",
    running: item?.running ?? "--",
    last_update: t("dt", { val: new Date(item?.last_update) }) ?? "--",
    status: item?.status ?? "--",
    actions: [item?.id, item?.running, item?.status],
  }));

  useEffect(() => {
    console.log("tablerows", tableRows);
  }, [tableRows]);
  const onCampaignStartClick = (id) => {
    startCampaign({
      attributes: {
        data: {
          geoJson: "ftm",
        },
      },
      id: id,
    });
  };
  const onCampaignStopClick = (id) => {
    stopCampaign({
      attributes: {
        data: {
          geoJson: "ftm",
        },
      },
      id: id,
    });
  };
  const onTrashClick = (id) => {
    dispatch(opendModal());
    setTrashId(id);
  };
  useEffect(() => {
    if (deleteConfirmed) {
      deleteCampaign(trashId);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  const [getIndexCampaign] = store.useLazyGetIndexCampaignQuery();
  useEffect(() => {
    getIndexCampaign();
  }, []);
  useEffect(() => {
    if (stopSuccess || startSuccess || isSuccess) {
      getIndexCampaign();
    }
  }, [stopSuccess, startSuccess, isSuccess]);
  if (!campaigns) {
    dispatch(loadingOn());
  } else {
    dispatch(loadingOff());
  }
  return (
    <>
      <TrashModal
        text={t("delete_campaign_text")}
        title={t("delete_campaign_title")}
      />
      {loading === false && sortedCampaigns?.length > 0 ? (
        <Box sx={{ height: 700, width: "100%" }}>
          <StyledDataGrid
            disableColumnMenu
            rows={tableRows}
            columns={columns}
            /*   rowCount={response?.data?.meta?.count} */
            pageSize={11}
            rowsPerPageOptions={[11]}
            disableSelectionOnClick
            /*    onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1} */
            // loading={isLoadingTable}
            /*  paginationMode="server" */
            getRowHeight={() => "auto"}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            mt: 10,
          }}
        >
          <Box sx={{ flexGrow: 1, flex: "0 0 45%", mt: 2 }}></Box>
          <Box sx={{ flex: "0 0 49%", width: "100%", mt: 1 }}>
            <Typography sx={{ color: theme.palette.primary.main }} variant="h4">
              {t("no_available_data")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Campaigns;
