import React from "react";
import { Provider } from "use-http";

import { useAuth } from "./AuthProvider";
import { PhoenixSocketProvider } from "./PhoenixContext";

const AuthenticatedProvider = ({ children }) => {
  let auth = useAuth();
  if (!auth.isLogged()) {
    return <> {children} </>;
  }

  const globalOptions = {
    interceptors: {
      request: ({ options }) => {
        options.headers = {
          Authorization: `Bearer ${auth.token()}`,
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        };

        return options;
      },
    },
    onError: ({ error }) => {
      if (error.name === "401") {
        auth.signout(() => {});
      }
    },
  };

  return (
    <Provider options={globalOptions}>
      <PhoenixSocketProvider>{children}</PhoenixSocketProvider>
    </Provider>
  );
};
//<PhoenixSocketProvider>{children}</PhoenixSocketProvider>
export default AuthenticatedProvider;
