import { createSlice } from "@reduxjs/toolkit";
import { clusterApi } from "./clusterApi";
import { store } from "../store";
import { actions } from "./iwdWebSocketSlice";
const initialState = {
  clusters: null,

  devices: null,
  selectedClusterId: "",
  devicesOnGlobalMap: null,
};

const clusterSlice = createSlice({
  name: "clusterSlice",
  initialState,
  reducers: {
    setClusterId(state, action) {
      state.selectedClusterId = action.payload;
    },
    unSetClusterId(state, action) {
      if (state.devices !== null) {
        state.devices = null;
      }
      if (state.selectedClusterId !== "") {
        state.selectedClusterId = "";
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.clusterEndpoints.getCluster.matchFulfilled,
      (state, action) => {
        state.clusters =
          action.payload.data.length > 0 ? action.payload.data : null;
      }
    );
    builder.addMatcher(
      store.clusterEndpoints.getSingleClusterDevices.matchFulfilled,
      (state, action) => {
        state.devices = action.payload;
      }
    );
    builder.addMatcher(
      store.clusterEndpoints.getDevices.matchFulfilled,
      (state, action) => {
        state.devicesOnGlobalMap =
          action.payload.data.length > 0 ? action.payload.data : null;
      }
    );
  },
});
export const { setClusterId, unSetClusterId } = clusterSlice.actions;

export default clusterSlice.reducer;
