import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TreeItem, TreeView } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { Box } from "@mui/system";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import LongNameWrapper from "../../utils/LongNameWrapper";
import { autoloadingOn } from "../../redux/slices/loadingSlice";
import { v4 as uuidv4 } from "uuid";
import { setMessage } from "../../redux/slices/snackbarSlice";
import ModalDialog from "../meters/ModalDialog";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { cleanGatewayData } from "../../redux/slices/gatewaySlice";
import {
  setApartametnMeterModal,
  setSubCondominiumGatewayModal,
  setTreeExpanded,
  setPosDirection,
  setCondomiumTabValue,
  resetApartmentReadings,
  resetMeterId,
  setShowMode,
  resetSubcondominiumReadings,
} from "../../redux/slices/condominiumSlice";
import { setTreeItem } from "../../redux/slices/condominiumSlice";
import {
  setSubComIndex,
  setAppIndex,
} from "../../redux/slices/condominiumSlice";
import { setcondominiumTrashId } from "../../redux/slices/condominiumSlice";
import { Button } from "@mui/material";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import theme from "../../theme";
import { cleanMeterId } from "../../redux/slices/condominiumSlice";

// selectedName should be the name of the subcondominium or apartment selected in the tree, condominium is the condominium object as
// returned by the API
export const CondominiumTree = ({
  condominium,
  subcondominiumName,
  apartmentName,
  serial,
  apartment,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(["condominum"]);
  const [trashId, setTrashId] = useState(null);
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );
  const [deleteCondominium, { isSuccess: deleteSuccess }] =
    store.useDeleteCondominiumMutation();
  const [deleteOption, setDeleteOption] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const handleNodeToggle = (event, nodeIds) => {
    if (nodeIds !== "new") {
      setExpanded(nodeIds);
    }
  };
  const condominiumTrashId = useSelector(
    (state) => state?.condominiumSlice?.condominiumTrashId
  );
  const [deleteModal, setDeletemodal] = useState(false);
  const notCreating = !window.location.href.includes("new");
  const selectedItem = useSelector(
    (state) => state?.condominiumSlice?.selectedTreeItem
  );
  const treeExpanded = useSelector(
    (state) => state.condominiumSlice.treeExpanded
  );
  const subCondominiumTopAllowed = useSelector(
    (state) => state.condominiumSlice.subCondominiumTopAllowed
  );
  const subCondominiumBotAllowed = useSelector(
    (state) => state.condominiumSlice.subCondominiumBotAllowed
  );
  const appTopAllowed = useSelector(
    (state) => state.condominiumSlice.appTopAllowed
  );
  const appBotAllowed = useSelector(
    (state) => state.condominiumSlice.appBotAllowed
  );
  const btnNameDetector = useSelector(
    (state) => state.condominiumSlice.btnDetector
  );
  const handleToggle = (event, nodeIds) => {
    dispatch(setTreeExpanded(nodeIds));
    sessionStorage.setItem("TreeExpanded", treeExpanded);
  };

  const deleteOnlyCondominum = () => {
    setDeleteOption("condominum");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const deleteCondWithMeters = () => {
    setDeleteOption("all");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const onDeleteClick = (id) => {
    setTrashId(id);
    setDeletemodal(true);
  };
  useEffect(() => {
    sessionStorage.setItem("TreeExpanded", treeExpanded);
  }, [treeExpanded]);
  useEffect(() => {
    if (condominiumTrashId && deleteOption === "condominum") {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: false,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (condominiumTrashId && deleteOption === "all") {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: true,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/condominiums");
    }
  }, [deleteSuccess]);
  useEffect(() => {
    console.log("apartmentus", apartment);
  }, [apartment]);
  const sortChildrenByPosition = (children) => {
    return [...children]?.sort(
      (a, b) => a.attributes?.position - b.attributes?.position
    );
  };

  /*  const [btnNameDetector, setBtnNameDetector] = useState(1); */
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    console.log("btnNameDetector", btnNameDetector);
  }, [btnNameDetector]);
  useEffect(() => {
    console.log(
      "ebalrottogopidora",
      apartment?.children?.map((c) => c?.attributes?.serial)
    );
  }, [apartment]);
  useEffect(() => {
    console.log("shmaraapp", apartment);
    console.log("shmaracon", condominium);
    console.log("shmarasub", subcondominiumName);
  }, []);
  useEffect(() => {
    console.log("expix", expanded);
  }, [expanded]);
  return (
    <Box
      sx={{
        padding: "0.5rem",
        marginRight: "0.3rem",

        width: "22rem",
        border: "1px solid gainsboro",
        height: "100%",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalDialog
        open={deleteModal}
        title={t("delete_condominum")}
        close={() => setDeletemodal(false)}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ width: 600, height: 50 }}
        >
          <Button
            onClick={deleteOnlyCondominum}
            sx={{ height: 40, mt: 3 }}
            variant="contained"
          >
            {t("del_con")}
          </Button>
          <Button
            onClick={deleteCondWithMeters}
            sx={{ height: 40, mt: 3 }}
            variant="contained"
          >
            {t("del_con_with_met")}
          </Button>
        </Box>
      </ModalDialog>
      <DeleteConfirmModal
        trashModal={confirmDeleteModal}
        setTrashModal={setConfirmDeleteModal}
        title={t("delete_condominum")}
        text={t("cond_delete_text")}
        deleteFunc={() => setcondominiumTrashId(trashId)}
      />
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreOutlined />}
        defaultExpandIcon={<ChevronRightOutlined />}
        expanded={treeExpanded}
        onNodeToggle={handleToggle}
        selected={selectedItem}
        /*    defaultSelected={["condominium"]} */
        sx={{ height: 500, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      >
        <TreeItem
          nodeId="condominum"
          /*     label={condominium?.attributes?.name ?? ""} */
          label={
            <LongNameWrapper
              text={condominium?.attributes?.name}
              size={"10vw"}
              maxLength={100}
            />
          }
          onClick={() => {
            id &&
              window.sessionStorage.setItem(
                "bcName",
                condominium?.attributes?.name
              );
            dispatch(setBtnDetector(1));
            dispatch(setTreeItem("condominum"));
            navigate(`/condominiums/${id}`);
          }}
        >
          {condominium?.attributes?.children?.length > 0 &&
            sortChildrenByPosition(condominium?.attributes?.children)?.map(
              (subCondominium, index) => (
                <TreeItem
                  /*  label={subCondominium?.attributes?.name} */
                  label={
                    <LongNameWrapper
                      text={subCondominium?.attributes?.name}
                      size={"8vw"}
                      maxLength={100}
                    />
                  }
                  nodeId={subCondominium?.attributes?.identifier}
                  onClick={() => {
                    dispatch(setBtnDetector(2));
                    dispatch(setSubComIndex(index));
                    dispatch(setShowMode("gateway"));
                    /*       dispatch(resetSubcondominiumReadings()); */
                    dispatch(setTreeItem(subCondominium?.attributes?.name));
                    if (
                      subCondominium?.attributes?.name !== subcondominiumName ||
                      apartmentName
                    )
                      navigate(
                        `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                          subCondominium?.attributes?.name
                        )}`
                      );
                  }}
                >
                  {subCondominium?.children?.length > 0 &&
                    sortChildrenByPosition(subCondominium?.children)?.map(
                      (apartment, index) => (
                        <TreeItem
                          nodeId={apartment?.attributes?.identifier}
                          /*        label={apartment?.attributes?.name} */
                          label={
                            <LongNameWrapper
                              text={apartment?.attributes?.name}
                              size={"8vw"}
                              maxLength={100}
                            />
                          }
                          onClick={() => {
                            if (apartment?.attributes?.name !== apartmentName)
                              dispatch(setBtnDetector(3));
                            dispatch(setShowMode("gateway"));
                            window.sessionStorage.setItem("bcName", "");
                            dispatch(setAppIndex(index));
                            /*     dispatch(resetApartmentReadings()); */
                            dispatch(setTreeItem(apartment?.attributes?.name));
                            dispatch(cleanGatewayData());
                            dispatch(cleanMeterId());
                            dispatch(autoloadingOn());
                            navigate(
                              `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                                subCondominium?.attributes?.name
                              )}/apartments/${encodeURIComponent(
                                apartment?.attributes?.name
                              )}`
                            );
                          }}
                        >
                          {apartment?.children?.length > 0 &&
                            apartment?.children?.map((meter) => (
                              <TreeItem
                                nodeId={meter?.attributes?.serial}
                                /*    label={meter?.attributes?.serial} */
                                label={
                                  <LongNameWrapper
                                    text={
                                      meter?.attributes?.meter_type ===
                                      "RIPARTITORE"
                                        ? `${meter?.attributes?.room} - ${meter?.attributes?.serial}`
                                        : meter?.attributes?.serial
                                    }
                                    size={"6vw"}
                                    maxLength={100}
                                  />
                                }
                                onClick={() => {
                                  if (meter?.attributes?.serial !== serial)
                                    dispatch(setBtnDetector(5));
                                  window.sessionStorage.setItem("bcName", "");
                                  dispatch(setShowMode("gateway"));
                                  dispatch(
                                    setTreeItem(meter?.attributes?.serial)
                                  );

                                  navigate(
                                    `/condominiums/${id}/subcondominiums/${encodeURIComponent(
                                      subCondominium?.attributes?.name
                                    )}/apartments/${encodeURIComponent(
                                      encodeURIComponent(
                                        apartment?.attributes?.name
                                      )
                                    )}/apartamentMeter/${encodeURIComponent(
                                      meter?.attributes?.serial
                                    )}`
                                  );
                                }}
                              />
                            ))}
                        </TreeItem>
                      )
                    )}
                </TreeItem>
              )
            )}
        </TreeItem>
      </TreeView>
      {id && btnNameDetector == 1 && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{ marginTop: "1rem" }}
        >
          <Button
            variant="outlined"
            sx={{
              mt: 2,
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
            onClick={() => {
              dispatch(setCondomiumTabValue(0));
              window.sessionStorage.setItem("bcName", "Nuovo sotto condominio");
              dispatch(setTreeItem("condominum"));
              return navigate(`/condominiums/${id}/subcondominiums/new`);
            }}
          >
            {t("new_subcondominium")}
          </Button>
        </Box>
      )}
      {btnNameDetector == 2 && notCreating && (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              onClick={() => dispatch(setPosDirection("subTop"))}
              disabled={!subCondominiumTopAllowed}
              variant="outlined"
              sx={{
                mt: 2,
                background:
                  subCondominiumTopAllowed && theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
                width: "48%",
              }}
            >
              {t("move_up")}
            </Button>
            <Button
              variant="outlined"
              disabled={!subCondominiumBotAllowed}
              onClick={() => dispatch(setPosDirection("subDown"))}
              sx={{
                mt: 2,
                background:
                  subCondominiumBotAllowed && theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
                width: "48%",
              }}
            >
              {t("move_down")}
            </Button>
          </Box>
          <Button
            variant="outlined"
            sx={{
              mt: 2,
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
            onClick={() => {
              dispatch(setBtnDetector(4));
              dispatch(setCondomiumTabValue(0));
              dispatch(cleanMeterId());
              window.sessionStorage.setItem("bcName", "Nuovo Appartamento");
              navigate("apartments/new");
            }}
          >
            {t("new_apartament")}
          </Button>
          <IwdAuthWrapper
            children={
              <Button
                variant="outlined"
                sx={{
                  mt: 2,
                  background: theme.palette.primary.main,
                  color: "white",
                  "&:hover": { backgroundColor: theme.palette.primary.main },
                }}
                onClick={() => {
                  dispatch(setSubCondominiumGatewayModal(true));
                  /*      window.sessionStorage.setItem("bcName", "Nuovo huevo"); */
                  /*  return navigate("/gateways/new"); */
                }}
              >
                {t("add_gateway")}
              </Button>
            }
            section={"associate_gateway"}
          />
        </>
      )}
      {id && btnNameDetector == 3 && notCreating && (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              disabled={!appTopAllowed}
              variant="outlined"
              onClick={() => dispatch(setPosDirection("appTop"))}
              sx={{
                mt: 2,
                background: theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
                width: "48%",
              }}
            >
              {t("move_up")}
            </Button>
            <Button
              variant="outlined"
              disabled={!appBotAllowed}
              onClick={() => dispatch(setPosDirection("appDown"))}
              sx={{
                mt: 2,
                background: theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
                width: "48%",
              }}
            >
              {t("move_down")}
            </Button>
          </Box>

          <Button
            onClick={() => dispatch(setApartametnMeterModal(true))}
            variant="outlined"
            sx={{
              mt: 2,
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
          >
            {t("add_meter")}
          </Button>
        </>
      )}
      {id && btnNameDetector === 5 && notCreating && (
        <Button
          disabled
          variant="outlined"
          sx={{
            mt: 2,
            /*   background: theme.palette.primary.main,
            color: "white",
            "&:hover": { backgroundColor: theme.palette.primary.main }, */
          }}
        >
          {t("sostituisci")}
        </Button>
      )}
      <IwdAuthWrapper
        children={
          <Button
            sx={{
              mt: 2,
              background: theme.palette.error.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.error.main },
            }}
            variant="outlined"
            onClick={() => onDeleteClick(condominiumId)}
          >
            {t("delete_condominium")}
          </Button>
        }
        section={"delete_condominum"}
      />
    </Box>
  );
};
