import { prepareBodyWithoutId } from "../../utils/api_params";

export const messageExportApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      startExportMessage: build.query({
        query: ({ params, digitalTwinId, meeterId }) => {
          return `v1/altior/digital_twin/${digitalTwinId}/instance/${meeterId}/message/export?${params.toString()}`;
        },
        transformResponse: (response) => {
          return response.data.attributes.status;
        },
      }),
      downloadMessages: build.mutation({
        query: ({ params, digitalTwinId, meeterId }) => {
          const uuid = params.get("uuid");
          const downloadType = params.get("download_type");
          return {
            url: `v1/altior/digital_twin/${digitalTwinId}/instance/${meeterId}/message/export?${params.toString()}`,
            method: "POST",
            body: JSON.stringify(
              prepareBodyWithoutId(
                { download_type: downloadType, uuid: uuid },
                "export"
              )
            ),
            responseHandler: async (response) =>
              window.location.assign(
                window.URL.createObjectURL(await response.blob())
              ),
          };
        },
        transformErrorResponse: (response) => {
          return response.data.attributes.status;
        },
      }),
    }),
  });
/* export const { useLazyStartExportMessageQuery, useDownloadMessagesMutation } =
  messageExportApi;
 */
