import React, { useEffect } from "react";
import { Box, Paper, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation } from "react-router-dom";
import { useIwdBreadCrumb } from "./IwdBreadcrumbProvider";
import HomeIcon from "@mui/icons-material/Home";
import { useSelector } from "react-redux";

const renderElements = (t, elements, last, meterShowValue) => {
  const breadcrumbs = elements.split("/");
  const lastItem = breadcrumbs.pop();
  const results = breadcrumbs.reduce(
    ([base, items], currentValue, currentIndex) => {
      base.push(currentValue);
      if (currentValue === "") {
        items.push(
          <Link key={currentIndex} underline="hover" color="inherit" href={"/"}>
            <HomeIcon />
          </Link>
        );
      } else {
        items.push(
          <Link
            key={currentIndex}
            underline="hover"
            color="inherit"
            href={base.join("/")}
          >
            {t(currentValue)}
          </Link>
        );
      }
      return [base, items];
    },
    [[], []]
  );
  if (parseInt(lastItem)) {
    results.push(
      <Typography key={breadcrumbs.length} color="text.primary">
        {meterShowValue ? meterShowValue : lastItem}
      </Typography>
    );
  } else {
    results.push(
      <Typography key={breadcrumbs.length} color="text.primary">
        {t(lastItem)}
      </Typography>
    );
  }
  return results;
};

const IwdBreadCrumb = () => {
  const meterShowValue = useSelector(
    (state) => state?.breadCrumbMeterSlice?.identifierTypeValue
  );

  const { t } = useTranslation();
  const { last } = useIwdBreadCrumb();
  const location = useLocation();

  const results = renderElements(t, location.pathname, last, meterShowValue);
  useEffect(() => {
    console.log("breadres", results);
  }, [results]);
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Paper sx={{ padding: 2 }}>
        {results?.[0][1] !== "logged" && (
          <Breadcrumbs aria-label="breadcrumb">
            {results.map((r) => r)}
          </Breadcrumbs>
        )}
      </Paper>
    </Box>
  );
};
export default IwdBreadCrumb;
