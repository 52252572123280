import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Show a snack bar on the bottom right of the screen.
 * It can be configured with different severities:
 * - `error`
 * - `warning`
 * - `info`
 * - `success`
 */
const IwdSnackBar = ({ isOpen, severity, message, callback }) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (callback) {
      callback(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <Snackbar
      open={callback ? isOpen : open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

IwdSnackBar.propTypes = {
  /** Set if open or not the snack bar*/
  isOpen: PropTypes.bool.isRequired,
  /**
   * Set the severity (the look) of the snack bar. Is possibile to choose from:
   *
   *  - `error`
   *  - `warning`
   *  - `info`
   *  - `success`
   */
  severity: PropTypes.string.isRequired,
  /**
   * The already translated message to show
   */
  message: PropTypes.string.isRequired,
};
export default IwdSnackBar;
