import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDialog = ({ open, close, children, title, cancell = true }) => {
  const [text, setText] = React.useState();

  return (
    <Box>
      <Dialog
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {title ? title : "Help"}
          {cancell && <CancelIcon sx={{ float: "right" }} onClick={close} />}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*  <Button onClick={close}>Disagree</Button>
          <Button onClick={close}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ModalDialog;
