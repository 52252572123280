import { useParams } from "react-router-dom";
import { store } from "../../redux/store";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import EdgeGatewayShow from "../gateway/EdgeGatewayShow";
import { setTabIndex } from "../../redux/slices/gatewaySlice";
import { setGateway } from "../../redux/slices/gatewaySlice";
import GatewayShow from "../gateway/GatewayShow";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const EdgeShow = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      dispatch(setGateway(null));
    }
    dispatch(setTabIndex(0));
    setValue(newValue);
  };
  const { t } = useTranslation();
  const gateway = useSelector((state) => state?.gatewaySlice?.edgeShow);
  const edge = useSelector((state) => state?.gatewaySlice?.edgeShow)?.fields
    ?.compound_twins;
  const [getShowGateway, { isError: gatewayError }] =
    store.useLazyGetShowGatewayQuery();
  useEffect(() => {
    getShowGateway(id);
  }, []);
  useEffect(() => {});
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t("edge_controller")} />
        <Tab label={edge?.[0]?.digital_twin} />
        <Tab label={edge?.[1]?.digital_twin} />
        <Tab label={edge?.[2]?.digital_twin} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <EdgeGatewayShow forceEdge={true} edgeId={id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EdgeGatewayShow edgeId={edge?.[0]?.digital_twin_instance_id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EdgeGatewayShow edgeId={edge?.[1]?.digital_twin_instance_id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EdgeGatewayShow edgeId={edge?.[2]?.digital_twin_instance_id} />
      </TabPanel>
    </>
  );
};
export default EdgeShow;
