import { useEffect } from "react";
import { Tabs, Tab, Box, Typography, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setTreeExpanded,
  setTreeItem,
} from "../../redux/slices/condominiumSlice";
import { useTranslation } from "react-i18next";
import { setCondomiumShow } from "../../redux/slices/condominiumSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
const InfoCondominum = () => {
  const meter = useSelector((state) => state?.meterSlice?.meterShow)?.attributes
    ?.fields;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("pisimeter", meter);
  }, [meter]);
  const condominiumId = meter?.condominium_id;
  const subCondominumName = meter?.sub_condominium_name;
  const apartamentName = meter?.apartment_name;
  const condominumName = meter?.condominium_name;
  const subcondominiumId = meter?.sub_condominium_id;
  const apartamentId = meter?.apartment_id;
  useEffect(() => {
    dispatch(setCondomiumShow(null));
  }, []);
  const onSubCondominumClick = () => {
    dispatch(setTreeExpanded(["condominum", subcondominiumId]));
    /* dispatch(setTreeItem(subcondominiumId)); */
    navigate(
      `/condominiums/${condominiumId}/subcondominiums/${subCondominumName}`
    );

    /* window.open(
      `/condominiums/${condominiumId}/subcondominiums/${subCondominumName}`,
      "_blank"
    ); */
  };
  const onCondominumClick = () => {
    dispatch(setTreeExpanded(["condominum"]));
    navigate(`/condominiums/${condominiumId}`);
    /*   window.open(`/condominiums/${condominiumId}`, "_blank"); */
  };
  const onApartamentClick = () => {
    /*  dispatch(setTreeItem(apartamentId)); */
    dispatch(setTreeExpanded(["condominum", subcondominiumId, apartamentId]));

    navigate(
      `/condominiums/${condominiumId}/subcondominiums/${subCondominumName}/apartments/${apartamentName}`
    );
    /* window.open(
      `/condominiums/${condominiumId}/subcondominiums/${subCondominumName}/apartments/${apartamentName}`,
      "_blank"
    ); */
  };

  return (
    <Grid
      container
      alignItems="center"
      rowSpacing={5}
      direction="row"
      spacing={5}
    >
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("condominium")} :</Typography>

        {condominumName ? (
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="subtitle1">{condominumName}</Typography>
            <IconButton sx={{ ml: 1 }} onClick={onCondominumClick}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        ) : (
          <Typography>{t("no_cond")}</Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("subcondominum")} :</Typography>

        {subCondominumName ? (
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="subtitle1">{subCondominumName}</Typography>
            <IconButton sx={{ ml: 1 }} onClick={onSubCondominumClick}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        ) : (
          <Typography>{t("no_subcom")}</Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("apartament")} :</Typography>

        {apartamentName ? (
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="subtitle1">{apartamentName}</Typography>
            <IconButton sx={{ ml: 1 }} onClick={onApartamentClick}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        ) : (
          <Typography>{t("no_ap")}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default InfoCondominum;
