import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";
export const meterApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getConsumptions: build.query({
        query: (params) => {
          return `v1/plugin/consumptions?${prepareParams(params)}`;
        },
      }),
      getMeterAttributes: build.query({
        query: (id) => {
          return `v1/altior/digital_twin/${id}/attribute`;
        },
      }),
      postCommands: build.mutation({
        query: ({
          digitalTwinId,
          digitalTwinInstance,
          commandName,
          attributes,
        }) => ({
          method: "POST",
          url: `v1/altior/digital_twin/${digitalTwinId}/instance/${digitalTwinInstance}/command/${commandName}`,
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "device_commands")
          ),
        }),
      }),
      updateMeter: build.mutation({
        query: ({ attributes, twinId, instanceId }) => ({
          url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}`,
          method: "PATCH",
          body: JSON.stringify(prepareBody(attributes, instanceId, "devices")),
        }),
      }),
      getDigitalTwin: build.query({
        query: (id) => {
          return `v1/altior/digital_twin`;
        },
      }),
      deleteMeter: build.mutation({
        query: (id) => ({
          url: `v1/plugin/condominium_device/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });

/* export const {
  useLazyGetConsumptionsQuery,
  useLazyGetMeterAttributesQuery,
  useUpdateMeterMutation,
  useLazyGetDigitalTwinQuery,
} = meterApi;
 */
