import React, { useState } from "react";
export const IwdBreadCrumbContext = React.createContext();

const IwdBreadCrumbProvider = ({ children }) => {
const [last, setLast] = useState()


  let value = {
    last,
    setLast
  };

  return (
    <IwdBreadCrumbContext.Provider value={value}>
      {children}
    </IwdBreadCrumbContext.Provider>
  );
};

export const useIwdBreadCrumb = () => {
  return React.useContext(IwdBreadCrumbContext);
};

export const IwdBreadCrumbcosumer = IwdBreadCrumbContext.Consumer;

export default IwdBreadCrumbProvider;
