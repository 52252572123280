import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

/* const getDefaultValue = ({formData}) => {
    
    if(formData && Object.keys(formData).length > 0){
        return {...formData}
    }else{
        return {lat: "0", lng: "0"}
    }
}
 */
const ImpulsiveInput = (props) => {
  const [meterType, setMeterType] = useState(null);
  const [unit, setUnit] = useState(null);
  const { t } = useTranslation();
  const onTypeChange = (e) => {
    setMeterType(e.target.value);
  };
  const onUnitChange = (e) => {
    setUnit(e.target.value);
  };
  useEffect(() => {
    console.log("impupropx", props);
  }, [props]);
  useEffect(() => {
    if (meterType && unit) {
      const values = {
        meter_type: meterType,
        unit_of_reading: unit,
      };
      props.onChange(values);
    }
  }, [meterType, unit]);
  return (
    <>
      <Typography>{t(props.name)}</Typography>
      <Box sx={{ display: "flex", mt: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          {/*      <TextField
             defaultValue={0}
            type="number"
            value={meterType}
            sx={{ width: "100%" }}
            onChange={(e) => onTypeChange(e)}
            label="meter_type"
            variant="outlined"
          /> */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("meter_type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={meterType}
              label={t("meter_type")}
              onChange={onTypeChange}
            >
              <MenuItem value={"AFS"}>AFS</MenuItem>
              <MenuItem value={"ACS"}>ACS</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t("unit")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={unit}
              label={t("unit")}
              onChange={onUnitChange}
            >
              <MenuItem value={"m^3"}>m^3</MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
export default ImpulsiveInput;
