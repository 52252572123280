import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { store } from "../../redux/store";
import theme from "../../theme";

const NetworkEdgeWidget = (props) => {
  const twins = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwins
  )?.filter((item) =>
    item?.attributes?.digital_twin_fields?.class?.includes("rf")
  );
  const [getTwins] = store.useLazyGetDigitalTwinsQuery();
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataQuery();
  const [loraDevices, setLoraDevices] = useState(null);
  const [server, setServer] = useState(null);
  const { t } = useTranslation();
  const [parameters, setParameters] = useState([]);
  const [area, setArea] = useState(null);
  const [dtwin, setDtwin] = useState(null);
  const [serial, setSerial] = useState(null);
  const [addParam, setAddParam] = useState(false);
  const updateTwinAtIndex = (index, twin) => {
    const newwParam = [...parameters];
    newwParam[index] = { ...newwParam[index], twin: twin };
    setParameters(newwParam);
  };
  const updateSerialAtIndex = (index, serial) => {
    const newwParam = [...parameters];
    newwParam[index] = { ...newwParam[index], serial: serial };
    setParameters(newwParam);
  };
  const updateServerAtIndex = (index, server) => {
    const newwParam = [...parameters];
    newwParam[index] = { ...newwParam[index], server: server };
    setParameters(newwParam);
  };
  const updateAreaAtIndex = (index, area) => {
    const newwParam = [...parameters];
    newwParam[index] = { ...newwParam[index], area: area };
    setParameters(newwParam);
  };
  const onDeleteClick = (i) => {
    setParameters(parameters.filter((el, ind) => ind !== i));
  };
  const serverOptions = loraDevices?.map((item) => ({
    label: item?.attributes?.fields?.name,
    id: item?.id,
  }));
  useEffect(() => {
    if (!twins) {
      getTwins();
    }
  }, []);
  useEffect(() => {
    trigger("altior/device?tag=lorawan_server");
  }, []);
  useEffect(() => {
    if (result) {
      setLoraDevices(result?.data?.data);
    }
  }, [result]);
  const mappedTwinOptions = twins?.map((item) => ({
    label: item?.attributes?.name,
    id: item?.id,
    class: item?.attributes?.digital_twin_fields?.class,
  }));
  const selectedSever = loraDevices?.filter((item) => item?.id == server?.id);
  const areaOptions = selectedSever?.[0]?.attributes?.fields?.areas?.map(
    (item) => ({
      label: item?.name,
    })
  );
  const onSaveClick = () => {
    setParameters((prev) => [
      ...prev,
      { twin: dtwin, serial: serial, area: area, server: server, area: area },
    ]);
    setDtwin("");
    setSerial("");
    setArea(null);
    setServer(null);
    setAddParam(false);
  };
  const selectedTwinNames = parameters.map((item) => item?.twin?.label);
  useEffect(() => {
    console.log("loradev", loraDevices);
  }, [loraDevices]);
  useEffect(() => {
    if (parameters) {
      props.onChange(parameters);
      console.log("parix", parameters);
    }
  }, [parameters]);
  const mappedParams = parameters?.map((item, i) => {
    const selectedLoraServer = loraDevices?.filter(
      (dev) => dev.id == item?.server?.id
    );
    const areaOpt = selectedLoraServer?.[0]?.attributes?.fields?.areas?.map(
      (item) => ({
        label: item?.name,
      })
    );
    return (
      <>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ mb: 3 }}
            disablePortal
            id="combo-box-demo"
            options={mappedTwinOptions ?? []}
            value={item?.twin.label}
            onChange={(event, newValue) => {
              updateTwinAtIndex(i, newValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                value={item?.serial}
                label={t("digital_twin")}
              />
            )}
          />
        </Grid>

        <Grid item xs={5.5}>
          <TextField
            fullWidth
            value={item?.serial}
            onChange={(e) => updateSerialAtIndex(i, e.target.value)}
            label={
              item?.twin?.class?.includes("rf_lorawan_gateway")
                ? "MAC"
                : t("serial")
            }
          />
        </Grid>
        {item?.twin?.class === "rf_lorawan_gateway" && (
          <>
            <Grid item xs={6}>
              <Autocomplete
                sx={{ mb: 3 }}
                disablePortal
                id="combo-box-demo"
                options={serverOptions ?? []}
                value={item?.server.label}
                onChange={(event, newValue) => {
                  updateServerAtIndex(i, newValue);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={item?.serial}
                    label={t("server")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Autocomplete
                sx={{ mb: 3 }}
                disablePortal
                id="combo-box-demo"
                options={areaOpt ?? []}
                value={item?.area}
                onChange={(event, newValue) => {
                  updateAreaAtIndex(i, newValue.label);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={item?.serial}
                    label={t("area")}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={0.5}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton onClick={() => onDeleteClick(i)} sx={{ mt: 1 }}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton>
          </Box>
        </Grid>
      </>
    );
  });

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Compound edges
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {mappedParams}
            {parameters.length > 0 && !addParam && (
              <>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    width={"99.5%"}
                    justifyContent={"flex-end"}
                  >
                    <IconButton onClick={() => setAddParam(true)}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </>
            )}
            {(parameters.length == 0 || addParam) && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    sx={{ mb: 3 }}
                    disablePortal
                    id="combo-box-demo"
                    options={
                      mappedTwinOptions?.filter(
                        (item) => !selectedTwinNames?.includes(item?.label)
                      ) ?? []
                    }
                    onChange={(event, newValue) => {
                      setDtwin(newValue);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label={t("digital_twin")} />
                    )}
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <TextField
                    fullWidth
                    onChange={(e) => setSerial(e.target.value)}
                    label={
                      dtwin?.class === "rf_lorawan_gateway"
                        ? "MAC"
                        : t("serial")
                    }
                  />
                </Grid>

                {dtwin?.class === "rf_lorawan_gateway" && (
                  <>
                    <Grid item xs={6}>
                      <Autocomplete
                        sx={{ mb: 3 }}
                        disablePortal
                        id="combo-box-demo"
                        options={serverOptions ?? []}
                        onChange={(event, newValue) => {
                          setServer(newValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label={t("server")} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <Autocomplete
                        sx={{ mb: 3 }}
                        disablePortal
                        id="combo-box-demo"
                        options={areaOptions ?? []}
                        onChange={(event, newValue) => {
                          setArea(newValue.label);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label={t("area")} />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={0.5}>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <IconButton
                      onClick={onSaveClick}
                      sx={{ mt: 1 }}
                      disabled={!dtwin || !serial}
                    >
                      <DoneIcon
                        sx={{
                          color: dtwin && serial && theme.palette.success.main,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default NetworkEdgeWidget;
