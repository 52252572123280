import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const HeatCostTab = ({ result }) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.log("pisipopix", result);
  }, [result]);
  return (
    <Grid container spacing={4}>
      {result?.attributes?.fields?.radiator_height && (
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("radiator_height")} :</Typography>
          <Typography variant="subtitle1">
            {result?.attributes?.fields?.radiator_height + " mm" ?? "--"}
          </Typography>
        </Grid>
      )}
      {result?.attributes?.fields?.radiator_width && (
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("radiator_width")} :</Typography>
          <Typography variant="subtitle1">
            {result?.attributes?.fields?.radiator_width + " mm" ?? "--"}
          </Typography>
        </Grid>
      )}
      {result?.attributes?.fields?.radiator_depth && (
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("radiator_depth")} :</Typography>
          <Typography variant="subtitle1">
            {result?.attributes?.fields?.radiator_depth + " mm" ?? "--"}
          </Typography>
        </Grid>
      )}

      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("radiator_c")} :</Typography>
        <Typography variant="subtitle1">
          {result?.attributes?.fields?.radiator_c
            ? `${result?.attributes?.fields?.radiator_c} W/m^3 `
            : "--"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("factory_converter")} :</Typography>
        <Typography variant="subtitle1">
          {t("number", {
            val: result?.attributes?.fields?.factory_converter,

            maximumFractionDigits: 3,
          })}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("kq")} :</Typography>
        <Typography variant="subtitle1">
          {t("number", {
            val: result?.attributes?.fields?.kq,

            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("k")} :</Typography>
        <Typography variant="subtitle1">
          {t("number", {
            val: result?.attributes?.fields?.k,

            maximumFractionDigits: 3,
          })}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{t("kc")} :</Typography>
        <Typography variant="subtitle1">
          {t("number", {
            val: result?.attributes?.fields?.kc,

            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default HeatCostTab;
