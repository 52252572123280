import { useState, useEffect } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { loadingOff, loadingOn } from "../redux/slices/loadingSlice";
import { StyledDataGrid } from "./StyledDataGrid";
import ErrorIcon from "@mui/icons-material/Error";
import {
  setMessage as setMsg,
  setSeverity,
} from "../redux/slices/snackbarSlice";
import theme from "../theme";
import { prepareBody } from "../utils/api_params";
import MetersTableTab from "../components/form/formComponents/MetersTableTab";
import Chip from "@mui/material/Chip";
import { setCondominiumReadings } from "../redux/slices/condominiumSlice";
import DraftsIcon from "@mui/icons-material/Drafts";
import FormAccordion from "../components/form/formComponents/Accordion";
import IconButton from "@mui/material/IconButton";
import { getDateDifferenceInDays } from "../utils/api_params";
import ModalDialog from "../components/meters/ModalDialog";
import SingleDatePicker from "../components/form/formComponents/SingleDatePicker";
import { resetCondominiumReadings } from "../redux/slices/condominiumSlice";
import { store } from "../redux/store";
import { Link } from "react-router-dom";
import { objectFieldTemplate } from "../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CondominiumReadingsTab = ({ condominiumId }) => {
  const dispatch = useDispatch();

  const condominiumReadings = useSelector(
    (state) => state?.condominiumSlice?.condominiumReadings
  );
  const readingsCount = Math.ceil(
    useSelector(
      (state) => state?.condominiumSlice?.condominiumReadingsCount / 100
    )
  );
  const condominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const condominiumname = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.name;
  const [filteredCondominiumReadings, setFilteredCondomoniumReadings] =
    useState();
  const [meterType, setMeterType] = useState(["AFS"]);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const condominiumReadingsCount = useSelector(
    (state) => state?.condominiumSlice?.condominiumReadingsCount
  );
  const [exportReadings] = store.useExportCondominiumReadingsMutation();
  const [colorMeters, setColorMeters] = useState([]);
  const [savedToDate, setSavedToDate] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [heatMeters, setHeatMeters] = useState([]);
  const [coldMeters, setColdMeters] = useState([]);
  const [readyToShow, setReadyToShow] = useState(false);
  const [afsMeters, setAfsMeters] = useState([]);
  const [acsMeters, setAcsMeters] = useState([]);
  const [heatCostMeters, setHeatCostMeters] = useState([]);

  const condominiumRadingsFilter = () => {
    if (meterType?.length === 1 && meterType?.[0] === "CALORIE / FRIGORIE") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (
      meterType?.[0] === "CALORIE / FRIGORIE" &&
      meterType?.[1] === "Calorie"
    ) {
      const filteredHeatReadings = condominiumReadings?.filter((item) =>
        heatMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      const filteredReadings = condominiumReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );

      setFilteredCondomoniumReadings([
        ...filteredReadings,
        ...filteredHeatReadings,
      ]);
    }
    if (
      meterType?.[0] === "CALORIE / FRIGORIE" &&
      meterType?.[1] === "Frigorie"
    ) {
      const filteredColdReadings = condominiumReadings?.filter((item) =>
        coldMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      const filteredReadings = condominiumReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings([
        ...filteredReadings,
        ...filteredColdReadings,
      ]);
    }
    if (meterType?.[0] === "ACS") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        acsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "AFS") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        afsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "RIPARTITORE") {
      const filteredReadings = condominiumReadings?.filter((item) =>
        heatCostMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
  };
  useEffect(() => {
    condominiumRadingsFilter();
  }, [meterType, condominiumReadings]);

  const meterTypeSelector = (item) => {
    switch (item?.type) {
      case "08":
      case "8":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        if (
          item?.hasOwnProperty("cold_last_reading") ||
          item.hasOwnProperty("heat_last_reading")
        ) {
          if (
            item?.cold_last_reading?.value === null &&
            !item?.heat_last_reading?.value === null
          ) {
            return item;
          } else return null;
        } else {
          return [];
        }
    }
  };
  const allMeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    .map((item) => item?.children)
    .flat();
  const mappedMeters = {};
  allMeters.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedMeters[identifier] = obj;
  });
  const meters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    .map((item) => item?.children)
    .flat();
  const allPulsMeter = allMeters
    .map((item) => item?.children)
    .flat()
    .filter((item) => item?.attributes?.serial_number.includes("-"));
  const pulsMetersIdId = allPulsMeter?.map(
    (item) => item?.attributes?.identifier
  );
  const meterIdCollector = () => {
    const combinedMeters = [...meters, ...allPulsMeter];

    setColorMeters(
      combinedMeters
        ?.filter(
          (item) =>
            item?.attributes?.meter_type === "CALORIE / FRIGORIE" ||
            item?.attributes?.meter_type === "Calorie" ||
            item?.attributes?.meter_type === "Frigorie"
        )
        ?.map((item) => item?.attributes?.identifier)
    );

    /* setHeatMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "Calorie")
        ?.map((item) => item?.attributes?.identifier)
    );

    setColdMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "Frigorie")
        ?.map((item) => item?.attributes?.identifier)
    ); */
    setAcsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "ACS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setAfsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "AFS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setHeatCostMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "RIPARTITORE")
        ?.map((item) => item?.attributes?.identifier)
    );
  };
  useEffect(() => {
    meterIdCollector();
  }, []);

  const apartments = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat();

  const mappedApartments = {};
  apartments.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedApartments[identifier] = obj;
  });
  useEffect(() => {
    console.log("aparts", apartments);
    console.log("apartsmap", mappedApartments);
  }, [apartments]);
  const subcondominiumInfo = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.children?.[0]?.children;
  const nullMeter = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    .map((item) => item?.children)
    .flat()
    .filter((item) => meterTypeSelector(item?.attributes))

    .map((item) => ({
      children: item?.children,
      attributes: {
        device_id: item?.attributes?.identifier,
        meter_type: item?.attributes?.meter_type,
        values: {
          serial_number: item?.attributes?.serial_number,

          values: {
            apartment_name: item?.attributes?.apartment_name,
            condominium_name: item?.attributes?.condominium_name,
            sub_condominium_name: item?.attributes?.sub_condominium_name,
            internal:
              mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.internal,
            flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
              ?.flat,
            room: mappedMeters[item?.attributes?.identifier]?.attributes?.room,
          },
        },
      },
    }));
  const nullPulsMeters = nullMeter
    ?.map((item) =>
      item?.children?.filter(
        (item) => item?.attributes?.meter_type && !item?.last_reading?.value
      )
    )
    .flat()
    .map((item) => {
      const actualMeter = nullMeter.filter((nm) =>
        item?.attributes?.serial_number.includes(
          nm?.attributes?.values?.serial_number
        )
      );
      return {
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,
            values: {
              apartment_name:
                actualMeter?.[0]?.attributes?.values?.values?.apartment_name,
              condominium_name:
                actualMeter?.[0]?.attributes?.values?.values?.condominium_name,
              sub_condominium_name:
                actualMeter?.[0]?.attributes?.values?.values
                  ?.sub_condominium_name,
            },
          },
        },
      };
    });

  const filteredNullMeters =
    readyToShow &&
    nullMeter?.filter((item) =>
      meterType.includes(item?.attributes?.meter_type)
    );
  const filteredNullPulsMeters = nullPulsMeters?.filter((item) =>
    meterType.includes(item?.attributes?.meter_type)
  );
  const mappedFilteredReadings = filteredCondominiumReadings?.map((item) => {
    if (
      item &&
      item.attributes &&
      item.attributes.values &&
      item.attributes.values.values
    ) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          values: {
            ...item.attributes.values,
            values: {
              ...item.attributes.values.values,
              internal:
                mappedApartments[item?.attributes?.values?.values?.apartment_id]
                  ?.attributes?.internal,
              flat: mappedApartments[
                item?.attributes?.values?.values?.apartment_id
              ]?.attributes?.flat,
              room: mappedMeters[item?.attributes?.values?.device_identifier]
                ?.attributes?.room,
            },
          },
        },
      };
    }

    return item;
  });
  const allMeterIds = allMeters?.map((item) => item?.attributes?.identifier);
  const filteredReadingsIds = filteredCondominiumReadings?.map(
    (item) => item?.attributes?.values?.device_identifier
  );
  const newPulsMetersIds = pulsMetersIdId.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );
  const mappedPulsMeters = {};
  allPulsMeter.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedPulsMeters[identifier] = obj;
  });

  const newNullMeterIds = allMeterIds?.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );

  const newNullPulsMeters = newPulsMetersIds?.reduce((acc, key) => {
    if (mappedPulsMeters.hasOwnProperty(key)) {
      acc[key] = mappedPulsMeters[key];
    }
    return acc;
  }, {});

  const newNullMeters = newNullMeterIds.reduce((acc, key) => {
    if (mappedMeters.hasOwnProperty(key)) {
      acc[key] = mappedMeters[key];
    }
    return acc;
  }, {});
  const mappedNullPulsMeters =
    condominiumReadings &&
    Object.values(newNullPulsMeters)
      .map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,

            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
            },
          },
        },
      }))
      ?.filter((item) => meterType.includes(item?.attributes?.meter_type));

  const mappedNullMeters =
    condominiumReadings &&
    Object.values(newNullMeters)
      .map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,

            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
            },
          },
        },
      }))
      ?.filter((item) => meterType.includes(item?.attributes?.meter_type));
  useEffect(() => {
    console.log(
      "allmi",
      allPulsMeter.filter((item) => item?.attributes?.meter_type)
    );
  }, [allPulsMeter]);
  useEffect(() => {
    console.log("apexxxx", meters);
  }, [newNullMeterIds, allMeterIds]);

  const allReadings =
    condominiumReadings &&
    filteredCondominiumReadings &&
    nullMeter &&
    readyToShow
      ? [
          ...mappedNullMeters,

          ...mappedNullPulsMeters,
          ...mappedFilteredReadings,
          /* condominiumRadingsFilter(), */
        ]
      : [];
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const now = new Date();
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );

  now.setHours(0, 0, 0, 0);
  const [getReadings, { isSuccess, status }] =
    store.useLazyGetCondominiumReadingsQuery();
  const fields = {
    datepicker: SingleDatePicker,
  };
  useEffect(() => {
    console.log("readingsCount", readingsCount);
  }, [readingsCount]);
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
      },
      condominium_name: {
        id: 4,
        title: t("condominium_name"),
        type: "string",
      },
      sub_condominium_name: {
        id: 5,
        title: t("sub_condominium_name"),
        type: "string",
      },
      apartment_name: {
        id: 1,
        title: t("apartment_name"),
        type: "string",
      },
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          to_date: {
            type: "string",
            default: now.toISOString(),
          },
        },
      },
      read_time: {
        id: 6,
        title: t("read_time"),
        type: "string",
        label: "read_time",
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    read_time: {
      "ui:field": "datepicker",
    },
  };
  useEffect(() => {
    if (condominiumId) {
      const to = new Date();
      const toDate = new Date(
        Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1)
      );
      getReadings({
        condominium_id: condominiumIdentifier,
        no_gateway: true,
        read_time: savedFromDate && savedFromDate,
        to_date: savedToDate ?? toDate.toISOString(),
        nearest_message: true,
        type: meterType,
        /*  page: page, */
      });
    }
  }, [condominiumId, meterType]);
  useEffect(() => {
    if (isSuccess) {
      setFirstRender(false);
    }
  }, [isSuccess]);

  const openMessageModal = () => {
    setMessageModal(true);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };
  const onMailClick = (msg) => {
    setMessage(msg);
    openMessageModal();
  };
  useEffect(() => {
    console.log("usya", nullMeter);
  }, [nullMeter]);

  const readingRows = allReadings?.map((item, i) => ({
    id: i,
    serial_number: item?.attributes?.values?.serial_number,
    lastReadingValue:
      item?.attributes?.values?.values?.value?.last_reading &&
      item?.attributes?.values?.values?.value?.last_reading?.value !== null
        ? t("number", {
            val: item?.attributes?.values?.values?.value?.last_reading?.value,
          })
        : "--",
    unit:
      item.attributes?.values?.values.value?.last_reading?.unit == "m^3"
        ? "m³"
        : item.attributes?.values?.values.value?.last_reading?.unit ?? "--",
    readTime: item?.attributes?.values?.read_time
      ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
      : "--",
    alarms: item?.attributes?.values?.values?.value?.alarms ?? "--",
    apartment_name: item?.attributes?.values?.values?.apartment_name,
    condominium_name: item?.attributes?.values?.values?.condominium_name,
    internal: item?.attributes?.values?.values?.internal ?? "--",
    floor: item?.attributes?.values?.values?.flat ?? "--",
    sub_condominium_name:
      item?.attributes?.values?.values?.sub_condominium_name,
    actions: item?.attributes,
    dayDiff: getDateDifferenceInDays(item?.attributes?.values?.read_time),
  }));

  useEffect(() => {
    console.log("pusya", nullPulsMeters);
  }, [nullPulsMeters]);
  const readingColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "floor",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "readTime",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "lastReadingValue",
      headerName: t("last_reading_value"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return (
            <Box>
              {params.value.map((item) => (
                <Chip
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },

    /*   {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton
              disabled={!params?.value?.values?.values?.value}
              onClick={() => onMailClick(params?.value?.values?.values?.value)}
            >
              <DraftsIcon sx={{ fill: "red" }} />
            </IconButton>
            {params?.value?.device_id && (
              <IconButton sx={{ mt: 0.5 }}>
                <Link
                  to={`/meters/${params?.value?.device_id}`}
                  target="_blank"
                >
                  <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                </Link>
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const heatColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "floor",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "readTime",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "heat_last_reading",
      headerName: t("last_reading_heat_val"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "cold_last_reading",
      headerName: t("last_reading_cold_val"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return (
            <Box>
              {params.value.map((item) => (
                <Chip
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },

    /*  {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton
              disabled={!params?.value?.values?.values?.value}
              onClick={() => onMailClick(params?.value?.values?.values?.value)}
            >
              <DraftsIcon sx={{ fill: "red" }} />
            </IconButton>
            {params?.value?.device_id && (
              <IconButton sx={{ mt: 0.5 }}>
                <Link
                  to={`/meters/${params?.value?.device_id}`}
                  target="_blank"
                >
                  <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                </Link>
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const heatRows = allReadings?.map((item, i) => ({
    id: i,
    serial_number: item?.attributes?.values?.serial_number,
    cold_last_reading:
      item?.attributes?.values?.values?.value?.cold_last_reading?.value !== null
        ? item?.attributes?.values?.values?.value?.cold_last_reading?.unit ===
          "Wh"
          ? t("number", {
              val:
                item?.attributes?.values?.values?.value?.cold_last_reading
                  ?.value / 1000,
            })
          : t("number", {
              val: item?.attributes?.values?.values?.value?.cold_last_reading
                ?.value,
            })
        : "--",
    heat_last_reading:
      item?.attributes?.values?.values?.value?.heat_last_reading?.value !== null
        ? item?.attributes?.values?.values?.value?.heat_last_reading?.unit ===
          "Wh"
          ? t("number", {
              val:
                item?.attributes?.values?.values?.value?.heat_last_reading
                  ?.value / 1000,
            })
          : t("number", {
              val: item?.attributes?.values?.values?.value?.heat_last_reading
                ?.value,
            })
        : "--",
    unit:
      item?.attributes?.values?.values?.value?.heat_last_reading?.unit === "Wh"
        ? "kWh"
        : item?.attributes?.values?.values?.value?.heat_last_reading?.unit ??
          "--",
    readTime: item?.attributes?.values?.read_time
      ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
      : "--",
    alarms: item?.attributes?.values?.values?.value?.alarms ?? "--",
    apartment_name: item?.attributes?.values?.values?.apartment_name,
    condominium_name: item?.attributes?.values?.values?.condominium_name,
    internal: item?.attributes?.values?.values?.internal ?? "--",
    floor: item?.attributes?.values?.values?.flat ?? "--",
    sub_condominium_name:
      item?.attributes?.values?.values?.sub_condominium_name,
    actions: item?.attributes,
    dayDiff: getDateDifferenceInDays(item?.attributes?.values?.read_time),
  }));
  useEffect(() => {
    console.log("allReadings", allReadings);
  }, [allReadings]);
  const heatCostColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "floor",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "room",
      headerName: t("room"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "readTime",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "last_unconverted_reading",
      headerName: t("last_unconverted_reading"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "k",
      headerName: t("K"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "lastReadingValue",
      headerName: t("parameterized_reading"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },

    /*  {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 2,
    },
 */
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return (
            <Box>
              {params.value.map((item) => (
                <Chip
                  sx={{
                    background: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                    mt: 1,
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },

    /* {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton
              disabled={!params?.value?.values?.values?.value}
              onClick={() => onMailClick(params?.value?.values?.values?.value)}
            >
              <DraftsIcon sx={{ fill: "red" }} />
            </IconButton>
            {params?.value?.device_id && (
              <IconButton sx={{ mt: 0.5 }}>
                <Link
                  to={`/meters/${params?.value?.device_id}`}
                  target="_blank"
                >
                  <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                </Link>
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const heatCostRows = allReadings?.map((item, i) => ({
    id: i,
    serial_number: item?.attributes?.values?.serial_number,
    lastReadingValue:
      item?.attributes?.values?.values?.value?.last_reading &&
      item?.attributes?.values?.values?.value?.last_reading?.value !== null
        ? t("number", {
            val: item?.attributes?.values?.values?.value?.last_reading?.value,
          })
        : "--",
    last_unconverted_reading:
      item?.attributes?.values?.values?.value?.last_unconverted_reading
        ?.value !== null
        ? t("number", {
            val: item?.attributes?.values?.values?.value
              ?.last_unconverted_reading?.value,
          })
        : "--",
    /*   unit: item.attributes?.values?.values.value?.last_reading?.unit ?? "--", */
    k:
      item?.attributes?.values?.values?.value?.k !== null
        ? t("number", {
            val: item?.attributes?.values?.values?.value?.k,
          })
        : "--",
    readTime:
      t("date_val", { val: new Date(item?.attributes?.values?.read_time) }) ??
      "--",
    alarms: item?.attributes?.values?.values?.value?.alarms ?? "--",
    apartment_name: item?.attributes?.values?.values?.apartment_name,
    internal: item?.attributes?.values?.values?.internal ?? "--",
    floor: item?.attributes?.values?.values?.flat ?? "--",
    room: item?.attributes?.values?.values?.room ?? "--",
    condominium_name: item?.attributes?.values?.values?.condominium_name,
    sub_condominium_name:
      item?.attributes?.values?.values?.sub_condominium_name,
    actions: item?.attributes,
    dayDiff: getDateDifferenceInDays(item?.attributes?.values?.read_time),
  }));
  const onSubmitClick = (obj) => {
    dispatch(setCondominiumReadings([]));
    const messagesDateTo = new Date(formData.insertion_date.to_date);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setSavedToDate(messagesDateTo.toISOString());
    setSavedFromDate(obj.read_time?.read_time);
    getReadings({
      ...obj,
      condominium_id: condominiumIdentifier,
      no_gateway: true,
      page: page,
      to_date: messagesDateTo.toISOString(),

      nearest_message: true,
      meter_types: JSON.stringify(meterType),
    });
  };
  /*   useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData.insertion_date.to_date);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getReadings({
        ...formData,
        condominium_id: condominiumIdentifier,
        no_gateway: true,
        to_date: messagesDateTo.toISOString(),
        nearest_message: true,
        meter_types: JSON.stringify(meterType),
      });
    }
  }, [firstRender, meterType]); */

  /*  useEffect(() => {
    if (!firstRender) {
      const to = new Date();
      const toDate = new Date(
        Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1)
      );
      for (let i = 0; i < readingsCount - 1; i++) {
        getReadings({
          condominium_id: condominiumIdentifier,
          no_gateway: true,
          page: i + 2,
          per_page: 100,
          to_date: toDate.toISOString(),
          nearest_message: true,
          type: meterType,
        });
      }
    }
  }, [firstRender]); */
  useEffect(() => {
    if (status === "fulfilled") {
      setReadyToShow(true);
    }
  }, [status]);

  useEffect(() => {
    if (!firstRender) {
      setReadyToShow(false);
    }
  }, [meterType]);
  const bearer = localStorage.getItem("user_token");
  const onExportClick = () => {
    dispatch(loadingOn());
    const messagesDateTo = new Date(formData.insertion_date.to_date);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setExportModal(true);
    const attributes = {
      read_time: formData.read_time?.read_time,
      to_date: messagesDateTo.toISOString(),
      no_gateway: true,
      nearest_message: true,
      condominium_id: condominiumId,
    };

    fetch(`${window.BASE_URL}/api/v1/plugin/condominium_export`, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify(prepareBody(attributes)),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        console.log("blobik", blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${condominiumname}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        setExportModal(false);
        dispatch(loadingOff());
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        setExportModal(false);
        dispatch(setSeverity("error"));
        dispatch(loadingOff());
        dispatch(setMsg("Esportazione non è andata a buon fine"));
      });
  };
  useEffect(() => {
    console.log("export modal", exportModal);
  }, [exportModal]);
  const styleSelector = (params) => {
    console.log(params);
    const datastr = params.row.readTime;
    const date = new Date();
    console.log(date);
    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    }
    if (params?.row?.lastReadingValue == "--") {
      return `super-app-theme--Rejected`;
    }
  };
  const heatStyleSelector = (params) => {
    console.log(params);
    const datastr = params.row.readTime;
    const date = new Date();
    console.log(date);
    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    }
    if (
      params?.row?.heat_last_reading == "--" &&
      params?.row?.cold_last_reading == "--"
    ) {
      return `super-app-theme--Rejected`;
    }
  };

  useEffect(() => {
    getDateDifferenceInDays("2023-05-08 07:32:44Z");
  }, []);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen) {
      return "62vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "78vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "69vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "79vw";
    }
  };
  return (
    <>
      <ModalDialog
        open={messageModal}
        close={() => closeMessageModal()}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <ModalDialog open={exportModal} title={t("note")} cancell={false}>
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          {t("exporting_data")}
          {/* <CircularProgress /> */}
        </Box>
      </ModalDialog>
      <FormAccordion
        /* width={appBarIsOpen ? "63vw" : "79vw"} */ width={() =>
          dinamicWidthSelector()
        }
      >
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          /*   onSubmit={(values) => onSubmitClick(values.formData)} */
          validator={validator}
          uiSchema={uiSchema}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
            sx={{ mt: 2 }}
          >
            <Button variant="contained" onClick={onExportClick} sx={{ mr: 2 }}>
              Export
            </Button>
            <Button variant="contained" onClick={() => onSubmitClick(formData)}>
              {t("search")}
            </Button>
          </Box>
        </Form>
      </FormAccordion>
      {allReadings && (
        <Box sx={{ height: 600, width: dinamicWidthSelector(), mt: 2 }}>
          <MetersTableTab
            afs={
              <Box sx={{ height: 600 }}>
                {readyToShow && (
                  <StyledDataGrid
                    disableColumnMenu
                    getRowClassName={(params) => styleSelector(params)}
                    rows={readingRows?.length > 0 ? readingRows : []}
                    columns={readingColumns}
                    onSortModelChange={(model) => console.log("test")}
                    pageSize={perPage}
                    rowsPerPageOptions={[perPage]}
                    rowCount={readingRows?.length}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
              </Box>
            }
            heatMeter={
              <Box sx={{ height: 600 }}>
                {readyToShow && (
                  <StyledDataGrid
                    disableColumnMenu
                    getRowClassName={(params) => styleSelector(params)}
                    rows={heatCostRows?.length > 0 ? heatCostRows : []}
                    columns={heatCostColumns}
                    onSortModelChange={(model) => console.log("test")}
                    pageSize={perPage}
                    rowsPerPageOptions={[perPage]}
                    rowCount={heatCostRows?.length}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
              </Box>
            }
            colorMeter={
              <Box sx={{ height: 600 }}>
                {readyToShow && (
                  <StyledDataGrid
                    getRowClassName={(params) => heatStyleSelector(params)}
                    disableColumnMenu
                    onSortModelChange={(model) => console.log("test")}
                    rows={heatRows?.length > 0 ? heatRows : []}
                    columns={heatColumns}
                    pageSize={perPage}
                    rowsPerPageOptions={[perPage]}
                    rowCount={heatRows?.length}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
              </Box>
            }
            setMeterType={setMeterType}
          />
        </Box>
      )}
      {/*   <Box sx={{ height: 600, width: "100%", mt: 2 }}>
        <StylingRowsGrid columns={readingColumns} rows={readingRows} />
      </Box> */}
    </>
  );
};
export default CondominiumReadingsTab;
