import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from "recharts";

import theme from "../../theme";

const ColorMeterReadingGraph = ({ meter }) => {
  const { t } = useTranslation();
  const heatUnit = useSelector((state) => state?.meterSlice?.heatUnit);
  const coldUnit = useSelector((state) => state?.meterSlice?.coldUnit);
  const data = useSelector((state) => state.meterReadingSlice?.data);
  const readingsRangeElements = useSelector(
    (state) => state?.meterSlice?.readingsRangeElements
  );
  const graphData = data?.map((item) => {
    let hotValue = item?.heat_reading;
    let coldValue = item?.cold_reading;
    if (typeof item.attributes?.heat_reading === "string") {
      hotValue = parseFloat(item.reading.replace(",", "."));
    }
    if (typeof item?.cold_reading === "string") {
      coldValue = parseFloat(item.reading.replace(",", "."));
    }

    return {
      oneDay: new Date(item?.oneDay).getTime(),
      brushDate: t("date_val", {
        val: new Date(item?.oneDay),
      }),
      Calorie: isNaN(hotValue)
        ? "Nessuna lettura"
        : heatUnit === "Wh"
        ? hotValue / 1000
        : hotValue,
      Frigorie: isNaN(coldValue)
        ? "Nessuna lettura"
        : coldUnit === "Wh"
        ? coldValue / 1000
        : coldValue,
    };
  });
  const [range, setRange] = useState({
    start: graphData?.length > 10 ? graphData?.length - 10 : 0,
    end: graphData?.length - 1,
  });
  /*   useEffect(() => {
    if (readingsRangeElements) {
      setRange({
        end: graphData?.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.oneDay),
            }) ===
            t("date_val", {
              val: new Date(readingsRangeElements?.start?.messageDate),
            })
        ),
        start: graphData?.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.oneDay),
            }) ===
            t("date_val", {
              val: new Date(readingsRangeElements?.end?.messageDate),
            })
        ),
      });
    }
  }, [readingsRangeElements]); */
  useEffect(() => {
    console.log("rangerid", graphData);
  }, [range]);
  /*   useEffect(() => {
    console.log(
      "testoneday",
      t("date_val", {
        val: new Date(graphData[graphData.length - 1].oneDay),
      }),
      t("date_val", {
        val: new Date(readingsRangeElements.start.messageDate),
      })
    );
  }, [range]); */
  const [ref, { width }] = useMeasure();

  if (!data) {
    return <Box ref={ref}>Loading</Box>;
  }

  return (
    <Box ref={ref}>
      <LineChart
        style={{ margin: 4 }}
        width={width - 10}
        height={400}
        data={graphData}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <Tooltip
          labelFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          formatter={(value) => t("number", { val: value })}
        />
        <Line
          type="monotone"
          dataKey="Calorie"
          stroke={"red"}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="Frigorie"
          stroke={"blue"}
          isAnimationActive={false}
        />
        <CartesianGrid stroke={theme.palette.device.main} />
        <XAxis
          dataKey="oneDay"
          label={{ value: t("date"), position: "insideBottom", offset: 5 }}
          angle={60}
          height={90}
          tickMargin={40}
          interval="preserveStartEnd"
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          stroke={theme.palette.group_and_district.main}
          style={{ fontSize: "12px" }}
        />
        <YAxis
          label={{
            value: t("kWh"),
            angle: -45,
            position: "insideLeft",
          }}
          stroke={theme.palette.group_and_district.main}
          tickFormatter={(value) => t("number", { val: value })}
          style={{ fontSize: "10px" }}
          width={90}
        />
        {/*  <Brush
          height={30}
          dataKey="brushDate"
          travellerWidth={10}
          startIndex={range?.start}
          endIndex={range?.end}
        /> */}
        <Tooltip formatter={(value) => t("number", { val: value })} />
      </LineChart>
    </Box>
  );
};

export default ColorMeterReadingGraph;
